<template>
  <div id="product-wrapper">
    <div class="row">
      <b-tabs v-model="tabIndex" class="content-d-none w-100">
        <b-tab @clcik="tabIndex = 0" title="Product List"></b-tab>
        <b-tab @click="tabIndex = 1" title="Stock Balance"></b-tab>
      </b-tabs>
    </div>
    <div class="row">
      <b-tabs v-model="tabIndex" class="nav-d-none w-100">
        <b-tab>
          <div v-if="tabIndex === 0">
            <VueBootstrap4Table 
              :rows="productList" 
              :columns="columns" 
              :config="config" 
              :total-rows="productListTotal"
              @on-change-query="onChangeQuery"
              @refresh-data="getProductList"
            >
              <template slot="empty-results">
                <div
                  v-if="!isLoading"
                  class="bold-text"
                >No records found</div>
                <div
                  v-if="isLoading"
                  class="row justify-content-center mt-3"
                ><div class="loader"></div></div>
              </template>
              <template slot="name" slot-scope="props">
                <img width="40" :src="getActiveCoverImage(props.row)" class="mr-4" style="height: 40px; object-fit: cover"/>
                <span>{{props.row.name}}</span>
              </template>
              <template slot="action" slot-scope="props">
                <div class="col-8 mx-auto">
                  <router-link :to="'/products/product-list/product-info/' + props.row.id" class="btn btn-primary justify-content-center">View &amp; Edit</router-link>
                </div>
              </template>
            </VueBootstrap4Table>
          </div>
        </b-tab>
        <b-tab>
          <div v-if="tabIndex === 1">
            <VueBootstrap4Table 
              :rows="stockList" 
              :columns="columnsStock" 
              :config="config"
              :total-rows="stockListTotal"
              @on-change-query="onChangeQuery"
              @refresh-data="getStockList"
            >
              <template slot="empty-results">
                <div
                  v-if="!isLoading"
                  class="bold-text"
                >No records found</div>
                <div
                  v-if="isLoading"
                  class="row justify-content-center mt-3"
                ><div class="loader"></div></div>
              </template>
              <template slot="paginataion" slot-scope="props">
                {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
              </template>
              <template slot="name" slot-scope="props">
                <div class="d-inline-flex">
                  <img width="40" :src="props.row.picture || '/assets/user_default_profile.png'" class="mr-4 profile-picture"/>
                  <div class="member-name"><span>{{props.row.name}}</span></div>
                </div>
              </template>
              <template slot="products" slot-scope="props">
                <div>
                  <p v-for="(product, index) in props.row.productList" :key="product.id" class="mb-1">{{index + 1}}. {{product.name}}</p>
                </div>
              </template>
              <template slot="action" slot-scope="props">
                <div class="col-12 mx-auto">
                  <router-link :to="'/products/stock-balance-list/view-user/' + props.row.uuid" class="btn btn-primary justify-content-center">View &amp; Edit</router-link>
                </div>
              </template>
              <template slot="report" slot-scope="props">
                <div class="custom-icon mx-auto" style="cursor:pointer" @click="downloadReport(props.row.uuid)">
                  <svg height="30px" viewBox="-35 0 511 512" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="m436.03125 107.03125-104.601562-102.734375c-2.804688-2.753906-6.578126-4.296875-10.511719-4.296875h-264.449219c-30.859375 0-55.96875 25.105469-55.96875 55.96875v400.0625c0 30.859375 25.105469 55.96875 55.96875 55.96875h328.082031c30.859375 0 55.96875-25.105469 55.96875-55.96875v-338.296875c0-4.027344-1.617187-7.882813-4.488281-10.703125zm-100.113281-56.273438 52.921875 51.976563h-48.152344c-2.628906 0-4.769531-2.140625-4.769531-4.769531zm48.632812 431.242188h-328.082031c-14.316406 0-25.96875-11.648438-25.96875-25.96875v-400.0625c0-14.320312 11.648438-25.96875 25.96875-25.96875h249.453125v67.964844c0 19.171875 15.597656 34.769531 34.769531 34.769531h69.828125v323.296875c0 14.320312-11.648437 25.96875-25.96875 25.96875zm0 0"/><path d="m324.644531 238h-211.453125c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h211.453125c8.285157 0 15-6.714844 15-15s-6.714843-15-15-15zm0 0"/><path d="m113.191406 328h172.414063c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-172.414063c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0"/><path d="m306.40625 358h-193.214844c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h193.214844c8.28125 0 15-6.714844 15-15s-6.71875-15-15-15zm0 0"/></svg>
                </div>
              </template>
            </VueBootstrap4Table>
             <a 
              :href="baseURL + downloadUrl.replace(/https?:\/\/[^\/]*\/?/i, '')" 
              :download="/[^/]*$/.exec(downloadUrl)[0]"
              id="download-link"
              style="display: none"
            ></a>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table/VueBootstrap4Table";
import { 
  GET_PRODUCT_LIST_REQUEST, 
  GET_STOCK_LIST_REQUEST, 
  EXPORT_STOCK_BALANCE_REPORT_REQUEST 
} from "../../store/modules/product/actions";
import { convertDateFormat } from "../../utils/helpers";

export default {
  name: "ProductList",
  props: ['tab'],
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
  },
  watch: {
    'queryParams.page': {
      handler(){
        if(this.tabIndex === 1){
          this.getStockList();
        } else {
          this.getProductList();
        }
      }
    },
    'queryParams.per_page': {
      handler(){
        if(this.tabIndex === 1){
          this.getStockList();
        } else {
          this.getProductList();
        }
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          if(this.tabIndex === 1){
          this.getStockList();
        } else {
          this.getProductList();
        }
        }
      }
    },
    tabIndex: {
      handler(newValue){
        this.onTabsChanged(newValue);
      }
    },
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    }
  },
  data(){
    return{
      tabIndex: this.tab || 0,
      isLoading: false,
      productList: [],
      productListTotal: 0,
      stockListTotal: 0,
      stockList: [],
      downloadUrl: '',
      baseURL: window.location.origin + '/',
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Product Name",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: '40%'
      },
      {
        label: "Product Code",
        name: "code",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        sort: true,
        width: '30%'
      },
      {
        label: "Product Vintage",
        name: "vintage",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        sort: true,
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      columnsStock: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Member Name",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "10%"
      },
      {
        label: "Product(s)",
        name: "products",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        width: '40%'
      },
      {
        label: "Total Stock",
        name: "totalStock",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
      },
      {
        label: "Customer Stock Balance",
        name: "report",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: '5%'
      }
      ],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Product"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    changeDate(date){
      return convertDateFormat(date, "DD/MM/YY")
    },
    downloadReport(uuid){
      this.$store.dispatch(EXPORT_STOCK_BALANCE_REPORT_REQUEST, {uuid: uuid}).then(res => {
        if(res.status === 'ok' && res.result){
          this.downloadUrl = res.result.url;
        }
      }).then(() => {
        document.getElementById('download-link').click();
      })
    },
    getActiveCoverImage(row){
      let hasCover = row.uploadList.find(el => el.cover === true);
      if(row.uploadList.length > 0 && hasCover){
        return hasCover.url;
      } else {
        return '/assets/default_image.jpeg';
      }
    },
    onTabsChanged(tabIndex){
      this.queryParams.global_search = "";
      this.queryParams.sort = [];
      if(tabIndex == 0) {
        this.config.global_search.placeholder = "Search Product";
        if(document.getElementById('header-route-name')){
          document.getElementById('header-route-name').innerHTML = "Product List";
        }
        this.getProductList();
      } else if(tabIndex == 1) {
        this.config.global_search.placeholder = "Search Member";
        document.getElementById('header-route-name').textContent = "Stock Balance";
        return this.getStockList();
      }
    },
    getProductList(){
      this.isLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, search: search, orderCol: orderCol, desc: desc};
      this.$store.dispatch(GET_PRODUCT_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.productList = res.result.list;
          this.productListTotal = res.result.total;
        }
        this.isLoading = false;
      })
    },
    getStockList(){
      this.isLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      const type = "STOCK";
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, search: search, orderCol: orderCol, desc: desc, type: type};
      this.$store.dispatch(GET_STOCK_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.stockList = res.result.list;
          this.stockListTotal = res.result.total;
        }
        this.isLoading = false;
      })
    }
  },
  mounted(){
    this.onTabsChanged(this.tabIndex);
  }
}
</script>
<style lang="scss">
#product-wrapper .profile-picture{
  height: 40px; 
  object-fit: cover;
  border-radius: 50%;
}
.nav-d-none ul.nav-tabs{
  display: none!important;
}

.nav-d-none .tab-content{
  width: 100%;
}

.content-d-none .tab-content{
  display: none;
}

.content-d-none div{
  width: 100%;
  .nav-tabs{
    background-color: white;
    border-radius: 10px;
    border: none;
    font-weight: 100;
  }
}
.member-name{
  white-space: nowrap; 
  width: 200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
</style>