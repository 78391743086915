<template>
  <div class="news-wrapper" :class="'news-wrapper-' + news.status.toLowerCase()">
    <div class="row justify-content-between p-3">
      <div class="col-4 d-inline-flex">
        <h5 class="mr-3 my-auto news-title">{{news.remarks}}</h5> 
        <div class="vertical-line-sm my-auto"></div>
        <p class="ml-3 my-auto news-p">Created Date {{changeDate(news.createdDate)}}</p>
      </div>
      <div class="col-6 d-inline-flex justify-content-end">
        <h5 class="mr-3 my-auto news-status">News Status</h5>
        <div class="col-4 mr-3">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <multiselect
              ref="status"
              v-model="status"
              :options="statusOptions"
              label="name"
              track-by="value"
              class="news-status-input"
              :allow-empty="false"
              :show-labels="false"
              @input="updateStatus"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="my-auto mr-4" style="font-size: 25px; cursor: pointer" @click="triggerUpdateModal">&#9998;</div>
        <div class="custom-icon delete-icon my-auto" @click="triggerModal"></div>
      </div>
    </div>
    <hr/>
    <div class="p-3">
      <b><h5 class="mb-3 news-name">{{item.name}}</h5></b>
      <p>{{item.description}}</p>
    </div>
    <BModal ref="confirm-modal" size="md" hide-footer>
      <div class="px-5 pb-5">
        <div class="row justify-content-center mb-5">
          <h5>Are you sure you want to delete <b><i>{{this.item.name}}</i></b> news?</h5>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" @click="deleteNews">Delete</button>
        </div>
      </div>
    </BModal>
    <BModal ref="update-modal" size="xl" hide-footer>
      <div>
        <form class="px-5" @submit.prevent="handleSubmit">
          <h5 class="text-center">Update News</h5>
          <div class="row my-5">
            <div class="col-4">
              <label class="mb-3">News Title</label>
              <input
                v-model="form.name"
                maxlength="40"
                placeholder="News Title here"
                class="form-control"
              />
              <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
            </div>
            <div class="col-4">
              <label class="mb-3">Start Date</label>
              <datepicker 
                v-model="form.fromDateStr"
                format="dd/MM/yyyy"
                :disabled="true"
                placeholder="12/04/2021"
                class="form-control disabled"
              ></datepicker>
            </div>
            <div class="col-4">
              <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
                <label class="mb-3">News Status</label>
                <multiselect
                  ref="status"
                  v-model="form.status"
                  :options="statusOptions"
                  label="name"
                  track-by="value"
                  placeholder="Select status"
                  class="news-status-input"
                  :allow-empty="false"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
                  </template>
                </multiselect>
                <span v-if="formErrors.status" class="invalid-field">{{ formErrors.status }}</span>
              </div>
            </div>
          </div>
          <div class="row my-5">
            <div class="col-4">
              <label class="mb-3">End Date</label>
              <datepicker 
                v-model="form.toDateStr"
                :disabled-dates="disabledDates"
                format="dd/MM/yyyy"
                placeholder="12/04/2021"
                class="form-control"
              ></datepicker>
              <span v-if="formErrors.toDateStr" class="invalid-field">{{ formErrors.toDateStr }}</span>
            </div>
            <div class="col-8">
              <label class="mb-3">News Remarks</label>
              <input
                v-model="form.remarks"
                placeholder="News Remarks here"
                maxlength="80"
                class="form-control"
              />
              <span v-if="formErrors.remarks" class="invalid-field">{{ formErrors.remarks }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="mb-3">News Details</label>
              <textarea
                v-model="form.description"
                placeholder="News Details here"
                class="form-control"
                maxlength="120"
                rows="4"
              ></textarea>
              <span v-if="formErrors.description" class="invalid-field">{{ formErrors.description }}</span>
            </div>
          </div>
        </form>
        <div class="row mt-5 justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerUpdateModal">Cancel</button>
          <button class="btn btn-secondary" :disabled="isLoading" @click="handleSubmit">Update</button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { convertDateFormat } from "../../utils/helpers";
import Multiselect from 'vue-multiselect';
import { DELETE_NEWS_REQUEST, UPDATE_NEWS_REQUEST } from "../../store/modules/news/actions"
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { BModal } from "bootstrap-vue";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "NewsComponent",
  props: ['item'],
  components: {
    Multiselect,
    BModal,
    Datepicker
  },
  data(){
    return{
      statusOptions: [
        {name: 'Active', value: 'ACTIVE'},
        {name: 'Inactive', value: 'INACTIVE'},
      ],
      status: {name: '', value: ''},
      news: {
        status: ''
      },
      form: {},
      formErrors: {},
      isLoading: false,
      disabledDates: {
        to: new Date()
      },
    }
  },
  methods: {
    changeDate(date){
      return convertDateFormat(date, "DD-MM-YY");
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.status) {
        this.formErrors.status = "Status is required";
        error++;
      } 
      if (!this.form.name) {
        this.formErrors.name = "Title is required";
        error++;
      } 
      if (!this.form.remarks) {
        this.formErrors.remarks = "Remarks is required";
        error++;
      } 
      if (!this.form.description) {
        this.formErrors.description = "Description is required";
        error++;
      } 
      if (Date.parse(this.form.fromDateStr) > Date.parse(this.form.toDateStr)) {
        this.formErrors.toDateStr = "End Date should be more than Start Date";
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const news = {...this.form};
        news.status = news.status.value;
        news.toDateStr = convertDateFormat(news.toDateStr, "DD/MM/YYYY");
        news.fromDateStr = convertDateFormat(news.fromDateStr, "DD/MM/YYYY");
        this.$store.dispatch(UPDATE_NEWS_REQUEST, news).then(res => {
          if(res.status === 'ok' && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "News has been successfully updated",
              timeOut: true
            });
            this.news = res.result;
            this.status = this.statusOptions.find(el => el.value === res.result.status);
            this.triggerUpdateModal();
            this.$emit("update-list");
          } else {
            this.form = {...this.item};
            this.form.status = this.status;
          }
          this.isLoading = false;
        })
      }
    },
    triggerUpdateModal(){
      this.$refs["update-modal"].toggle("#toggle-btn");
    },
    triggerModal(){
      this.$refs["confirm-modal"].toggle("#toggle-btn");
    },
    updateStatus(){
      const news = {...this.news};
      news.status = this.status.value;
      this.$store.dispatch(UPDATE_NEWS_REQUEST, news).then(res => {
        if(res.status === 'ok' && res.result){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "News has been successfully updated",
            timeOut: true
          });
          this.news = res.result;
        } else {
          this.status = this.statusOptions.find(el => el.value === this.item.status);
        }
      })
    },
    deleteNews(){
      this.$store.dispatch(DELETE_NEWS_REQUEST, {id: this.news.id}).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "News has been successfully deleted",
            timeOut: true
          });
          this.triggerModal();
          this.$emit("update-list");
        }
      })
    },
    changeToDateObject(date){
      var dateString = date;
      var dateParts = dateString.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
      return dateObject.toString();
    }
  },
  mounted(){
    this.news = {...this.item};
    this.form = {...this.item};
    this.status = this.statusOptions.find(el => el.value === this.item.status);
    this.form.status = this.status;
    this.disabledDates.to = new Date(new Date().setDate(new Date().getDate() - 1));
    this.form.toDateStr = this.changeToDateObject(this.form.toDateStr);
    this.form.fromDateStr = this.changeToDateObject(this.form.fromDateStr);
  }
}
</script>
<style scoped>
.news-wrapper{
  width: 100%;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  padding: 15px 30px;
}

.news-wrapper-active{
  background-color: #F4E6EB;
}

.vertical-line-sm{
  height: 25px;
  color: #D3C9CC;
  border-left: 3px solid;
}

.news-wrapper-active .news-name{
  color: #54021F;
}
.news-title, .news-p{
  color: #545557;
}

.news-name{
  font-weight: 900;
}

.news-status{
  color: #545557da;
}

.news-status-input{
  box-shadow: none;
  background-color: #F4F7FA;
  border-radius: 10px;
}

.delete-icon{
  cursor: pointer;
}
p, h5{
  cursor: default!important;
}
</style>