import {
  getProductList,
  getStockList,
  updateProductItem,
  uploadImage,
  getProductListByUser,
  updateStock,
  getProduct,
  addStock,
  exportStockBalanceReport
} from "@/api/product";
import {
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_STOCK_LIST_REQUEST,
  GET_STOCK_LIST_SUCCESS,
  GET_STOCK_LIST_ERROR,
  UPDATE_PRODUCT_INFO_REQUEST,
  UPDATE_PRODUCT_INFO_SUCCESS,
  UPDATE_PRODUCT_INFO_ERROR,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  GET_PRODUCT_LIST_BY_USER_REQUEST,
  GET_PRODUCT_LIST_BY_USER_SUCCESS,
  GET_PRODUCT_LIST_BY_USER_ERROR,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_ERROR,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  ADD_STOCK_REQUEST,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_ERROR,
  EXPORT_STOCK_BALANCE_REPORT_REQUEST,
  EXPORT_STOCK_BALANCE_REPORT_SUCCESS,
  EXPORT_STOCK_BALANCE_REPORT_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [GET_PRODUCT_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PRODUCT_LIST_REQUEST);
      getProductList(postData)
        .then(resp => {
          commit(GET_PRODUCT_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PRODUCT_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_STOCK_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_STOCK_LIST_REQUEST);
      getStockList(postData)
        .then(resp => {
          commit(GET_STOCK_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_STOCK_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_PRODUCT_INFO_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_PRODUCT_INFO_REQUEST);
      updateProductItem(postData)
        .then(resp => {
          commit(UPDATE_PRODUCT_INFO_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_PRODUCT_INFO_ERROR, err);
          reject(err);
        });
    });
  },
  [UPLOAD_IMAGE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_IMAGE_REQUEST);
      uploadImage(postData)
        .then(resp => {
          commit(UPLOAD_IMAGE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_IMAGE_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_PRODUCT_LIST_BY_USER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PRODUCT_LIST_BY_USER_REQUEST);
      getProductListByUser(postData)
        .then(resp => {
          commit(GET_PRODUCT_LIST_BY_USER_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PRODUCT_LIST_BY_USER_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_STOCK_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_STOCK_REQUEST);
      updateStock(postData)
        .then(resp => {
          commit(UPDATE_STOCK_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_STOCK_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_PRODUCT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PRODUCT_REQUEST);
      getProduct(postData)
        .then(resp => {
          commit(GET_PRODUCT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PRODUCT_ERROR, err);
          reject(err);
        });
    });
  },
  [ADD_STOCK_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(ADD_STOCK_REQUEST);
      addStock(postData)
        .then(resp => {
          commit(ADD_STOCK_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ADD_STOCK_ERROR, err);
          reject(err);
        });
    });
  },
  [EXPORT_STOCK_BALANCE_REPORT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_STOCK_BALANCE_REPORT_REQUEST);
      exportStockBalanceReport(postData)
        .then(resp => {
          commit(EXPORT_STOCK_BALANCE_REPORT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(EXPORT_STOCK_BALANCE_REPORT_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_PRODUCT_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PRODUCT_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_PRODUCT_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_STOCK_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_STOCK_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_STOCK_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_PRODUCT_INFO_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_PRODUCT_INFO_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_PRODUCT_INFO_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPLOAD_IMAGE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_IMAGE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPLOAD_IMAGE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_PRODUCT_LIST_BY_USER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PRODUCT_LIST_BY_USER_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_PRODUCT_LIST_BY_USER_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_STOCK_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_STOCK_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_STOCK_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_PRODUCT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PRODUCT_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_PRODUCT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  ADD_STOCK_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ADD_STOCK_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  ADD_STOCK_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  EXPORT_STOCK_BALANCE_REPORT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_STOCK_BALANCE_REPORT_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  EXPORT_STOCK_BALANCE_REPORT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};