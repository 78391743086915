<template>
  <div>
    <VueBootstrap4Table 
      :rows="userList" 
      :columns="columns" 
      :config="config"
      :total-rows="userListTotal"
      @on-change-query="onChangeQuery"
      @refresh-data="getUserList"
    >
      <template slot="empty-results">
        <div
          v-if="!isDataLoading"
          class="bold-text"
        >No records found</div>
        <div
          v-if="isDataLoading"
          class="row justify-content-center mt-3"
        ><div class="loader"></div></div>
      </template>
      <template slot="paginataion" slot-scope="props">
        {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
      </template>
      <template slot="created_date" slot-scope="props">
        <span>{{changeDate(props.row.createdDate)}}</span>
      </template>
      <template slot="name" slot-scope="props">
        <img width="40" :src="props.row.picture || '/assets/user_default_profile.png'" class="mr-4" style="height: 40px; object-fit: cover; border-radius: 50%"/>
        <span>{{props.row.name}}</span>
      </template>
      <template slot="member_type" slot-scope="props">
        <span>{{props.row.memberType}}</span>
      </template>
      <template slot="action" slot-scope="props">
        <div class="col-6 mx-auto">
          <router-link :to="'/user-management/view-user/' + props.row.uuid" class="btn btn-primary justify-content-center">View &amp; Edit </router-link>
        </div>
      </template>
    </VueBootstrap4Table>
  </div>
</template>
<script>
import { GET_USER_MANAGEMENT_LIST_REQUEST } from "../../store/modules/user-management/actions";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table/VueBootstrap4Table";
import { convertDateFormat } from "../../utils/helpers";

export default {
  name: "UserManagement",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
  },
  watch: {
    'queryParams.page': {
      handler(newVal, oldVal){
        console.log(newVal, oldVal)
        this.getUserList();
      }
    },
    'queryParams.per_page': {
      handler(newVal, oldVal){
        console.log(newVal, oldVal)
        this.getUserList();
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          this.getUserList();
        }
      }
    },
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    }
  },
  data(){
    return{
      userList: [],
      userListTotal: 0,
      isDataLoading: false,
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "User Name",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Joined Date",
        name: "created_date",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "20%"
      },
      {
        label: "Member Type",
        name: "member_type",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "15%"
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Member"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getList();
    },
    changeDate(date){
      return convertDateFormat(date, "DD/MM/YY")
    },
    getUserList(){
      this.isDataLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, search: search, orderCol: orderCol, desc: desc};
      this.$store.dispatch(GET_USER_MANAGEMENT_LIST_REQUEST, filter).then(res => {
        if(res.status === 'ok' && res.result){
          this.userList = res.result.list;
          this.userListTotal = res.result.total;
        }
        this.isDataLoading = false;
      })
    }
  },
  mounted(){
    this.getUserList();
  }
}
</script>
<style scoped>
span{
  cursor: default;
}
</style>