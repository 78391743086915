<template>
  <div>
    <div class="header">
      <div class="header-content">
        <div class="header-left d-inline-flex align-items-center" style="height: 60px; cursor: default">
          <div class="navigation-icon custom-icon" @click="toggleSidebar"></div>
          <ul class="mb-0">
            <li class="icons position-relative">
              <div v-if="$route.path === '/dashboard'" class="d-inline-flex">
                <b class="my-auto"><p class="mb-0">Welcome, <span>{{ name }}</span></p></b>
                &nbsp;&nbsp;<div class="vertical-line"></div>&nbsp; &nbsp;
                <div class="my-auto">{{this.$route.meta.name}}</div>
              </div>
              <div v-else class="d-inline-flex" :key="path">
                <b class="my-auto"><p class="mb-0">{{this.$route.meta.title}}</p></b>
                &nbsp;&nbsp;
                <div 
                  v-if="this.$route.meta.subtitles ? this.$route.meta.subtitles.length !== 0 : false" 
                  class="vertical-line"
                ></div>
                &nbsp; &nbsp;
                <div v-for="(subtitle, index) in this.$route.meta.subtitles" :key="index" :class="'subtitle-' + index" class="my-auto">
                  <div v-if="subtitle !== ''" class="header-subtitle">{{subtitle}} /&nbsp;</div>
                </div>
                <p id="header-route-name" class="my-auto"> {{this.$route.name}}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="header-right">
          <ul class="mb-0">
            <li class="icons d-inline-flex">
              <router-link to="/notifications" class="d-inline-flex">
                <div class="custom-icon notification-icon"></div>
                <span v-if="unreadCount > 0" class="unread-signal-nav" />
              </router-link>
              <b-dropdown ref="navbar-dropdown" id="navbar-dropdown" class="m-md-2">
                <b-dropdown-item href="/profile">
                  <div class="user-icon custom-icon mr-3"></div>
                  <span class="my-auto">Profile</span>
                </b-dropdown-item>
              </b-dropdown>
              <div class="d-inline-flex" style="cursor: pointer" @click="toggleDropdown">
                <div class="user-icon custom-icon"></div>
                <div class="dropdown-icon custom-icon"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { GET_UNREAD_NOTIFICATIONS_REQUEST } from "../../store/modules/notifications/actions"
// import { AUTH_LOGOUT } from "../../store/modules/auth/actions";

// import { GET_UNREAD_MESSAGE_COUNT_REQUEST } from "../../store/modules/message/actions";
import { EventBus } from "../../utils/EventBus";

export default {
  name: "Header",
  props: ['toggleSidebarAction'],
  computed: {
    ...mapGetters(["getUser", "isAuthenticated"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => `${state.auth.user.name}`,
      profilePic: state => `${state.auth.user.profilePic}`
    }),
  },
  watch: {
    $route(to){
      if(to.fullPath !== "/products/stock-balance-list/view-user"){
        this.path = to.fullPath;
      }
    }
  },
  data() {
    return {
      env: process.env.VUE_APP_NODE_ENV,
      unreadCount: 0,
      path: this.$route.fullPath
    };
  },
  methods: {
    toggleSidebar(){
      this.toggleSidebarAction();
    },
    toggleDropdown(){
      this.$refs['navbar-dropdown'].show();
    },
    getUnreadNotifications(){
      this.$store.dispatch(GET_UNREAD_NOTIFICATIONS_REQUEST).then(res => {
        if(res.status === 'ok' && res.result){
          this.unreadCount = res.result.unreadCount;
        }
      })
    }
  },
  mounted() {
    EventBus.$on("getNotificationsCount", () => {
      this.getUnreadNotifications();
    });
    this.getUnreadNotifications();
  }
};
</script>
<style>

.dropdown-icon{
  background-position-x: left;
}

#navbar-dropdown button.dropdown-toggle{
  display: none!important;
}

#navbar-dropdown.b-dropdown{
  width: 0;
}

#navbar-dropdown .dropdown-menu{
  top: 40px!important;
  left: -200px!important;
  width: 250px;
  padding-top: 10px;
  transform: none!important;
  border: none;
  box-shadow: 0 0 1.5rem rgb(120 130 140 / 13%);
}

#navbar-dropdown .dropdown-menu li{
  height: 50px;
}

#navbar-dropdown .dropdown-item{
  display: inline-flex;
}

#navbar-dropdown .dropdown-item span{
  color: #808997;
  font-size: 15px;
}

.header p, .header .header-subtitle{
  cursor: default;
}
</style>