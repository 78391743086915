import request from "@/utils/request";
import {
  API_GET_PRODUCT_LIST,
  API_GET_MEMBER_LIST,
  API_UPDATE_PRODUCT_ITEM,
  API_UPLOAD_IMAGE,
  API_GET_PRODUCT_LIST_BY_USER,
  API_UPDATE_STOCK,
  API_GET_PRODUCT,
  API_ADD_STOCK,
  API_EXPORT_STOCK_BALANCE_REPORT
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getProductList(filter) {
  return await request({
    url: API_GET_PRODUCT_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getStockList(filter) {
  return await request({
    url: API_GET_MEMBER_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateProductItem(product) {
  return await request({
    url: API_UPDATE_PRODUCT_ITEM,
    method: "post",
    data: {locale: locale, product: product.product},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadImage(url) {
  return await request({
    url: API_UPLOAD_IMAGE,
    method: "post",
    data: {locale: locale, upload: {url: url}},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getProductListByUser(filter) {
  return await request({
    url: API_GET_PRODUCT_LIST_BY_USER,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateStock(product) {
  return await request({
    url: API_UPDATE_STOCK,
    method: "post",
    data: {locale: locale, product: product},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getProduct(postData) {
  let data;
  if(postData.user){
    data = {locale: locale, product: {id: postData.id, user: {uuid: postData.user}}}
  } else {
    data = {locale: locale, product: {id: postData.id}}
  }
  return await request({
    url: API_GET_PRODUCT,
    method: "post",
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addStock(product) {
  return await request({
    url: API_ADD_STOCK,
    method: "post",
    data: {locale: locale, product: product},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function exportStockBalanceReport(user) {
  return await request({
    url: API_EXPORT_STOCK_BALANCE_REPORT,
    method: "post",
    data: {locale: locale, user: user},
    timeout: DEFAULT_TIMEOUT
  });
}