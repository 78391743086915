<script>
import { mapGetters, mapState } from "vuex";
import Header from "../components/header/header.vue";
import Footer from "../components/footer/footer.vue";
import Sidebar from "../components/sidebar/sidebar.vue";
import { EventBus } from "@/utils/EventBus";
// import jQuery from 'jquery';
export default {
  name: "Admin",
  components: {
    "admin-header": Header,
    "admin-footer": Footer,
    "admin-sidebar": Sidebar
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading"
    })
  },
  data() {
    return {
      collapsed: false
    };
  },
  watch: {
    getUserId: function() {}
  },
  methods: {
    onCollapse(){
      this.collapsed = !this.collapsed;
    }
  },
  mounted() {
    EventBus.$on("userLogout", () => {
      this.$router.push("/sign-in");
      location.reload();
    });

    EventBus.$on("reRenderApp", data => {
      let userData = JSON.parse(localStorage.getItem("user")) || {};
      let userId = userData.user ? userData.user.id : 0;

      if (userId != this.getUserId) {
        location.reload();
      }
      this.reRenderComponent = data;
    });
  }
};
</script>

<template>
  <div>
    <div id="main-wrapper" class="main-component-wrapper" :class="[{'menu-toggle' : collapsed}]">
      <admin-header :toggleSidebarAction="onCollapse"/>
      <admin-sidebar :collapsed="collapsed" :onCollapseAction="onCollapse"/>
      <div class="main-container">
        <div class="content-body main-container">
          <div class="container-fluid admin-layout-body">
            <!-- <router-view></router-view> -->
            <slot />
          </div>
          <admin-footer />
        </div>
      </div>
    </div>
  </div>
</template>