import store from "@/store";
import PassThrough from "../../views/pass-through.vue";
import notificationsList from "../../views/notifications/list.vue";

import {
  ROLE_ADMIN,
  LAYOUT_ADMIN
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.accessLevel) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/sign-in");
};

const notificationsRouter = {
  path: "/notifications",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
    ],
  },
  children: [
    {
      path: "",
      name: "",
      component: notificationsList,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Notifications",
        subtitles: []
      },
    },
  ],
  beforeEnter: ifAuthenticated
};

export default notificationsRouter;