<template>
  <div class="chart-wrapper mb-5 pb-5">
    <div class="d-inline-flex w-100 filter-wrapper">
      <div class="col-10 d-flex">
        <h4 class="ml-4 my-auto">(Weekly) Trading Thread / Collection Request</h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <b-dropdown variant="link" class="h-100">
          <template #button-content>
            {{currentWeek}}
          </template>
          <b-dropdown-item v-for="(option, index) in weekList" :key="index" href="#" @click="onDropdownClick(option)">
            {{option}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <GChart
      ref="chartData"
      type="LineChart"
      :data="chartData"
      :options="chartOption"
    />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts";
import $ from "jquery";

export default {
  name: "ChartComponent",
  props: ['chartData', 'weekOptions', 'week'],
  components: {
    GChart,
  },
  computed: {
    currentWeek: function() {
      return this.week;
    },
    weekList: function() {
      return this.weekOptions;
    },
  },
  data(){
    return{
      chartOption: {
        borderRadius: 20,
        height: 450,
        colors: ['#9ACD3A', '#E0C64B'],
        chartArea: {height: '55%', width: '90%'},
        legend: {position: 'bottom'},
        hAxis: { minValue: 0}, 
        vAxis: {
          minValue: 0,
          viewWindow: {
            min: 0
          },
          viewWindowMode: "explicit"
        }
      }
    }
  },
  methods: {
    onDropdownClick(option){
      this.$emit('get-data', option);
    },  
    addEvent(){
      let reference = this.$refs;
      let component = this;
      $(".navigation-icon").on("click", function(){
        let dashboard = reference["chartData"];
        if(dashboard){
          component.$emit('get-data', component.currentWeek);
        }
      }); 
    }
  },
  mounted(){
    this.addEvent();
  }
}
</script>
<style>
.chart-wrapper{
  position: relative;
}

.chart-wrapper svg{
  border-radius: 20px;
  box-shadow: 0 0 1.5rem rgb(120 130 140 / 13%)!important;
}

.chart-wrapper .filter-wrapper{
  position: absolute;
  top: 20px;
  /* right: 20px; */
  justify-content: center;
  z-index: 9999;
}

.chart-wrapper .filter-wrapper button.btn-link{
  box-shadow: none!important;
  width: auto!important;
  margin: auto 0px;
}

.chart-wrapper .filter-wrapper .b-dropdown{
  justify-content: flex-end;
}

.chart-wrapper .filter-wrapper button.btn-link:hover, .chart-wrapper .filter-wrapper button.btn-link:focus{
  box-shadow: none!important;
  text-decoration: none;
}

.chart-wrapper h4{
  cursor: default;
}

.chart-wrapper text{
  cursor: pointer;
}
</style>