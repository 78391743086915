import request from "@/utils/request";
import {
  API_GET_TRADING_FORUM_LIST,
  API_CREATE_THREAD,
  API_GET_THREAD,
  API_UPDATE_THREAD_STATUS
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getTradingForumList(filter) {
  return await request({
    url: API_GET_TRADING_FORUM_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function createThread(thread) {
  return await request({
    url: API_CREATE_THREAD,
    method: "post",
    data: {locale: locale, thread: thread},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getThread(thread) {
  return await request({
    url: API_GET_THREAD,
    method: "post",
    data: {locale: locale, thread: thread},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateThreadStatus(thread) {
  return await request({
    url: API_UPDATE_THREAD_STATUS,
    method: "post",
    data: {locale: locale, thread: thread},
    timeout: DEFAULT_TIMEOUT
  });
}