import {
  getNewsList,
  deleteNews,
  updateNews,
  createNewNews
} from "@/api/news";
import {
  GET_NEWS_LIST_REQUEST,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_ERROR,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_ERROR,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_ERROR,
  CREATE_NEW_NEWS_REQUEST,
  CREATE_NEW_NEWS_SUCCESS,
  CREATE_NEW_NEWS_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [GET_NEWS_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_NEWS_LIST_REQUEST);
      getNewsList(postData)
        .then(resp => {
          commit(GET_NEWS_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_NEWS_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [DELETE_NEWS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_NEWS_REQUEST);
      deleteNews(postData)
        .then(resp => {
          commit(DELETE_NEWS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DELETE_NEWS_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_NEWS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_NEWS_REQUEST);
      updateNews(postData)
        .then(resp => {
          commit(UPDATE_NEWS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_NEWS_ERROR, err);
          reject(err);
        });
    });
  },
  [CREATE_NEW_NEWS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_NEW_NEWS_REQUEST);
      createNewNews(postData)
        .then(resp => {
          commit(CREATE_NEW_NEWS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CREATE_NEW_NEWS_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_NEWS_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_NEWS_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_NEWS_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DELETE_NEWS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DELETE_NEWS_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DELETE_NEWS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_NEWS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_NEWS_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_NEWS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  CREATE_NEW_NEWS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CREATE_NEW_NEWS_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  CREATE_NEW_NEWS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};