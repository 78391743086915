<template>
  <sidebar-menu 
    :menu="menu" 
    :collapsed="collapsed" 
    width="24rem"
    :hideToggle="true"
    @item-click="onItemClick"
    @toggle-collapse="onCollapse">
      <div slot="header" class="vsm--header d-flex justify-content-center mb-4">
        <router-link to="/"><div class="custom-icon logo-icon"></div></router-link>
      </div>
      <div slot="footer">
        <div class="d-inline-flex sync-wrapper">
          <div class="custom-icon sync-icon mr-3 mt-1" :class="isSyncing && 'syncing'" @click="sync"></div>
          <div class="sync-content">
            <b><p class="mb-0">Sync</p></b>
            <span>{{$store.getters.getLastSync}}</span>
          </div>
        </div>
        <div class="vsm--footer" @click="onLogout">
          <div class="custom-icon sign-out-icon"></div>
          <p class="my-auto">Sign Out</p>
        </div>
      </div>
  </sidebar-menu>
</template>

<script>
  import { GET_LAST_SYNC_REQUEST, SYNC_REQUEST } from "../../store/modules/constant/actions";
  import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
  import { AUTH_LOGOUT } from "../../store/modules/auth/actions";
  import { EventBus } from "../../utils/EventBus";

  export default {
    name: 'Sidebar',
    props: ['collapsed', 'onCollapseAction'],
    data() {
      return {
        isSyncing: false,
        menu: [
          {
            href: '/dashboard',
            title: 'Dashboard',
            icon: 'custom-icon dashboard-icon'
          },
          {
            href: '/products/product-list',
            title: 'Products',
            alias: [
              '/products', 
              '/products/product-list', 
              '/products/product-list/product-info/:id', 
              '/products/stock-balance-list/view-user/:id',
              '/products/stock-balance-list/view-user/update-stock-details/:id/:product_id'
            ],
            icon: 'custom-icon products-icon',
          },
          {
            href: '/delivery-order/new-request',
            title: 'Delivery Order',
            alias: [
              '/delivery-order', 
              '/delivery-order/new-request',
              '/delivery-order/generate-order/:id'
            ],
            icon: 'custom-icon delivery-icon',
          },
          {
            href: '/trading-forum/new-thread',
            title: 'Trading',
            alias: [
              '/trading-forum', 
              '/trading-forum/new-thread',
              '/trading-forum/create-new-thread',
              '/trading-forum/view-thread/:id'
            ],
            icon: 'custom-icon trading-icon',
          },
          {
            href: '/user-management',
            title: 'User Management',
            alias: [
              '/user-management',
              '/user-management/view-user/:id',
            ],
            icon: 'custom-icon user-management-icon',
          },
          {
            href: '/messages',
            title: 'Message',
            alias: [
              '/messages',
              '/messages/view-message/:id',
              '/messages/create-new-message',
            ],
            icon: 'custom-icon message-icon',
          },
          {
            href: '/news',
            title: 'News',
            alias: [
              '/news',
            ],
            icon: 'custom-icon news-icon',
          },
          {
            href: '/banner',
            title: 'Banner',
            alias: [
              '/banner',
            ],
            icon: 'custom-icon banner-icon',
          },
        ],
        path: this.$route.path
      }
    },
    methods: {
      onCollapse () {
        this.onCollapseAction();
      },
      onItemClick(event, item){
        console.log(event);
        this.menu = this.menu.map(el => 
          {
            var temp = Object.assign({}, el);
            let element = document.getElementsByClassName(el.icon)[0];
            if(element) element.classList.remove('is-active');
            if(el !== item) {
              temp.icon = temp.icon.replace('is-active','');
            } else {
              temp.icon = temp.icon + " is-active";
            }
            return temp;
          }
        );
      },
      sync(){
        this.isSyncing = true;
        this.$store.dispatch(SYNC_REQUEST).then(res => {
          if(res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Sync has been successfully updated",
              timeOut: true
            });
            this.getLastSyncDetails();
          }
          this.isSyncing = false;
        })
      },
      getLastSyncDetails(){
        this.$store.dispatch(GET_LAST_SYNC_REQUEST)
      },
      onLogout() {
        this.$store.dispatch(AUTH_LOGOUT).then(res => {
          if (res.logout) {
            EventBus.$emit("userLogout", 1);
          }
        });
      },
      observeSidebar(){
        const target = document.getElementsByClassName('vsm--link');
        for (var i = 0; i < target.length; i++) {
          var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
              if(mutation.target.classList.contains('vsm--link_active')){
                for (var i = 0; i < mutation.target.childNodes.length; i++) {
                  if (mutation.target.childNodes[i].classList && mutation.target.childNodes[i].classList.contains('vsm--icon')) {
                    mutation.target.childNodes[i].classList.add('is-active');
                  }
                }
              } else {
                for (var k = 0; k < mutation.target.childNodes.length; k++) {
                  if (mutation.target.childNodes[k].classList && mutation.target.childNodes[k].classList.contains('vsm--icon')) {
                    mutation.target.childNodes[k].classList.remove('is-active');
                  }
                }
              }
            });
          });
          var config = { attributes: true };
          observer.observe(target[i], config);
        }
      }
    },
    mounted(){
      this.menu = this.menu.map(el => {
        var temp = Object.assign({}, el);
        if(this.$route.path.includes(el.href.split("/").slice(0,2).join("/"))) temp.icon = temp.icon + " is-active";
        return temp;
      });
      this.observeSidebar();
      this.getLastSyncDetails();      
    }
  }
</script>
<style scoped>
.sync-icon{
  cursor: pointer;
}
.menu-toggle .vsm--footer p{
  display: none;
}
.vsm--footer p{
  display: block;
}

.menu-toggle .sync-wrapper .sync-content{
  display: none;
}
.sync-wrapper .sync-content{
  display: block;
}

.menu-toggle .logo-icon{
  width: 45px;
}


</style>