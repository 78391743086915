import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_ERROR,
  UPDATE_USER_ADMIN_PASSWORD_REQUEST,
  UPDATE_USER_ADMIN_PASSWORD_SUCCESS,
  UPDATE_USER_ADMIN_PASSWORD_ERROR,
} from "./actions";
import {
  loginByUsername,
  getUserProfile,
  resetByEmail,
  updateUserDetails,
  updateUserAdminPassword
} from "@/api/auth";

if(!document.cookie && document.cookie !== "remember=true"){
  localStorage.removeItem("user-token");
  localStorage.removeItem("user");
}

let userData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) || {} : {};
let userId = 0;
if (userData) {
  userId = userData.user ? userData.user.id : 0;
}

const state = {
  token: localStorage.getItem("user-token") || "",
  user: userData,
  status: "",
  hasLoadedOnce: false,
  getUserId: userId,
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  getUser: state => state.auth,
  user: state => state.user,
  getUserId: state => state.getUserId,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, authData) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      loginByUsername(authData)
        .then((resp) => {
          if (resp && resp.status === 'ok') {
            let ad = resp.result;
            if (ad.token) {
              localStorage.setItem("user-token", ad.token);
              localStorage.setItem("user", JSON.stringify(ad));
              commit(AUTH_SUCCESS, resp);
              resolve(resp);
            } else {
              commit(AUTH_ERROR, resp);
              localStorage.removeItem("user-token");
              localStorage.removeItem("user");
              reject(resp);
            }
            resolve(resp);
          } else {
            // reject({})
          }
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("user-token");
          localStorage.removeItem("user");
          reject(err);
        });
    });
  },
  [PROFILE_REQUEST]: ({ commit }, authData) => {
    return new Promise((resolve, reject) => {
      commit(PROFILE_REQUEST);
      getUserProfile(authData)
        .then(resp => {
          let profile = resp.result;
          if (profile) {
            // if (profile.userProfile) {
              // let user = {
              //   name: profile.userProfile.displayName,
              //   role: profile.countryRoleList[0].role,
              //   user: profile.userProfile,
              //   profilePic:
              //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIp5rxGw-RDahWMjll8IdthIiY-H21h3UyNl8KMDPDTlUNa0lH"
              // };
              localStorage.setItem("user", JSON.stringify(profile));
              commit(PROFILE_SUCCESS, profile);
              resolve(resp);
            // }
          } else {
            commit(PROFILE_ERROR, resp);
            localStorage.removeItem("user-token");
            localStorage.removeItem("user");
            reject(resp);
          }

          resolve(resp);
        })
        .catch(err => {
          commit(PROFILE_ERROR, err);
          localStorage.removeItem("user");
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token");
      localStorage.removeItem("user");
      resolve({
        logout: true
      });
    });
  },
  [PASSWORD_RESET_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PASSWORD_RESET_REQUEST);
      resetByEmail(postData)
        .then(resp => {
          commit(PASSWORD_RESET_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PASSWORD_RESET_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_USER_ADMIN_PASSWORD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_USER_ADMIN_PASSWORD_REQUEST);
      updateUserAdminPassword(postData)
        .then(resp => {
          commit(UPDATE_USER_ADMIN_PASSWORD_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_USER_ADMIN_PASSWORD_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_USER_DETAILS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_USER_DETAILS_REQUEST);
      updateUserDetails(postData)
      .then((resp) => {
        if (resp && resp.status === 'ok') {
          let ad = resp.result;
          localStorage.setItem("user", JSON.stringify(ad));
          commit(UPDATE_USER_DETAILS_SUCCESS, ad);
          resolve(resp);
        } else {
          commit(UPDATE_USER_DETAILS_ERROR, resp);
          localStorage.removeItem("user-token");
          localStorage.removeItem("user");
          reject(resp);
        }
      })
      .catch(err => {
        commit(UPDATE_USER_DETAILS_ERROR, err);
        localStorage.removeItem("user-token");
        localStorage.removeItem("user");
        reject(err);
      });
    });
  },
};

const mutations = {
  AUTH_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  AUTH_SUCCESS: (state, token) => {
    Object.assign(state, {
      status: "",
      token: token,
      hasLoadedOnce: true
    });
  },
  AUTH_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PROFILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROFILE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      user: data,
      hasLoadedOnce: true
    });
  },
  PROFILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  AUTH_LOGOUT: state => {
    Object.assign(state, {
      status: "loading",
      hasLoadedOnce: true
    });
  },
  PASSWORD_RESET_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PASSWORD_RESET_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PASSWORD_RESET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_DETAILS_REQUEST: (state) => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_USER_DETAILS_SUCCESS: (state, user) => {
    Object.assign(state, {
      user: user,
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_DETAILS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_ADMIN_PASSWORD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_USER_ADMIN_PASSWORD_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_ADMIN_PASSWORD_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
