<template>
  <div 
    class="notification-wrapper d-inline-flex justify-content-between position-relative" 
    :class="notification.receipientList[0].read ? 'inactive' : 'active'"
    @click="updateNotification"
  >
    <div>
      <div class="row">
        <div class="col-12 d-inline-flex">
          <p class="notification-title mr-2">{{notification.subject}}</p>
          <p class="notification-content ml-0"> - {{notification.content}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-inline-flex">
          <p class="mr-3 my-auto notification-p">{{changeDate(notification.createdDate, "DD/MM/YY")}}</p>
          <div class="vertical-line-sm my-auto"></div>
          <p class="ml-3 my-auto notification-p">{{changeDate(notification.createdDate, "HH:MM")}}</p>
        </div>
      </div>
    </div>
    <span v-if="!notification.receipientList[0].read" class="unread-signal-nav" />
    
  </div>
</template>
<script>
import { convertDateFormat } from "../../utils/helpers";
import { UPDATE_NOTIFICATION_REQUEST } from "../../store/modules/notifications/actions";
import { EventBus } from "../../utils/EventBus";

export default {
  name: "NotificationComponent",
  props: ['item'],
  data(){
    return{
      notification: {
        receipientList: [{read: this.item.receipientList[0].read}]
      }
    }
  },
  methods: {
    changeDate(date, type){
      return convertDateFormat(date, type);
    },
    redirectTo(){
      if(this.notification.collection){
        this.$router.push('/delivery-order/generate-order/' + this.notification.collection.id);
      } else if(this.notification.threadId){
        this.$router.push('/trading-forum/view-thread/' + this.notification.threadId);
      } 
    },
    updateNotification(){
      if(!this.notification.receipientList[0].read){
        this.$store.dispatch(UPDATE_NOTIFICATION_REQUEST, {id: this.notification.id}).then(() => {
          EventBus.$emit("getNotificationsCount");
        })
      }
      this.redirectTo();
    },
  },
  mounted(){
    this.notification = {...this.item};
  }
}
</script>
<style scoped>
.notification-wrapper{
  width: 100%;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  padding: 15px 30px;
  cursor: pointer;
}
.notification-title{
  font-weight: 900;
  color: #191C30;
}
.notification-content{
  font-weight: 600;
  color: #54021F;
}
.notification-wrapper.active{
  background-color: #F4E6EB;
}
.notification-p{
  color: #B5B2B8;
}

.unread-signal-nav{
  top: 50%;
  transform: translateY(-50%);
}
</style>