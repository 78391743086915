<template>
  <div class="px-5">
    <form class="form" @submit.prevent="handleSubmit">
      <div class="row my-5">
        <div class="col-12 d-inline-flex">
          <div class="col-4 pl-0">
            <label class="mb-3">Member ID</label>
            <input
              v-model="user.memberNo"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <label class="mb-3">Member Name</label>
            <input
              v-model="user.name"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4 pr-0">
            <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
              <label class="mb-3">Product Status</label>
              <multiselect
                ref="status"
                v-model="status"
                :searchable="true"
                :disabled="true"
                :options="statusOptions"
                label="name"
                track-by="value"
                :loading="!statusOptions"
                :allow-empty="false"
                :show-labels="false"
                class="disabled"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-3 d-inline-flex">
          <div class="position-relative">
            <img 
              :src="getActiveCoverImage()" 
              width="100" 
              class="product-pic"
              alt="Product Picture"
            />
            <div class="overlay" @click="triggerModal">View ({{item.uploadList && item.uploadList.length}})</div>
          </div>
          <div class="my-auto ml-5">
            <p class="mb-1">Change Product <br/> Photo</p>
            <p class="mb-0" style="cursor: pointer; text-decoration: underline" @click="triggerModal">View</p>
          </div>
        </div>
        <div class="col-9 d-inline-flex">
          <div class="col-6 pl-0">
            <label class="mb-3">Product Code</label>
            <input
              v-model="item.code"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-6 pr-0">
            <label class="mb-3">Product Name</label>
            <input
              v-model="item.name"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12">
          <input
            v-model="item.description"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-4">
          <label class="mb-3">Product Vintage</label>
          <input
            v-model="item.vintage"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Product UOM</label>
          <input
            v-model="item.uom"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Product Market Price (RM)</label>
          <input
            v-model="item.price"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-4">
          <div class="form-group flex-grow-1">
            <label class="mb-3">Product Stock Balance</label>
            <div class="position-relative">
              <span class="custom-icon minus-icon descrease-icon" @click="changeStockBalance(false)"></span>
              <input 
                v-model="stockBalance"
                class="form-control px-5 text-center"
              />
              <!-- <span class="custom-icon add-icon increase-icon" @click="changeStockBalance(true)"></span> -->
            </div>
            <span v-if="formErrors.stockBalance" class="invalid-field">{{ formErrors.stockBalance }}</span>
          </div> 
        </div>
      </div>
    </form>
    <div class="update-stock-details-footer">
      <router-link :to="{name: 'View User', params: { item: user }}" class="btn btn-light mr-3" >Back to Stocks</router-link>
      <button class="btn btn-primary" @click="handleSubmit" :disabled="isLoading">Update Stock</button>
    </div>
    <BModal ref="view-modal" size="lg" hide-footer>
      <div>
        <b-carousel
          v-if="uploadingImages.length > 0"
          ref="pic-carousel"
          id="carousel-1"
          :interval="interval"
          img-width="524"
          img-height="100"
          class="px-3"
          style="text-shadow: 1px 1px 2px #333;"
        >
          <!-- Text slides with image -->
          <b-carousel-slide class="px-5"
            :img-src="uploadingImages[0].url"
          ></b-carousel-slide>
        </b-carousel>
        <ol v-if="uploadingImages.length > 0" class="carousel-indicators position-relative my-5 px-5">
          <li role="button" v-for="(pic, index) in uploadingImages" :key="index" style="cursor: inherit">
            <img :src="pic.url" style="object-fit: cover;" width="100" class="h-100"/>
          </li>
        </ol>
      </div>
    </BModal>
  </div>
</template>
<script>
import { BModal } from "bootstrap-vue";
import { UPDATE_STOCK_REQUEST, GET_PRODUCT_REQUEST } from "../../store/modules/product/actions";
import Multiselect from 'vue-multiselect';
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { GET_USER_PROFILE_REQUEST } from "../../store/modules/user-management/actions";

export default {
  name: "UpdateStockDetails",
  components: {
    BModal: BModal,
    Multiselect
  },
  data(){
    return{
      stockBalance: 0,
      status: null,
      uploadingImages: [],
      interval: 0,
      item: {...this.product},
      user: {...this.member},
      isLoading: false,
      formErrors: {},
      statusOptions: [{name: 'Active', value: 'ACTIVE'}, {name: 'Inactive', value: 'INACTIVE'}]
    }
  },
  methods: {
    triggerUpload(){
      document.getElementById('file-input').click();
    },
    changeStockBalance(action){
      this.stockBalance = Number(this.stockBalance);
      if(action){
        this.stockBalance += 1;
      } else if(this.stockBalance != 0) {
        this.stockBalance -= 1;
      }
    },
    getActiveCoverImage(){
      let hasCover = this.item.uploadList ? this.item.uploadList.find(el => el.cover === true) : false;
      if(hasCover){
        return hasCover.url;
      } else {
        return '/assets/default_image.jpeg';
      }
    },
    triggerModal(){
      if(this.item.uploadList && this.item.uploadList.length !== 0){
        this.$refs["view-modal"].toggle("#toggle-btn");
      }
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (this.item.stockBalance === 0 && !this.stockBalance) {
        this.formErrors.stockBalance = "Stock Balance is required";
        error++;
      } else if(this.stockBalance > this.item.stockBalance){
        this.formErrors.stockBalance = `New Stock Balance should be less than original Stock Balance. Current Stock Balance is ${this.item.stockBalance}`;
        error++;
      } else if(this.stockBalance < 0){
        this.formErrors.stockBalance = "Stock Balance can't be less than 0";
        error++;
      }

      return !error;
    },
    getProductItem(){
      this.isLoading = true;
      this.$store.dispatch(GET_PRODUCT_REQUEST, this.$route.params.product_id).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "There was a new update on this product's stock balance. Please try again now",
            timeOut: true
          });
        } 
        this.isLoading = false;
      })
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const product = {};
        product.id = this.item.id;
        product.oriQuantity = this.item.stockBalance;
        product.stockBalance = Number(this.stockBalance);
        product.user = {uuid: this.user.uuid};
        this.$store.dispatch(UPDATE_STOCK_REQUEST, product).then(res => {
          if(res.status === 'ok'){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Stock is successfully updated",
              timeOut: true
            });
            // this.getProductList();
          } else if(res.errorCode === "request.error.stock.balance.new.update"){
            this.getProductItem();
          }
          this.isLoading = false;
        })
      }
    },
    getUser(){
      const user = {uuid: this.$route.params.id};
      this.$store.dispatch(GET_USER_PROFILE_REQUEST, user).then(res => {
        if(res.status === 'ok' && res.result){
          this.user = {...res.result};
          document.getElementById("header-route-name").previousSibling.innerHTML = 
          this.user.memberNo + ' /&nbsp;';
          document.getElementById('header-route-name').textContent = 'Update Stock';
          this.getProduct();
        } 
      })
    },
    getProduct(){
      this.$store.dispatch(GET_PRODUCT_REQUEST, {id: this.$route.params.product_id, user: this.user.uuid}).then(res => {
        if(res.status === 'ok' && res.result){
          this.item = {...res.result};
          this.status = this.statusOptions.find(el => el.value === res.result.status);
          this.stockBalance = res.result.stockBalance;
          res.result.uploadList.map(el => {
            this.uploadingImages.push(el);
          });
        } 
      })
      
    }
  },
  mounted(){
    this.getUser();
  },
}
</script>
<style scoped>
.update-stock-details-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  text-align: center;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
.remove-icon{
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  z-index: 9999;
  display: flex;
  cursor: pointer;
}
.current-active-cover-picture{
  border: 3px solid #1BB28B;
}
.active-cover-picture{
  border: 2px solid #1BB28B;
}
#file-input{
  display: none;
}
.product-pic{
  object-fit: cover;
  height: 100px;
  border-radius: 10px;
}
.overlay{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.44);
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>