<template>
  <div class="w-100 d-flex px-5" style="flex-direction: column; padding-bottom: 20rem">
    <form class="w-100 mt-5" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-12">
          <div class="col-8 d-flex mt-5 px-4" style="flex-direction: column">
            <label class="mb-3">Select a member</label>
            <multiselect
              ref="member"
              v-model="member"
              :searchable="true"
              :options="memberOptions"
              label="name"
              track-by="id"
              placeholder="Select member"
              :loading="!memberOptions"
              :allow-empty="false"
              :show-labels="false"
              @input="type === 'products' ? getProductList() : getCollectionList()"
            ></multiselect>
            <span v-if="formErrors.member" class="invalid-field mt-3">{{ formErrors.member }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="col-8 d-flex mt-5 px-4" style="flex-direction: column">
            <label class="mb-3">Choose the type of DO</label>
            <div class="d-inline-flex">
              <input
                v-model="type"
                name="products"
                type="radio"
                value="products"
              />
              <label class="text-label sales-data mr-4" @click="changeType('products')">Ad-hoc DO</label>
              <input
                v-model="type"
                type="radio"
                name="collections"
                value="collections"
              />
              <label class="text-label sales-data" @click="changeType('collections')">Collection DO</label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type === 'products'">
        <div v-for="(product, index) in selectedProducts" :key="index">
          <div class="row">
            <div class="col-12 d-inline-flex">
              <div class="col-8 d-flex mt-5 px-4" style="flex-direction: column">
                <multiselect
                  ref="product"
                  v-model="selectedProducts[index]"
                  :searchable="true"
                  :options="productOptions"
                  label="name"
                  track-by="id"
                  placeholder="Select product"
                  :loading="!productOptions"
                  :allow-empty="false"
                  :show-labels="false"
                  @input="updateProductList"
                >
                  <template slot="option" slot-scope="props">
                    <div class="row px-4 justify-content-between">
                      <span>{{props.option.name}}</span>
                      <b><span class="stock-number-do">{{props.option.stockBalance}}</span></b>
                    </div>
                  </template>
                </multiselect>
              </div>
              <div v-if="index !== 0" class="col-2 d-flex mt-5 py-2">
                <div class="custom-icon trash-icon ml-4 my-auto" style="cursor: pointer" @click="removeProduct(index)"></div>
              </div>
            </div>
          </div>
          <div v-if="selectedProducts[index]" class="row mt-5 pb-5 mx-4 product-add-wrapper">
            <div class="col-2 pl-0">
              <label>Product Code</label>
              <input
                v-model="selectedProducts[index].code"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Product UOM</label>
              <input
                v-model="selectedProducts[index].uom"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Product Vintage</label>
              <input
                v-model="selectedProducts[index].vintage"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Select Quantity</label>
              <div class="position-relative">
                <span class="custom-icon minus-icon descrease-icon" @click="changeQuantity(selectedProducts[index], false)"></span>
                <input 
                  v-model="selectedProducts[index].quantity"
                  class="form-control px-5 text-center quantity-input"
                  type="number"
                />
                <span class="custom-icon add-icon increase-icon" @click="changeQuantity(product, true, selectedProducts[index].quantity)"></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="col-12">
            <p v-if="formErrors.products" class="invalid-field mt-3">{{ formErrors.products }}</p>
            <p v-if="formErrors.quantity" class="invalid-field mt-3">{{ formErrors.quantity }}</p>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-outline-secondary" @click="addProductSelect">Add Product</button>
          </div>
        </div>
      </div>
      <div v-if="type === 'collections'">
        <div v-for="(product, index) in selectedProducts" :key="index">
          <div class="row">
            <div class="col-12 d-inline-flex">
              <div class="col-8 d-flex mt-5 px-4" style="flex-direction: column">
                <multiselect
                  ref="product"
                  v-model="selectedProducts[index]"
                  :searchable="true"
                  :options="productCollectionOptions"
                  label="name"
                  track-by="id"
                  placeholder="Select product"
                  :loading="!productCollectionOptions"
                  :allow-empty="false"
                  :show-labels="false"
                  @input="updateProductList"
                >
                  <template slot="option" slot-scope="props">
                    <div class="row px-4 justify-content-between">
                      <span>{{props.option.product.name}}{{" (" + props.option.collectionTimeStr + ")"}}</span>
                      <b><span class="stock-number-do">{{props.option.quantity}}</span></b>
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span>{{option.product.name}}</span>
                  </template>
                </multiselect>
              </div>
              <div v-if="index !== 0" class="col-2 d-flex mt-5 py-2">
                <div class="custom-icon trash-icon ml-4 my-auto" style="cursor: pointer" @click="removeProduct(index)"></div>
              </div>
            </div>
          </div>
          <div v-if="selectedProducts[index]" class="row mt-5 pb-5 mx-4 product-add-wrapper">
            <div class="col-2 pl-0">
              <label>Product Code</label>
              <input
                v-model="selectedProducts[index].product.code"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Product UOM</label>
              <input
                v-model="selectedProducts[index].product.uom"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Product Vintage</label>
              <input
                v-model="selectedProducts[index].product.vintage"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Quantity</label>
              <input
                v-model="selectedProducts[index].quantity"
                :disabled="true"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <label>Collection Date &amp; Time</label>
              <input
                v-model="selectedProducts[index].collectionTimeStr"
                :disabled="true"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="col-12">
            <p v-if="formErrors.products" class="invalid-field mt-3">{{ formErrors.products }}</p>
            <p v-if="formErrors.quantity" class="invalid-field mt-3">{{ formErrors.quantity }}</p>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-outline-secondary" @click="addProductSelect">Add Product</button>
          </div>
        </div>
      </div>
    </form>
    <div class="generate-order-footer mx-auto">
      <div class="row justify-content-center">
        <button class="btn btn-primary" :disabled="isLoading" @click="handleSubmit">Generate DO</button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { CREATE_DO_REQUEST, GET_DELIVERY_ORDER_LIST_REQUEST } from "../../store/modules/delivery-order/actions";
import { GET_STOCK_LIST_REQUEST, GET_PRODUCT_LIST_BY_USER_REQUEST } from "../../store/modules/product/actions";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { convertDateFormat } from "../../utils/helpers";

export default {
  name: "DOGeneration",
  components: {
    Multiselect
  },
  data(){
    return{
      formErrors: {},
      isLoading: false,
      member: null,
      type: "products",
      memberOptions: [],
      productOptions: [],
      selectedProducts: [null],
      productCollectionOptions: []
    }
  },
  methods: {
    changeQuantity(item, action){
      let product = this.selectedProducts.find(el => el === item);
      product.quantity = Number(item.quantity);
      if(action){
        
        this.selectedProducts.find(el => el === item).quantity += 1;
      } else if(product.quantity != 0) {
        this.selectedProducts.find(el => el === item).quantity -= 1;
      }
    },
    changeType(type){
      this.selectedProducts = [null];
      this.type = type;
      this.formErrors = {};
      if(this.member && type === 'products'){
        this.getProductList();
      } else if(this.member && type === 'collections'){
        this.getCollectionList();
      }
    },
    removeProduct(index){
      if(this.type === 'products' && this.productOptions.find(el => this.selectedProducts[index] === el)){
        this.productOptions.find(el => this.selectedProducts[index] === el).$isDisabled = false;
      } else if(this.productOptions.find(el => this.selectedProducts[index] === el)) {
        this.productCollectionOptions.find(el => this.selectedProducts[index] === el).$isDisabled = false;
      }
      this.selectedProducts.splice(index, 1);
    },
    addProductSelect(){
      this.selectedProducts.push(null)
    },
    updateProductList(item){
      if(this.type === 'products') {
        this.productOptions.map(el => {
          if(el === item || this.selectedProducts.find(product => product === el)){
            el.$isDisabled = true;
            return el;
          } else {
            el.$isDisabled = false;
            return el;
          }
        }) 
        this.$set(item, 'quantity', 0);
      }
      else{
        this.productCollectionOptions.map(el => {
          if(el === item || this.selectedProducts.find(product => product === el)){
            el.$isDisabled = true;
            return el;
          } else {
            el.$isDisabled = false;
            return el;
          }
        })
      }
      this.selectedProducts.filter(item => item !== null).push(item);
    },
    getProductList(){
      this.selectedProducts = [null]
      const user = {uuid: this.member.uuid};
      const filter = {user: user};
      this.$store.dispatch(GET_PRODUCT_LIST_BY_USER_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.productOptions = res.result.list.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
      })
    },
    getCollectionList(){
      this.selectedProducts = [null]
      const user = {uuid: this.member.uuid};
      const filter = {status: "ACCEPTED", user: user};
      this.$store.dispatch(GET_DELIVERY_ORDER_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.productCollectionOptions = res.result.list.sort((a,b) => (a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0));
        }
      })
    },
    getUsersList(){
      const type = "MEMBER_TYPE";
      const filter = {type: type};
      this.$store.dispatch(GET_STOCK_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.memberOptions = res.result.list.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
      });        
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.member) {
        this.formErrors.member = "User is required";
        error++;
      } 
      if (this.selectedProducts.filter(el => el === null).length > 0) {
        this.formErrors.products = "Product is required";
        error++;
      } else if (this.selectedProducts.filter(el => el.quantity > el.stockBalance || el.quantity === 0).length > 0) {
        this.formErrors.quantity = "Stock Balance should be more than 0 and less or equals to the stock balance of the selected product in " + this.selectedProducts.find(el => el.quantity > el.stockBalance || el.quantity === 0).name;
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const user = {uuid: this.member.uuid};
        const collectionTimeStr = convertDateFormat(new Date(new Date().getTime() + 5*60000), "DD/MM/YYYY HH:mm");
        let collectionList = [];
        if(this.type === 'products'){
          this.selectedProducts.map(item => {
            collectionList.push({
              user: user, product: {id: item.id}, quantity: item.quantity, collectionTimeStr: collectionTimeStr
            })
          });
        } else {
          this.selectedProducts.map(item => {
            collectionList.push({id: item.id})
          });
        }
        this.$store.dispatch(CREATE_DO_REQUEST, {user: user, collectionList: collectionList}).then(res => {
          if(res.status === "ok" && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "DO has been successfully created",
              timeOut: true
            });
            this.$router.push('/delivery-order/generate-order/'+ res.result.id);
          }
          this.isLoading = false;
        })
      }
    }
  },
  mounted(){
    this.getUsersList();
  }
}
</script>
<style scoped>
.product-add-wrapper{
  border-bottom: 1px solid lightgray;
}
/* Chrome, Safari, Edge, Opera */
.form-control.quantity-input::-webkit-outer-spin-button,
.form-control.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-control.quantity-input[type=number] {
  -moz-appearance: textfield;
}
</style>