<template>
  <div class="px-5">
    <div v-if="bannerList.length > 0" class="mt-5 banner-row-wrapper">
      <BannerComponent
        v-for="banner in bannerList" :key="banner.id"
        :item="banner"
        @update-list="getBannersList"
      />
    </div>
    <div v-else-if="isDataLoading" class="row justify-content-center mt-5 pt-5">
      <div class="loader"></div>
    </div>
    <div v-else>
      <h5 class="text-center mt-5 pt-5">No records found</h5>
    </div>
    <tr class="footer-pagination-row">
      <td class="footer-pagination-row-inner px-5">
        <div class="row vbt-pagination-row no-gutters px-5 justify-content-end">
          <div class="col-4 justify-content-center d-flex">
            <button class="btn btn-secondary ml-4 my-auto" @click="triggerModal">
              Create New Banner
            </button>
          </div>
          <div class="col-4 d-inline-flex justify-content-end">
            <div class="col-4 mr-3">
              <select
                id="per_page"
                class="form-control per-page-input"
                v-model="queryParams.per_page"
              >
                <option
                  v-for="(option, key, index) in queryParams.per_page_options"
                  :key="index"
                  :value="option"
                ><b>{{option}}</b> per page</option>
              </select>
            </div>
            <div class="my-auto mr-0 ml-2">
              <div>
                <Pagination
                  :page.sync="queryParams.page"
                  :per_page.sync="queryParams.per_page"
                  :per_page_options="queryParams.per_page_options"
                  :total="bannerListTotal"
                >
                  <template slot="vbt-paginataion-previous-button">
                    <slot name="paginataion-previous-button">
                      &laquo;
                    </slot>
                  </template>
                  <template slot="vbt-paginataion-next-button">
                    <slot name="paginataion-next-button">
                        &raquo;
                    </slot>
                  </template>
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <BModal ref="view-modal" size="xl" hide-footer>
      <div>
        <form class="px-5" @submit.prevent="handleSubmit">
          <h5 class="text-center">Create New Banner</h5>
          <div class="row my-5">
            <div class="col-4">
              <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
                <label class="mb-3">Banner Status</label>
                <multiselect
                  ref="status"
                  v-model="form.status"
                  :options="bannerOptions"
                  label="name"
                  track-by="value"
                  placeholder="Select status"
                  class="news-status-input"
                  :allow-empty="false"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
                  </template>
                </multiselect>
                <span v-if="formErrors.status" class="invalid-field">{{ formErrors.status }}</span>
              </div>
            </div>
          </div>
          <div class="row my-5">
            <div class="col-12">
              <label class="mb-3">Banner Image</label>
              <div v-if="form.url.length > 0" class="position-relative">
                <div class="text-center">
                  <img :src="form.url" class="banner-image"/>
                  <div class="remove-icon" @click="removeUrl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16.971 16.971" class="m-auto">
                      <g id="Group_1226" data-name="Group 1226" transform="translate(-173.241 -200.818) rotate(45)">
                        <line id="Line_74" data-name="Line 74" y1="18" transform="translate(276.5 10.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                        <line id="Line_75" data-name="Line 75" y1="18" transform="translate(285.5 19.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div v-else-if="isLoadingPic" class="row justify-content-center">
                <div class="loader"></div>
              </div>
              <div v-else class="dropzone justify-content-center d-flex" @click="triggerDropzone">
                <div class="m-auto">
                  <div class="custom-icon upload-icon mx-auto mb-3"></div>
                  <p>Click to browse an image</p>
                </div>
              </div>
              <input 
                ref="bannerDropzone" 
                name="file" 
                accept="image/*" 
                type="file" 
                class="d-none"
                @input="getUrl"
              />
              <span v-if="formErrors.url" class="invalid-field">{{ formErrors.url }}</span>
            </div>
          </div>
        </form>
        <div class="row mt-5 justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" :disabled="isLoading" @click="handleSubmit">Create</button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import Pagination from "../../components/ui/VueBootstrap4Table/Pagination";
import BannerComponent from "../../components/banner/BannerComponent";
import { BModal } from "bootstrap-vue";
import Multiselect from 'vue-multiselect';
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { CREATE_NEW_BANNER_REQUEST, GET_BANNER_LIST_REQUEST } from "../../store/modules/banner/actions";
import { UPLOAD_IMAGE_REQUEST } from "../../store/modules/product/actions";

export default {
  name: "Banner",
  components: {
    Pagination,
    BModal,
    Multiselect,
    BannerComponent,
  },
  watch: {
    'queryParams.page': {
      handler(){
        this.getBannersList();
      }
    },
    'queryParams.per_page': {
      handler(){
        this.getBannersList();
      }
    }
  },
  data(){
    return{
      queryParams: {
        global_search: "",
        per_page: 10,
        page: 1,
        per_page_options: [10, 25, 50, 100]
      },
      disabledDates: {
        to: new Date()
      },
      form: {
        url: ''
      },
      isDataLoading: false,
      isLoading: false,
      isLoadingPic: false,
      formErrors: {},
      bannerList: [],
      bannerListTotal: 0,
      bannerOptions: [
        {name: 'Active', value: 'ACTIVE'},
        {name: 'Inactive', value: 'INACTIVE'},
      ],
    }
  },
  methods: {
    // changeQueryParams(value){
    //   this.queryParams.global_search = value;
    // },
    triggerDropzone(){
      this.$refs.bannerDropzone.click();
    },
    removeUrl(){
      this.form.url = "";
    },
    getUrl(event){
      this.isLoadingPic = true;
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = ()=> {
        let imageBase64 = reader.result;
        this.$store.dispatch(UPLOAD_IMAGE_REQUEST, imageBase64).then(res => {
          if(res.status === 'ok' && res.result){
            this.form.url = res.result.url;
          }
          this.isLoadingPic = false;
        })
      }
      //   reader.readAsDataURL(file);
      // }
    },
    triggerModal(){
      this.$refs["view-modal"].toggle("#toggle-btn");
    },
    getBannersList(){
      this.isDataLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      const filter = {page: page, limit: limit, search: search};
      this.$store.dispatch(GET_BANNER_LIST_REQUEST, filter).then(res => {
        if(res.status === 'ok' && res.result){
          this.bannerList = res.result.list;
          this.bannerListTotal = res.result.total;
        }
        this.isDataLoading = false;
      })
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.status) {
        this.formErrors.status = "Status is required";
        error++;
      } 
      if (this.form.url.length === 0) {
        this.formErrors.url = "Image is required";
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const banner = {...this.form};
        banner.status = banner.status.value;
        this.$store.dispatch(CREATE_NEW_BANNER_REQUEST, banner).then(res => {
          if(res.status === 'ok' && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Banner has been successfully updated",
              timeOut: true
            });
            this.form = {url: ''};
            this.triggerModal();
            this.getBannersList();
          }
          this.isLoading = false;
        })
      }
    },
  },
  mounted(){
    this.getBannersList();
  }
}
</script>
<style scoped>
#search{
  padding-left: 20px!important;
}
.upload-icon{
  height: 90px;
  width: 100%;
}
.remove-icon{
  position: absolute;
  top: -10px;
  left: 62%;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  z-index: 9999;
  display: flex;
  cursor: pointer;
}
.banner-image{
  height: 169px;
  width: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.footer-pagination-row-inner{
  padding: .75rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}

.banner-row-wrapper{
  margin-bottom: 20rem;
}
.dropzone{
  height: 200px;
  border: dotted;
  border-radius: 10px;
  background: none;
  cursor: pointer;
}
.form-control.per-page-input{
  width: auto;
}
</style>