<template>
  <div>
    <div class="row">
      <b-tabs v-model="tabIndex" class="content-d-none w-100" @input="getNotificationsList">
        <b-tab title="New"></b-tab>
        <b-tab title="Read"></b-tab>
      </b-tabs>
    </div>
    <div class="px-5">
      <div class="row justify-content-end mt-4">
        <div class="col-8 d-inline-flex justify-content-end ">
          <div class="col-6">
            <b-input-group class="form-group m-2" id="search-group">
              <b-form-input
                id="search"
                ref="global_search"
                placeholder="Search Notifications"
                type="text"
                class="form-control px-0"
                @input="changeQueryParams"
                @keyup.enter="getNotificationsList"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-2 d-flex">
            <div class="input-group-append m-auto" @click="getNotificationsList">
              <span class="input-group-text btn btn-light" style="color: white">
                Search
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="notificationsList.length > 0" class="mt-5 notifications-row-wrapper">
        <NotificationComponent
          v-for="notification in notificationsList" :key="notification.id"
          :item="notification"
          @update-list="getNotificationsList"
        />
      </div>
      <div v-else-if="isLoading" class="row justify-content-center mt-5 pt-5">
        <div class="loader"></div>
      </div>
      <div v-else>
        <h5 class="text-center mt-5 pt-5">No records found</h5>
      </div>
      <tr class="footer-pagination-row">
        <td class="footer-pagination-row-inner px-5">
          <div class="row vbt-pagination-row no-gutters justify-content-end px-5">
            <div class="col-4 d-inline-flex justify-content-end">
              <div class="col-4 mr-3">
                <select
                  id="per_page"
                  class="form-control per-page-input"
                  v-model="queryParams.per_page"
                >
                  <option
                    v-for="(option, key, index) in queryParams.per_page_options"
                    :key="index"
                    :value="option"
                  ><b>{{option}}</b> per page</option>
                </select>
              </div>
              <div class="my-auto mr-0 ml-2">
                <div>
                  <Pagination
                    :page.sync="queryParams.page"
                    :per_page.sync="queryParams.per_page"
                    :per_page_options="queryParams.per_page_options"
                    :total="notificationsListTotal"
                  >
                    <template slot="vbt-paginataion-previous-button">
                      <slot name="paginataion-previous-button">
                        &laquo;
                      </slot>
                    </template>
                    <template slot="vbt-paginataion-next-button">
                      <slot name="paginataion-next-button">
                          &raquo;
                      </slot>
                    </template>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </div>
  </div>
</template>
<script>
import Pagination from "../../components/ui/VueBootstrap4Table/Pagination";
import { GET_NOTIFICATIONS_LIST_REQUEST } from "../../store/modules/notifications/actions";
import NotificationComponent from "../../components/notifications/NotificationComponent";

export default {
  name: "Notifications",
  components: {
    Pagination,
    NotificationComponent
  },
  data(){
    return{
      tabIndex: 0,
      queryParams: {
        global_search: "",
        per_page: 10,
        page: 1,
        per_page_options: [10, 25, 50, 100]
      },
      notificationsListTotal: 0,
      notificationsList: [],
      isLoading: false,
    }
  },
  watch: {
    'queryParams.page': {
      handler(newValue){
        this.queryParams.page = newValue;
        this.getNotificationsList();
      }
    },
    'queryParams.per_page': {
      handler(newValue){
        this.queryParams.per_page = newValue;
        this.getNotificationsList();
      }
    }
  },
  methods: {
    changeQueryParams(value){
      this.queryParams.global_search = value;
    },
    getNotificationsList(){
      this.isLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      const type = "NOTIFICATION";
      const read = this.tabIndex === 0 ? false : true;
      const filter = {page: page, limit: limit, search: search, type: type, read: read};
      this.$store.dispatch(GET_NOTIFICATIONS_LIST_REQUEST, filter).then(res => {
        if(res.status === 'ok' && res.result){
          this.notificationsList = res.result.list;
          this.notificationsListTotal = res.result.total;
        }
        this.isLoading = false;
      })
    }
  },
  mounted(){
    this.getNotificationsList();
  }
}
</script>
<style scoped>
#search{
  padding-left: 20px!important;
}

.footer-pagination-row-inner{
  padding: .75rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}

.notifications-row-wrapper{
  margin-bottom: 20rem;
}

.form-control.per-page-input{
  width: auto;
}
</style>