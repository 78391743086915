import {
  getConstantList,
  getLastSync,
  getSync
} from "@/api/constant";
import {
  GET_CONSTANT_LIST_REQUEST,
  GET_CONSTANT_LIST_SUCCESS,
  GET_CONSTANT_LIST_ERROR,
  GET_LAST_SYNC_REQUEST,
  GET_LAST_SYNC_SUCCESS,
  GET_LAST_SYNC_ERROR,
  SYNC_REQUEST,
  SYNC_SUCCESS,
  SYNC_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false,
  lastSync: ""
};

const getters = {
  getLastSync: state => state.lastSync ? state.lastSync.text : '',
}

const actions = {
  [GET_CONSTANT_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_CONSTANT_LIST_REQUEST);
      getConstantList(postData)
        .then(resp => {
          commit(GET_CONSTANT_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_CONSTANT_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_LAST_SYNC_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_LAST_SYNC_REQUEST);
      getLastSync()
        .then(resp => {
          commit(GET_LAST_SYNC_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_LAST_SYNC_ERROR, err);
          reject(err);
        });
    });
  },
  [SYNC_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(SYNC_REQUEST);
      getSync()
        .then(resp => {
          commit(SYNC_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SYNC_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_CONSTANT_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_CONSTANT_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_CONSTANT_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_LAST_SYNC_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_LAST_SYNC_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      lastSync: data.result,
      hasLoadedOnce: true
    });
  },
  GET_LAST_SYNC_ERROR: state => {
    Object.assign(state, {
      status: "error",
      lastSync: "",
      hasLoadedOnce: true
    });
  },
  SYNC_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SYNC_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  SYNC_ERROR: state => {
    Object.assign(state, {
      status: "error",
      lastSync: "",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};