import Vue from "vue";
import admin_layout from "../layouts/admin-layout.vue";
import auth_layout from "../layouts/auth-layout.vue";
import BootstrapVue from "bootstrap-vue";
import statusNotification from "../components/ui/statusNotification.vue";
import VeeValidate from "vee-validate";

Vue.component("admin-layout", admin_layout);
Vue.component("auth-layout", auth_layout);
Vue.component("status-notification", statusNotification);

window.$ = window.jQuery = require("jquery");
window.Vue = Vue;

Vue.use(BootstrapVue);

const config = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: "errors", // change if property conflicts
  events: "input|blur",
  fieldsBagName: "fields",
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: "validations", // the nested key under which the validation messages will be located
  inject: true,
  locale: "en",
  validity: false,
  useConstraintAttrs: true
};
Vue.use(VeeValidate, config);
