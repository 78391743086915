<template>
  <div id="delivery-order-wrapper">
    <div class="row delivery-order-header-wrapper justify-content-between">
      <div class="col-8 d-inline-flex">
        <p class="my-auto"><span class="delivery-order-title">DO Print Count:</span> {{collection.count ? collection.count : 0}}</p> 
        &nbsp; &nbsp; <div class="my-auto vertical-line-sm"></div> &nbsp; &nbsp;
        <p class="my-auto"><span class="delivery-order-title">Request Date:</span> {{changeDateFormat(collection.createdDate)}}</p> 
      </div>
      <div class="col-4 text-right">
        <div class="d-inline-flex my-auto w-100">
          <span class="delivery-order-title mr-2 w-100 my-auto">Transaction Status: </span>
          <b><span :class="'status-span-' + collection.status.toLowerCase()">
            {{collection.status.charAt(0).toUpperCase() + collection.status.slice(1).toLowerCase()}}
          </span></b>
        </div>
      </div>
    </div>
    <div v-if="collection.status === 'COMPLETED'" class="row my-5">
      <div class="col-12 d-inline-flex">
        <div class="col-6">
          <label class="mb-3">DO Number</label>
          <input
            v-model="collection.doNumber"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-6">
          <label class="mb-3">DO Generate Date</label>
          <input
            v-model="doGenerationDate"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-12 d-inline-flex">
        <div class="col-4">
          <label class="mb-3">Membership Code</label>
          <input
            v-model="collection.user.memberNo"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Membership Name</label>
          <input
            v-model="collection.user.name"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Member Contact Number</label>
          <input
            v-model="collection.user.mobilePhone"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div v-if="collection.status === 'COMPLETED'" class="collection-list-wrapper">
      <div class="row mt-5">
        <div class="col-12 d-inline-flex">
          <div class="col-1">
            <label class="mb-3">No.</label>
          </div>
          <div class="col-2">
            <label class="mb-3">Code</label>
          </div>
          <div class="col-2">
            <label class="mb-3">Name</label>
          </div>
          <div class="col-2">
            <label class="mb-3">Vintage</label>
          </div>
          <div class="col-2">
            <label class="mb-3">UOM</label>
          </div>
          <div class="col-1">
            <label class="mb-3">Quantity</label>
          </div>
          <div class="col-2">
            <label class="mb-3">Collection Date &amp; Time</label>
          </div>
        </div>
      </div>
      <div v-for="item in collectionList" :key="item.id" class="row mb-3">
        <div class="col-12 d-inline-flex">
          <div class="col-1">
            <input
              v-model="item.doLine"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <input
              v-model="item.product.code"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <input
              v-model="item.product.name"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <input
              v-model="item.product.vintage"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <input
              v-model="item.product.uom"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-1">
            <input
              v-model="item.quantity"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-2">
            <input
              v-model="item.collectionTimeStr"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row my-5">
        <div class="col-12 d-inline-flex">
          <div class="col-4">
            <label class="mb-3">Product Code</label>
            <input
              v-model="collection.product.code"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <label class="mb-3">Product Name</label>
            <input
              v-model="collection.product.name"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <label class="mb-3">Vintage</label>
            <input
              v-model="collection.product.vintage"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12 d-inline-flex">
          <div class="col-4">
            <label class="mb-3">Product UOM</label>
            <input
              v-model="collection.product.uom"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <label class="mb-3">Quantity</label>
            <input
              v-model="collection.quantity"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <label class="mb-3">Collection Date &amp; Time </label>
            <input
              v-model="collection.collectionTimeStr"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <iframe id="print-do" name="print-do" :src="collection.doUrl" style="display:none;"></iframe>
    <div class="generate-order-footer">
      <div class="row justify-content-center">
        <router-link :to="{name: 'New Request', params: {tab: statusList.indexOf(status)}}" class="btn btn-light justify-content-center mr-5">Back to list</router-link>
        <div v-if="collection.status === 'COMPLETED'">
          <a 
            :href="baseURL + collection.doUrl.replace(/https?:\/\/[^\/]*\/?/i, '')" 
            :download="/[^/]*$/.exec(collection.doUrl)[0]"
            class="btn btn-light mr-4"
            style="color: white"
          >Download DO</a>
          <a 
            href="#" 
            class="btn btn-secondary mr-4"
            @click="printDO"
          >Print DO</a>
        </div>
        <div v-else-if="collection.status === 'ACCEPTED'" class="row justify-content-center">
          <button class="btn btn-primary mr-4" @click="generateDO">Generate DO</button>
          <button class="btn btn-secondary" @click="triggerModal('REJECTED')">Reject</button>
        </div>
        <div v-else-if="collection.status !== 'REJECTED' && collection.status !== 'CANCELLED'" class="row justify-content-center">
          <button class="btn btn-light mr-4" @click="triggerModal('REJECTED')">Reject</button>
          <button class="btn btn-primary mr-4" @click="triggerModal('ACCEPTED')">Accept</button>
        </div>
      </div>
    </div>
    <BModal ref="confirm-modal" size="md" hide-footer>
      <div class="px-5 pb-5">
        <div class="row justify-content-center mb-5">
          <h5>Are you sure you want to change the status to <b><i>{{status}}</i></b> ?</h5>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" @click="changeStatus(status)">Confirm</button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { 
  GET_COLLECTION_REQUEST, 
  GENERATE_DO_REQUEST, 
  CHANGE_STATUS_DO_REQUEST,
  GET_COLLECTION_LIST_REQUEST 
} from "../../store/modules/delivery-order/actions";
import { convertDateFormat } from "../../utils/helpers";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { BModal } from "bootstrap-vue";

export default {
  name: "GenerateOrder",
  components: {
    BModal
  },
  data(){
    return{
      collection: {
        status: '',
        user: {},
        product: {}
      },
      doGenerationDate: "",
      baseURL: window.location.origin + '/',
      total: 100, 
      status: '',
      statusList: ['PENDING', 'ACCEPTED', 'CANCELLED', 'REJECTED', 'COMPLETED'],
      collectionList: []
    }
  },
  methods: {
    changeDateFormat(date){
      return convertDateFormat(date, "DD/MM/YY");
    },
    triggerModal(status){
      this.status = status;
      this.$refs["confirm-modal"].toggle("#toggle-btn");
    },
    changeStatus(value){
      const collection = {};
      collection.id = this.collection.id;
      collection.status = value;
      this.$store.dispatch(CHANGE_STATUS_DO_REQUEST, collection).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Status of the DO has been changed",
            timeOut: true
          });
          this.$refs["confirm-modal"].toggle("#toggle-btn");
          this.getCollection();
        }
      })
    },
    printDO(){
      var pdfFrame = window.frames["print-do"];
      pdfFrame.focus();
      pdfFrame.print();
    },
    generateDO(){
      this.$store.dispatch(GENERATE_DO_REQUEST, {id: this.collection.id}).then(res => {
        if(res.status === 'ok'){
          this.getCollection();
        }
      })
    },
    getCollectionList(){
      this.$store.dispatch(GET_COLLECTION_LIST_REQUEST, {doNumber: this.collection.doNumber}).then(res => {
        if(res.status === 'ok'){
          this.collectionList = res.result;
        }
      })
    },
    getCollection(){
      this.$store.dispatch(GET_COLLECTION_REQUEST, {id: this.$route.params.id}).then(res => {
        if(res.status === 'ok'){
          this.collection = res.result;
          if(res.result.doGenerationDate) this.doGenerationDate = convertDateFormat(res.result.doGenerationDate, "DD/MM/YYYY");
          this.status = res.result.status;
          if(res.result.status === 'COMPLETED'){
            this.getCollectionList();
          }
        }
      })
    }
  },
  mounted(){
    this.getCollection();
  }
}
</script>
<style scoped>
.delivery-order-header-wrapper{
  background-color: #F4F9F4;
  padding: 10px 20px;
  border-radius: 10px;
}
.delivery-order-title{
  color: #545557;
}
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
p, span{
  cursor: default;
}
.collection-list-wrapper{
  padding-bottom: 20rem;
}
</style>