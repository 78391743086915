import request from "@/utils/request";
import {
  API_GET_CONSTANT_LIST,
  API_GET_LAST_SYNC,
  API_GET_SYNC
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getConstantList(type) {
  return await request({
    url: API_GET_CONSTANT_LIST,
    method: "post",
    data: {locale: locale, filter: {type: type}},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getLastSync() {
  return await request({
    url: API_GET_LAST_SYNC,
    method: "post",
    data: {locale: locale},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getSync() {
  return await request({
    url: API_GET_SYNC,
    method: "post",
    data: {locale: locale},
    timeout: DEFAULT_TIMEOUT
  });
}