<template>
  <div>
    <div class="row delivery-order-header-wrapper justify-content-between">
      <div class="col-8 d-inline-flex">
        <p class="my-auto"><span class="delivery-order-title">Thread Code:</span> {{thread.code}}</p> 
      </div>
      <div class="col-4 text-right">
        <div class="d-inline-flex my-auto w-100">
            <span class="delivery-order-title mr-2 w-100 my-auto">Transaction Status: </span>
            <b v-if="thread.status !== 'PENDING'"><span :class="'status-span-' + thread.status.toLowerCase()">
              {{thread.status.charAt(0).toUpperCase() + thread.status.slice(1).toLowerCase()}}
            </span></b>
            <b v-else><span :class="'status-span-' + thread.status.toLowerCase()">
              Booked
            </span></b>
        </div>
      </div>
    </div>
    <form @submit.prevent="handleSubmit" class="px-5">
      <div class="row my-5 pb-4">
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Thread Code</label>
            <input
              v-model="thread.code"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-4">
          <label class="mb-3">Thread Creation Date</label>
          <input
            v-model="thread.createdDate"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Thread Completion Date</label>
          <input
            v-model="thread.completeDate"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-5 pb-4">
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Thread Status</label>
            <multiselect
              ref="status"
              v-model="form.status"
              :searchable="true"
              :options="statusOptions"
              label="name"
              track-by="value"
              :disabled="thread.status === 'COMPLETED'"
              :loading="!statusOptions"
              :allow-empty="false"
              :show-labels="false"
              :class="thread.status === 'COMPLETED' && 'disabled'"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="col-4">
          <label class="mb-3">Trade Price (Total) (RM)</label>
          <input
            v-model="totalPrice"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-4">
          <label class="mb-3">Member (Seller)</label>
          <input
            v-model="thread.user.name"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Member (Buyer)</label>
          <input
            v-model="thread.bookedBy.name"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Product Item</label>
            <multiselect
              ref="product"
              v-model="form.product"
              :searchable="true"
              :options="productOptions"
              label="name"
              track-by="value"
              :disabled="true"
              placeholder="Select product"
              class="disabled"
              :loading="!productOptions"
              :allow-empty="false"
              :show-labels="false"
            ></multiselect>
          </div>
        </div>
        <div class="col-4">
          <label class="mb-3">Trade Quantity</label>
          <div class="position-relative">
            <span class="custom-icon minus-icon descrease-icon" @click="changeQuantity(false)"></span>
            <input 
              v-model="form.quantity"
              :disabled="true"
              class="form-control px-5 text-center"
            />
            <span class="custom-icon add-icon increase-icon" @click="changeQuantity(true)"></span>
          </div>
        </div>
        <div class="col-4">
          <label class="mb-3">Price (RM)</label>
          <input
            v-model="form.price"
            :disabled="true"
            class="form-control"
            @change="changeTotalPrice"
          />
        </div>
      </div>
    </form>
    <div class="generate-order-footer">
      <div class="row justify-content-center">
        <router-link :to="{name: 'New Thread', params: {stage: status.indexOf(originalStatus)}}" class="btn btn-light mr-3" >Back to Thread List</router-link>
        <button class="btn btn-secondary" @click="triggerModal">Update Thread</button>
      </div>
    </div>
    <BModal ref="confirm-modal" size="md" hide-footer>
      <div class="px-5 pb-5">
        <div class="row justify-content-center mb-5">
          <h5>Are you sure you want to change the status to <b><i class="invalid-field">{{this.form.status.name}}</i></b> ?</h5>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" @click="handleSubmit">Update</button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { GET_THREAD_REQUEST, UPDATE_THREAD_STATUS_REQUEST } from "../../store/modules/trading-forum/actions";
import Multiselect from 'vue-multiselect';
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { convertDateFormat } from "../../utils/helpers";
import { GET_PRODUCT_LIST_REQUEST } from "../../store/modules/product/actions";
import { BModal } from "bootstrap-vue";

export default {
  name: "ViewThread",
  components: {
    Multiselect,
    BModal
  },
  data(){
    return{
      thread: {
        status: '',
        user: {},
        threadProductList: [
          {product: {}}
        ],
        bookedBy: {},
        createdBy: {}
      },
      originalStatus: '',
      status: ['ACTIVE', 'PENDING', 'CANCELLED', 'PROCESSING','REJECTED', 'COMPLETED'],
      totalPrice: 0,
      productOptions: [],
      statusOptions: [],
      form: {
        status: {name: '', value: ''}
      }
    }
  },
  methods: {
    changeTotalPrice(){
      this.totalPrice = this.form.price * this.form.quantity;
    },
    triggerModal(){
      if(this.form.status.value === 'CANCELLED' || this.form.status.value === 'REJECTED'){
        this.$refs["confirm-modal"].toggle("#toggle-btn");
      } else {
        this.handleSubmit();
      }
    },
    changeQuantity(action){
      this.form.quantity = Number(this.form.quantity);
      if(action){
        this.form.quantity += 1;
      } else if(this.form.quantity != 0) {
        this.form.quantity -= 1;
      }
      this.changeTotalPrice();
    },
    handleSubmit(){
      const thread = {id: this.thread.id, status: this.form.status.value};
      this.$store.dispatch(UPDATE_THREAD_STATUS_REQUEST, thread).then(res => {
        if(res.result){
          this.thread = res.result;
          this.thread.createdDate = convertDateFormat(res.result.createdDate, "DD/MM/YYYY");
          this.thread.completedDate = convertDateFormat(res.result.completedDate, "DD/MM/YYYY");
          this.thread.bookedBy = res.result.bookedBy ? res.result.bookedBy : {};
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Thread has been successfully updated",
            timeOut: true
          });
          this.getStatusOptions();
          if(this.$refs["confirm-modal"].isShow){
            this.$refs["confirm-modal"].toggle("#toggle-btn");
          }
        }
      })
    },
    getThread(){
      const thread = {id: this.$route.params.id};
      this.$store.dispatch(GET_THREAD_REQUEST, thread).then(res => {
        if(res.result){
          this.thread = res.result;
          this.thread.createdDate = convertDateFormat(res.result.createdDate, "DD/MM/YYYY");
          this.thread.completedDate = convertDateFormat(res.result.completedDate, "DD/MM/YYYY");
          this.form.quantity = res.result.threadProductList[0].quantity;
          this.form.price = res.result.threadProductList[0].price;
          this.form.product = res.result.threadProductList[0].product;
          this.originalStatus = res.result.status;
          this.thread.bookedBy = res.result.bookedBy ? res.result.bookedBy : {};
          this.changeTotalPrice();
          this.getStatusOptions();
          document.getElementById('header-route-name').textContent = res.result.code;
        }
      })
    },
    getProductOptions(){
      this.$store.dispatch(GET_PRODUCT_LIST_REQUEST, {}).then(res => {
        if(res.status === "ok" && res.result){
          this.productOptions = res.result.list;
        }
      })
    },
    getStatusOptions(){
      if(this.thread.status === 'ACTIVE'){
        this.statusOptions = [
          {name: 'Active', value: 'ACTIVE'},
          {name: 'Rejected', value: 'REJECTED'},
        ]
      } else if(this.thread.status === 'CANCELLED'){
        this.statusOptions = [
          {name: 'Cancelled', value: 'CANCELLED'},
        ]
      } else if(this.thread.status === 'PENDING'){
        this.statusOptions = [
          {name: 'Booked', value: 'PENDING'},
          {name: 'Processing', value: 'PROCESSING'},
          {name: 'Rejected', value: 'REJECTED'},
        ]
      } else if(this.thread.status === 'REJECTED'){
        this.statusOptions = [
          {name: 'Rejected', value: 'REJECTED'},
        ]
      } else {
        this.statusOptions = [
          {name: 'Processing', value: 'PROCESSING'},
          {name: 'Rejected', value: 'REJECTED'},
          {name: 'Completed', value: 'COMPLETED'},
        ]
      }
      this.form.status = this.statusOptions.find(el => el.value === this.thread.status);
    }
  },
  mounted(){
    this.getThread();
    this.getProductOptions();
  }
}
</script>
<style scoped>
.form-control.price:before{
  content: 'RM';
  margin: auto 0px;
}
.form-control.price input{
  border: none;
  margin-left: 10px;
  color: #54555786;
  width: 100%;
  height: 100%;
}
.delivery-order-header-wrapper{
  background-color: #F4F9F4;
  padding: 10px 20px;
  border-radius: 10px;
}
.delivery-order-title{
  color: #545557;
}
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
p, span{
  cursor: default;
}
</style>