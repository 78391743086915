export const GET_TRADING_FORUM_LIST_REQUEST = "GET_TRADING_FORUM_LIST_REQUEST";
export const GET_TRADING_FORUM_LIST_SUCCESS = "GET_TRADING_FORUM_LIST_SUCCESS";
export const GET_TRADING_FORUM_LIST_ERROR = "GET_TRADING_FORUM_LIST_ERROR";
export const CREATE_THREAD_REQUEST = "CREATE_THREAD_REQUEST";
export const CREATE_THREAD_SUCCESS = "CREATE_THREAD_SUCCESS";
export const CREATE_THREAD_ERROR = "CREATE_THREAD_ERROR";
export const GET_THREAD_REQUEST = "GET_THREAD_REQUEST";
export const GET_THREAD_SUCCESS = "GET_THREAD_SUCCESS";
export const GET_THREAD_ERROR = "GET_THREAD_ERROR";
export const UPDATE_THREAD_STATUS_REQUEST = "UPDATE_THREAD_STATUS_REQUEST";
export const UPDATE_THREAD_STATUS_SUCCESS = "UPDATE_THREAD_STATUS_SUCCESS";
export const UPDATE_THREAD_STATUS_ERROR = "UPDATE_THREAD_STATUS_ERROR";