import moment from "moment";

export const convertDateFormat = function(UNIX_timestamp, format) {
  let date = new Date(UNIX_timestamp);
  return moment(String(date)).format(format);
};

export const emailValidate = function(email, reg) {
  let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (reg) {
    return re;
  } else {
    return re.test(email);
  }
};