<template>
  <div>
    <div class="row">
      <b-tabs v-model="tabIndex" class="content-d-none w-100">
        <b-tab title="New Threads"></b-tab>
        <b-tab title="Booked Threads"></b-tab>
        <b-tab title="Cancelled Threads"></b-tab>
        <b-tab title="Processing Threads"></b-tab>
        <b-tab title="Rejected Threads"></b-tab>
        <b-tab title="Completed Threads"></b-tab>
      </b-tabs>
    </div>
    <div class="row">
      <b-tabs class="nav-d-none w-100">
        <b-tab>
          <div>
            <VueBootstrap4Table 
              :rows="threadList" 
              :columns="columns" 
              :config="config"
              :total-rows="threadListTotal"
              @on-change-query="onChangeQuery"
              @refresh-data="getThreadList"
            >
              <template slot="empty-results">
                <div
                  v-if="!isDataLoading"
                  class="bold-text"
                >No records found</div>
                <div
                  v-if="isDataLoading"
                  class="row justify-content-center mt-3"
                ><div class="loader"></div></div>
              </template>
              <template slot="paginataion" slot-scope="props">
                {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
              </template>
              <template slot="created_date" slot-scope="props">
                <span>{{changeDate(props.row.createdDate)}}</span>
              </template>
              <template slot="status" slot-scope="props">
                <div v-if="props.row.status !== 'PENDING'">
                  <b><span :class="'status-content-' + props.row.status.toLowerCase()" class="status-wrapper">
                    {{props.row.status.charAt(0).toUpperCase() + props.row.status.slice(1).toLowerCase()}}
                  </span></b>
                </div>
                <div v-else>
                  <b><span :class="'status-content-' + props.row.status.toLowerCase()" class="status-wrapper">
                    Booked
                  </span></b>
                </div>
              </template>
              <template slot="action" slot-scope="props">
                <div class="col-8 mx-auto">
                  <router-link :to="'/trading-forum/view-thread/' + props.row.id" class="btn btn-primary justify-content-center">View &amp; Edit </router-link>
                </div>
              </template>
            </VueBootstrap4Table>
          </div>
        </b-tab>        
      </b-tabs>
    </div>
    <div class="trading-forum-footer">
      <div class="row justify-content-center">
        <!-- <button class="btn btn-light mr-3" @click="deleteThread" style="letter-spacing: 1px">Delete Thread</button> -->
        <router-link to="/trading-forum/create-new-thread" class="btn btn-secondary justify-content-center">Create New Thread</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table/VueBootstrap4Table";
import { GET_TRADING_FORUM_LIST_REQUEST } from "../../store/modules/trading-forum/actions";
import { convertDateFormat } from "../../utils/helpers";

export default {
  name: "NewThread",
  props: ['stage'],
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
  },
  watch: {
    'queryParams.page': {
      handler(){
        this.getThreadList();
      }
    },
    'queryParams.per_page': {
      handler(){
        this.getThreadList();
      }
    },
    tabIndex: {
      handler(newValue){
        this.onTabsChanged(newValue);
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          this.getThreadList();
        }
      }
    },
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    }
  },
  data(){
    return{
      tabIndex: this.stage || 0,
      threadList: [],
      status: ['ACTIVE', 'PENDING', 'CANCELLED', 'PROCESSING','REJECTED', 'COMPLETED'],
      threadListTotal: 0,
      isDataLoading: false,
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Thread Code",
        name: "code",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Created Date",
        name: "created_date",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "30%"
      },
      {
        label: "Transaction Status",
        name: "status",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "10%"
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Thread"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getList();
    },
    changeDate(date){
      return convertDateFormat(date, "DD/MM/YY")
    },
    onTabsChanged(index){
      if(index === 0 && document.getElementById('header-route-name')){
        document.getElementById('header-route-name').textContent = 'New Threads';
      } else if(index === 1){
        document.getElementById('header-route-name').textContent = 'Booked Threads';
      } else if(index === 2){
        document.getElementById('header-route-name').textContent = 'Cancelled Threads';
      } else if(index === 3){
        document.getElementById('header-route-name').textContent = 'Processing Threads';
      } else if(index === 4){
        document.getElementById('header-route-name').textContent = 'Rejected Threads';
      } else if(index === 5){
        document.getElementById('header-route-name').textContent = 'Completed Threads';
      }
      this.getThreadList();
    },
    getActiveCoverImage(row){
      let hasCover = row.threadProductList[0].product.uploadList.find(el => el.cover === true);
      if(row.threadProductList[0].product.uploadList.length > 0 && hasCover){
        return hasCover.url;
      } else {
        return '/assets/default_image.jpeg';
      }
    },
    getThreadList(){
      this.isDataLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, search: search, orderCol: orderCol, desc: desc};
      filter.status = this.status[this.tabIndex || 0];
      this.$store.dispatch(GET_TRADING_FORUM_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.threadList = res.result.list;
          this.threadListTotal = res.result.total;
        }
        this.isDataLoading = false;
      })
    }
  },
  mounted(){
    this.onTabsChanged(this.tabIndex)
  }
}
</script>
<style scoped>
.trading-forum-footer{
  position: absolute;
  bottom: 51px;
  padding: 15px 30px;
  left: 74rem;
}
</style>