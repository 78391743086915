<template>
  <div class="p-5">
    <form @submit.prevent="handleSubmit">
      <div class="row my-5">
        <div class="col-4">
          <label class="mb-3">Thread Code (auto generated)</label>
          <input
            v-model="code"
            :disabled="true"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Thread Creation Date</label>
          <datepicker 
            v-model="startDate"
            format="dd/MM/yyyy"
            :disabled="true"
            class="form-control disabled"
          ></datepicker>
        </div>
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Thread Status</label>
            <multiselect
              ref="status"
              v-model="status"
              :searchable="true"
              :options="statusOptions"
              :disabled="true"
              class="disabled"
              label="name"
              track-by="value"
              :loading="!statusOptions"
              :allow-empty="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row my-5 pb-4">
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Member (Seller)</label>
            <multiselect
              ref="product"
              v-model="form.user"
              :searchable="true"
              :options="memberOptions"
              label="name"
              track-by="id"
              placeholder="Select member"
              :loading="!memberOptions"
              :allow-empty="false"
              :show-labels="false"
              @input="getProductList"
            ></multiselect>
            <span v-if="formErrors.user" class="invalid-field">{{ formErrors.user }}</span>
          </div>
        </div>
        <div class="col-4">
          <label class="mb-3">Trade Quantity</label>
          <div class="position-relative">
            <span class="custom-icon minus-icon descrease-icon" @click="changeQuantity(false)"></span>
            <input 
              v-model="form.quantity"
              class="form-control px-5 text-center"
            />
            <span class="custom-icon add-icon increase-icon" @click="changeQuantity(true)"></span>
          </div>
          <span v-if="formErrors.quantity" class="invalid-field">{{ formErrors.quantity }}</span>
        </div>
        <div class="col-4">
          <label class="mb-3">Trade Price (RM)</label>
          <div class="form-control price d-flex">
            <input
              v-model="form.price"
              @change="changeTotalPrice"
            />
          </div>
          <span v-if="formErrors.price" class="invalid-field">{{ formErrors.price }}</span>
        </div>
      </div>
      <div class="row my-5 justify-content-between">
        <div class="col-8">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Product Item</label>
            <multiselect
              ref="product"
              v-model="form.product"
              :searchable="true"
              :options="productOptions"
              label="name"
              track-by="id"
              placeholder="Select product"
              :loading="!productOptions"
              :allow-empty="false"
              :show-labels="false"
            >
              <template slot="option" slot-scope="props">
                <div class="row px-4 justify-content-between">
                  <span>{{props.option.name}}</span>
                  <b><span class="stock-number-do">{{props.option.stockBalance}}</span></b>
                </div>
              </template>
            </multiselect>
            <span v-if="formErrors.product" class="invalid-field">{{ formErrors.product }}</span>
          </div>
        </div>
        <div class="col-4">
          <label class="mb-3">Trade Price (Total) (RM)</label>
          <input
            v-model="totalPrice"
            :disabled="true"
            class="form-control"
          />
        </div>
      </div>
    </form>
    <div class="generate-order-footer">
      <div class="row justify-content-center">
        <router-link to="/trading-forum/new-thread" class="btn btn-light mr-3" >Back to Forum</router-link>
        <button class="btn btn-secondary" :disabled="isLoading" @click="handleSubmit">Create Thread</button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import { GET_STOCK_LIST_REQUEST, GET_PRODUCT_LIST_BY_USER_REQUEST } from "../../store/modules/product/actions";
import { CREATE_THREAD_REQUEST } from "../../store/modules/trading-forum/actions";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";

export default {
  name: "CreateNewThread",
  components: {
    Multiselect,
    Datepicker
  },
  data(){
    return{
      totalPrice: 0,
      code: "",
      isLoading: false,
      formErrors: {},
      memberOptions: [],
      productOptions: [],
      statusOptions: [],
      status: {name: "Active", value: "ACTIVE"},
      startDate: new Date(),
      form: {
        price: 0,
        quantity: 0,
        product: null
      }
    }
  },
  methods: {
    changeTotalPrice(){
      this.totalPrice = this.form.price * this.form.quantity;
    },
    changeQuantity(action){
      this.form.quantity = Number(this.form.quantity);
      if(action){
        this.form.quantity += 1;
      } else if(this.form.quantity != 0) {
        this.form.quantity -= 1;
      }
      this.changeTotalPrice();
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.user) {
        this.formErrors.user = "Member is required";
        error++;
      } 
      if (!this.form.product) {
        this.formErrors.product = "Product is required";
        error++;
      } else if (this.form.quantity > this.form.product.stockBalance || this.form.quantity === 0) {
        this.formErrors.quantity = "Quantity should be more than 0 and less or equals to the stock balance of the selected product";
        error++;
      } 
      if (this.form.price <= 0) {
        this.formErrors.price = "Price should be more than 0";
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const user = {uuid: this.form.user.uuid};
        const threadProductList = [{
          product: {id: this.form.product.id},
          quantity: Number(this.form.quantity),
          price: Number(this.form.price)
        }];
        const thread = {user: user, threadProductList: threadProductList};
        this.$store.dispatch(CREATE_THREAD_REQUEST, thread).then(res => {
          if(res.status === "ok" && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Thread has been successfully created",
              timeOut: true
            });
            this.$router.push('/trading-forum/view-thread/' + res.result.id);
          }
          this.isLoading = false;
        });
      }
    },
    getOptionList(){
      const type = "MEMBER_TYPE";
      const filter = {type: type};
      this.$store.dispatch(GET_STOCK_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.memberOptions = res.result.list;
        }
      });
    },
    getProductList(){
      this.form.product = null;
      const user = {uuid: this.form.user.uuid};
      const filter = {user: user};
      this.$store.dispatch(GET_PRODUCT_LIST_BY_USER_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.productOptions = res.result.list;
        }
      })
    },
  },
  mounted(){
    this.getOptionList();
  }
}
</script>
<style scoped>
.form-control.price input{
  border: none;
  margin-left: 10px;
  color: #54555786;
  width: 100%;
  height: 100%;
}
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
.stock-number-do{
  color: #8C0434;
}

.multiselect__element:hover .stock-number-do, .multiselect__option--highlight .stock-number-do{
  color: white!important;
}
</style>