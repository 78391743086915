import request from "@/utils/request";
import {
  API_GET_NEWS_LIST,
  API_DELETE_NEWS,
  API_UPDATE_NEWS,
  API_CREATE_NEW_NEWS
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getNewsList(filter) {
  return await request({
    url: API_GET_NEWS_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function deleteNews(id) {
  return await request({
    url: API_DELETE_NEWS,
    method: "post",
    data: {locale: locale, news: id},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateNews(news) {
  return await request({
    url: API_UPDATE_NEWS,
    method: "post",
    data: {locale: locale, news: news},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function createNewNews(news) {
  return await request({
    url: API_CREATE_NEW_NEWS,
    method: "post",
    data: {locale: locale, news: news},
    timeout: DEFAULT_TIMEOUT
  });
}
