<template>
  <div id="view-user-wrapper">
    <div class="row user-info-wrapper justify-content-between">
      <div class="col-10 d-inline-flex" style="cursor: default">
        <p class="my-auto"><span class="user-info-title">User Name:</span> {{item.name}}</p> 
        &nbsp; &nbsp; <div class="my-auto vertical-line-sm"></div> &nbsp; &nbsp;
        <p class="my-auto"><span class="user-info-title">Joined Date:</span> {{changeDateFormat(item.createdDate)}}</p> 
        &nbsp; &nbsp; <div class="my-auto vertical-line-sm"></div> &nbsp; &nbsp;
        <!-- <b class="my-auto mr-5"><router-link to="#" class="primary-link">Trade History</router-link></b> -->
        <router-link :to="{name: 'View User Management', params: { user_uuid: item.uuid }}" class="btn btn-light d-flex">View Profile</router-link>
      </div>
      <div class="col-2 text-right">
        <p class="my-auto">
          <span class="user-info-title">User Status: </span>
          <b><span :class="'status-span-' + item.status.toLowerCase()">
            {{item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}}
          </span></b>
        </p>
      </div>
    </div>
    <div>
      <VueBootstrap4Table 
        :rows="productList" 
        :columns="columns" 
        :config="config"
        :total-rows="productListTotal"
        @on-change-query="onChangeQuery"
        @refresh-data="getProductList"
      >
        <template slot="empty-results">
          <div
            v-if="!isDataLoading"
            class="bold-text"
          >No records found</div>
          <div
            v-if="isDataLoading"
            class="row justify-content-center mt-3"
          ><div class="loader"></div></div>
        </template>
        <template slot="name" slot-scope="props">
          <img width="40" :src="getActiveCoverImage(props.row)" class="mr-4 profile-picture"/>
          <span>{{props.row.name}}</span>
        </template>
        <template slot="stockBalance" slot-scope="props">
          <span>{{props.row.stockBalance}}</span>
        </template>
        <template slot="action" slot-scope="props">
          <div class="col-6 mx-auto">
            <router-link :to="'/products/stock-balance-list/view-user/update-stock-details/' + item.uuid + '/' + props.row.id" class="btn btn-primary justify-content-center">View &amp; Edit</router-link>
          </div>
        </template>
      </VueBootstrap4Table>
    </div>
    <div class="trading-forum-footer">
      <div class="row justify-content-center">
        <router-link :to="{name: 'Product List', params: { tab: 1 }}" class="btn btn-light justify-content-center mr-3">Back to Stock list</router-link>
        <button class="btn btn-light d-flex" @click="triggerModal">Add Product</button>
      </div>
    </div>
    <BModal ref="add-product-modal" size="xl" class="add-product-wrapper" hide-footer>
      <h3 class="text-center mb-2">Add Product</h3>
      <div class="px-5 pt-4">
        <form class="form" @submit.prevent="handleSubmit">
          <div class="row my-5">
            <div class="col-12 d-inline-flex">
              <div class="col-6 pl-0">
                <div class="form-group flex-grow-1 d-flex" style="flex-direction: column">
                  <label class="mb-3">Member Name/ID</label>
                  <multiselect
                    ref="memberName"
                    v-model="form.user"
                    :searchable="true"
                    :options="[]"
                    :disabled="true"
                    label="name"
                    track-by="id"
                    :allow-empty="false"
                    :show-labels="false"
                    class="disabled"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <span>{{option.name}} - {{option.username}}</span>
                    </template>
                  </multiselect>
                  <span v-if="formErrors.user" class="invalid-field">{{ formErrors.user }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group flex-grow-1 d-flex" style="flex-direction: column">
                  <label class="mb-3">Product Name</label>
                  <multiselect
                    ref="productName"
                    v-model="form.product"
                    :searchable="true"
                    :options="productOptions"
                    label="name"
                    track-by="id"
                    :loading="!productOptions"
                    placeholder="Search product"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="productSelected"
                  ></multiselect>
                  <span v-if="formErrors.product" class="invalid-field">{{ formErrors.product }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-5">
            <div class="col-12 d-inline-flex">
              <div class="col-4 pl-0">
                <div class="form-group flex-grow-1 d-flex" style="flex-direction: column">
                  <label class="mb-3">Product Vintage</label>
                  <input 
                    v-model="vintage"
                    :disabled="true"
                    class="form-control px-5 text-center"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group flex-grow-1 d-flex" style="flex-direction: column">
                  <label class="mb-3">Product UOM</label>
                  <input 
                    v-model="uom"
                    :disabled="true"
                    class="form-control px-5 text-center"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group flex-grow-1 d-flex" style="flex-direction: column">
                  <label class="mb-3">Product Code</label>
                  <input 
                    v-model="code"
                    :disabled="true"
                    class="form-control px-5 text-center"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-inline-flex">
              <div class="col-6">
                <div class="form-group flex-grow-1">
                  <label class="mb-3">Quantity</label>
                  <div class="position-relative">
                    <span class="custom-icon minus-icon descrease-icon" @click="changeQuantity(false)"></span>
                    <input 
                      v-model="form.quantity"
                      class="form-control px-5 text-center"
                    />
                    <span class="custom-icon add-icon increase-icon" @click="changeQuantity(true)"></span>
                  </div>
                  <span v-if="formErrors.quantity" class="invalid-field">{{ formErrors.quantity }}</span>
                </div> 
              </div>
              <div class="col-6">
                <label class="mb-3">Price (RM)</label>
                <input
                  v-model="form.price"
                  class="form-control"
                />
                <span v-if="formErrors.price" class="invalid-field">{{ formErrors.price }}</span>
              </div>
            </div>
          </div>
        </form>
        <div class="row justify-content-center mt-4 py-5">
          <button class="btn btn-md btn-neutral mr-4" @click="triggerModal">Cancel</button>
          <button class="btn btn-md btn-secondary d-inline-flex" :disabled="isLoading" @click="handleSubmit">
            Add Product 
            <div v-if="isLoading" class="loader loader-sm"></div>
          </button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { convertDateFormat } from "../../utils/helpers";
import { 
  GET_PRODUCT_LIST_BY_USER_REQUEST, 
  GET_PRODUCT_LIST_REQUEST,
  ADD_STOCK_REQUEST
} from "../../store/modules/product/actions";
import { GET_USER_PROFILE_REQUEST } from "../../store/modules/user-management/actions";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table/VueBootstrap4Table";
import { BModal } from "bootstrap-vue";
import Multiselect from 'vue-multiselect'
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";

export default {
  name: "ViewUser",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    BModal: BModal,
    Multiselect: Multiselect,
  },
  watch: {
    'queryParams.page': {
      handler(){
        this.getProductList();
      }
    },
    'queryParams.per_page': {
      handler(){
        this.getProductList();
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          this.getProductList();
        }
      }
    }
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    }
  },
  data(){
    return{
      form: {
        user: {},
        product: '',
        quantity: 0
      },
      item: {
        status: ''
      },
      vintage: null,
      uom: null,
      code: null,
      isDataLoading: false,
      isLoading: false,
      formErrors: {},
      productOptions: [],
      productList: [],
      productListTotal: 0,
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Product Name",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Stock Amount",
        name: "stockBalance",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Product"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    changeDateFormat(date){
      return convertDateFormat(date, "DD/MM/YY");
    },
    changeQuantity(action){
      this.form.quantity = Number(this.form.quantity);
      if(action){
        this.form.quantity += 1;
      } else if(this.form.quantity != 0) {
        this.form.quantity -= 1;
      }
    },
    productSelected(product){
      this.vintage = product.vintage;
      this.uom = product.uom;
      this.code = product.code;
    },
    getActiveCoverImage(row){
      let hasCover = row.uploadList.find(el => el.cover === true);
      if(row.uploadList.length > 0 && hasCover){
        return hasCover.url;
      } else {
        return '/assets/default_image.jpeg';
      }
    },
    triggerModal(){
      this.$refs["add-product-modal"].toggle("#toggle-btn");
    },
    getProductOptions(){
      this.$store.dispatch(GET_PRODUCT_LIST_REQUEST, {withPrice: true, status: "ACTIVE"}).then(res => {
        if(res.status === "ok" && res.result){
          this.productOptions = res.result.list;
        }
      })
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.user) {
        this.formErrors.user = "User is required";
        error++;
      } 
      if (!this.form.product) {
        this.formErrors.product = "Product is required";
        error++;
      } 
      if (!this.form.price) {
        this.formErrors.price = "Price is required";
        error++;
      } else if(Number(this.form.price) < 0 || Number(this.form.price) === 0){
        this.formErrors.price = "Price should be more than 0";
        error++;
      }
      if (this.form.quantity <= 0) {
        this.formErrors.quantity = "Stock Balance should be more than 0";
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const product = {};
        product.id = this.form.product.id;
        product.quantity = Number(this.form.quantity);
        product.price = Number(this.form.price);
        product.user = {uuid: this.form.user.uuid};
        this.$store.dispatch(ADD_STOCK_REQUEST, product).then(res => {
          if(res.status === 'ok'){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Product is successfully added",
              timeOut: true
            });
            this.form.product = '';
            this.form.quantity = 0;
            this.form.price = null;
            this.getProductList();
            this.triggerModal();
          }
          this.isLoading = false;
        })
      }
    },
    getProductList(){
      this.isDataLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      const user = {uuid: this.item.uuid};
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, user: user, search: search, orderCol: orderCol, desc: desc};
      this.$store.dispatch(GET_PRODUCT_LIST_BY_USER_REQUEST, filter).then(res => {
        if(res.result){
          this.productList = res.result.list;
          this.productListTotal = res.result.total;
        }
        this.isDataLoading = false;
      })
    },
    getUser(){
      const user = {uuid: this.$route.params.id};
      this.$store.dispatch(GET_USER_PROFILE_REQUEST, user).then(res => {
        if(res.status === 'ok' && res.result){
          document.getElementById('header-route-name').textContent = res.result.memberNo;
          this.item = {...res.result};
          this.form.user = {...res.result};
          this.getProductList();
        }
      })
    }
  },
  mounted(){
    this.getUser();
    this.getProductOptions();
  },
}
</script>
<style scoped>
#view-user-wrapper .profile-picture{
  height: 40px; 
  object-fit: cover;
}
.user-info-wrapper{
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
}
.trading-forum-footer{
  position: absolute;
  bottom: 51px;
  padding: 15px 30px;
  left: 54rem;
  width: calc(100% - 94rem);
}
.menu-toggle .trading-forum-footer {
    left: 49rem!important;
}

.primary-link{
  color: var(--primary);
  text-decoration: underline;
}
.user-info-title{
  color: #545557;
}

.view-user-footer{
  /* position: absolute;
  bottom: 12em;
  left: 24rem; */
  width: 100%;
}

.add-product-wrapper{
  background-color: #FBFBFD;
}

.loader.loader-sm{
  width: 20px;
  height: 20px;
  margin-left: 2rem;
  border: 1px solid;
  border-top: 1px solid var(--primary);
}
p, span{
  cursor: default;
}
</style>