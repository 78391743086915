import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth/index";
import dashboard from "./modules/dashboard/index";
import product from "./modules/product/index";
import ui from "./modules/ui/index";
import deliveryOrder from "./modules/delivery-order/index";
import tradingForum from "./modules/trading-forum/index";
import userManagement from "./modules/user-management/index";
import news from "./modules/news/index";
import banner from "./modules/banner/index";
import notifications from "./modules/notifications/index";
import constant from "./modules/constant/index";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    dashboard,
    auth,
    product,
    ui,
    deliveryOrder,
    tradingForum,
    userManagement,
    news,
    banner,
    notifications,
    constant
  },
  strict: debug
});

export default store;
