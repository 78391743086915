import request from "@/utils/request";
import {
  API_GET_DELIVERY_ORDER_LIST,
  API_GET_COLLECTION,
  API_GENERATE_DO,
  API_CHANGE_STATUS_DO,
  API_CREATE_DO,
  API_GET_COLLECTION_LIST
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getDeliveryOrderList(filter) {
  return await request({
    url: API_GET_DELIVERY_ORDER_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getCollection(id) {
  return await request({
    url: API_GET_COLLECTION,
    method: "post",
    data: {locale: locale, collection: id},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function generateDO(id) {
  return await request({
    url: API_GENERATE_DO,
    method: "post",
    data: {locale: locale, collection: id},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function changeStatusDO(collection) {
  return await request({
    url: API_CHANGE_STATUS_DO,
    method: "post",
    data: {locale: locale, collection: collection},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function createDO(collection) {
  return await request({
    url: API_CREATE_DO,
    method: "post",
    data: {locale: locale, do: collection},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getCollectionList(doNumber) {
  return await request({
    url: API_GET_COLLECTION_LIST,
    method: "post",
    data: {locale: locale, collection: doNumber},
    timeout: DEFAULT_TIMEOUT
  });
}