import {
  getUnreadCountNotifications,
  getNotificationsList,
  updateNotification,
  createMessage,
  deleteMessage,
  getViewMessage
} from "@/api/notifications";
import {
  GET_UNREAD_NOTIFICATIONS_REQUEST,
  GET_UNREAD_NOTIFICATIONS_SUCCESS,
  GET_UNREAD_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_LIST_REQUEST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_NOTIFICATIONS_LIST_ERROR,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  GET_VIEW_MESSAGE_REQUEST,
  GET_VIEW_MESSAGE_SUCCESS,
  GET_VIEW_MESSAGE_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [GET_UNREAD_NOTIFICATIONS_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_UNREAD_NOTIFICATIONS_REQUEST);
      getUnreadCountNotifications()
        .then(resp => {
          commit(GET_UNREAD_NOTIFICATIONS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_UNREAD_NOTIFICATIONS_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_NOTIFICATIONS_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_NOTIFICATIONS_LIST_REQUEST);
      getNotificationsList(postData)
        .then(resp => {
          commit(GET_NOTIFICATIONS_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_NOTIFICATIONS_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_NOTIFICATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_NOTIFICATION_REQUEST);
      updateNotification(postData)
        .then(resp => {
          commit(UPDATE_NOTIFICATION_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_NOTIFICATION_ERROR, err);
          reject(err);
        });
    });
  },
  [CREATE_MESSAGE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_MESSAGE_REQUEST);
      createMessage(postData)
        .then(resp => {
          commit(CREATE_MESSAGE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CREATE_MESSAGE_ERROR, err);
          reject(err);
        });
    });
  },
  [DELETE_MESSAGE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_MESSAGE_REQUEST);
      deleteMessage(postData)
        .then(resp => {
          commit(DELETE_MESSAGE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DELETE_MESSAGE_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_VIEW_MESSAGE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_VIEW_MESSAGE_REQUEST);
      getViewMessage(postData)
        .then(resp => {
          commit(GET_VIEW_MESSAGE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_VIEW_MESSAGE_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_UNREAD_NOTIFICATIONS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_UNREAD_NOTIFICATIONS_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_UNREAD_NOTIFICATIONS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_NOTIFICATIONS_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_NOTIFICATIONS_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_NOTIFICATIONS_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_NOTIFICATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_NOTIFICATION_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_NOTIFICATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  CREATE_MESSAGE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CREATE_MESSAGE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  CREATE_MESSAGE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DELETE_MESSAGE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DELETE_MESSAGE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DELETE_MESSAGE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_VIEW_MESSAGE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_VIEW_MESSAGE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_VIEW_MESSAGE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};