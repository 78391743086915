// NPM: MAIN
import Vue from "vue";

// PROJECT: MAIN
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
// PROJECT: OTHER
require("./utils/imports");

/**************************************************************************
 * VUE INSTANCE
 **************************************************************************/
Vue.config.productionTip = false;
Vue.use(VueSidebarMenu);

export default new Vue({
  install: function(Vue) {
    Vue.prototype.$jQuery = require("jquery"); // you'll have this.$jQuery anywhere in your vue project
  },
  router,
  store,
  render: h => h(App)
}).$mount("#app");
