export const CREATE_NEW_BANNER_REQUEST = "CREATE_NEW_BANNER_REQUEST";
export const CREATE_NEW_BANNER_SUCCESS = "CREATE_NEW_BANNER_SUCCESS";
export const CREATE_NEW_BANNER_ERROR = "CREATE_NEW_BANNER_ERROR";
export const GET_BANNER_LIST_REQUEST = "GET_BANNER_LIST_REQUEST";
export const GET_BANNER_LIST_SUCCESS = "GET_BANNER_LIST_SUCCESS";
export const GET_BANNER_LIST_ERROR = "GET_BANNER_LIST_ERROR";
export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_ERROR = "DELETE_BANNER_ERROR";
export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_ERROR = "UPDATE_BANNER_ERROR";