<template>
  <div>
    <div class="row">
      <b-tabs v-model="tabIndex" class="content-d-none w-100">
        <b-tab title="New Requests"></b-tab>
        <b-tab title="Accepted Requests"></b-tab>
        <b-tab title="Cancelled Requests"></b-tab>
        <b-tab title="Rejected Requests"></b-tab>
        <b-tab title="Completed Requests"></b-tab>
        <b-tab title="DO Generation"></b-tab>
      </b-tabs>
    </div>
    <div class="row">
      <b-tabs class="nav-d-none w-100">
        <b-tab>
          <div v-if="tabIndex === 5" class="row">
            <DOGeneration />
          </div>
          <div v-if="tabIndex !== 5">
            <VueBootstrap4Table
              v-if="renderComponent"
              ref="bootstrap_table"
              :rows="deliveryOrderList" 
              :columns="tabIndex === 4 ? columns2 : columns" 
              :config="config"
              :total-rows="deliveryOrderListTotal"
              @on-change-query="onChangeQuery"
              @refresh-data="getDeliveryOrderList"
            >
              <template slot="empty-results">
                <div
                  v-if="!isDataLoading"
                  class="bold-text"
                >No records found</div>
                <div
                  v-if="isDataLoading"
                  class="row justify-content-center mt-3"
                ><div class="loader"></div></div>
              </template>
              <template slot="paginataion" slot-scope="props">
                {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
              </template>
              <template slot="createdDate" slot-scope="props">
                <span>{{changeDate(props.row.createdDate)}}</span>
              </template>
              <template slot="name" slot-scope="props">
                <img width="40" :src="props.row.user.picture || '/assets/user_default_profile.png'" class="mr-4" style="height: 40px; object-fit: cover; border-radius: 50%"/>
                <span>{{props.row.user.name}}</span>
              </template>
              <template slot="status" slot-scope="props">
                <b><span :class="'status-content-' + props.row.status.toLowerCase()" class="status-wrapper">
                  {{props.row.status.charAt(0).toUpperCase() + props.row.status.slice(1).toLowerCase()}}
                </span></b>
              </template>
              <template slot="action" slot-scope="props">
                <div class="col-8 mx-auto">
                  <router-link :to="'/delivery-order/generate-order/' + props.row.id" class="btn btn-primary justify-content-center">View &amp; Edit </router-link>
                </div>
              </template>
            </VueBootstrap4Table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table/VueBootstrap4Table";
import DOGeneration from "../../components/do/DOGeneration";
import { GET_DELIVERY_ORDER_LIST_REQUEST } from "../../store/modules/delivery-order/actions";
import { convertDateFormat } from "../../utils/helpers";
import { mapGetters } from "vuex";

export default {
  name: "NewRequest",
  props: ['tab'],
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    DOGeneration: DOGeneration,
  },
  watch: {
    'queryParams.per_page': {
      handler(){
        this.getDeliveryOrderList();
      }
    },
    tabIndex: {
      handler(newValue){
        console.log('tabIndex',newValue)
        this.onTabsChanged(newValue);
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          this.getDeliveryOrderList();
        }
      }
    }
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    },
    ...mapGetters(["getQueryParams"]),
  },
  data(){
    return{
      tabIndex: this.tab || 0,
      isChangedPage: !!this.tab,
      renderComponent: true,
      deliveryOrderList: [],
      deliveryOrderListTotal: 0,
      isDataLoading: false,
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Member",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Request Date",
        name: "createdDate",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "20%"
      },
      {
        label: "Transaction Status",
        name: "status",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "10%"
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      columns2: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Member",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "DO Number",
        name: "doNumber",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Request Date",
        name: "createdDate",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "20%"
      },
      {
        label: "Transaction Status",
        name: "status",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "10%"
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Collection Request"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
    }
  },
  methods: {
    async onChangeQuery(queryParams) {
      // console.log(queryParams)
      if(this.isChangedPage && this.tabIndex === 4 && Object.keys(this.getQueryParams).length > 0){
        this.queryParams.page = this.getQueryParams.page
        this.queryParams.global_search = this.getQueryParams.search
        this.config.page = this.getQueryParams.page
        // console.log('onChangeQuery ~~~~ onChangePage')
        this.getDeliveryOrderList(this.isChangedPage);
      } else {
        // console.log('onChangeQuery ~~~~ ')
        this.queryParams = queryParams;
        this.getDeliveryOrderList();
        // console.log(this.$refs.bootstrap_table.customSearch)
      }
    },
     async onTabsChanged(index){
      // console.log('onTabsChanged')
      if(index === 5 && document.getElementById('header-route-name')){
        document.getElementById('header-route-name').textContent = 'DO Generation';
      } else {
        if(index === 1){
          document.getElementById('header-route-name').textContent = 'Accepted Requests';
        } else if(index === 2){
          document.getElementById('header-route-name').textContent = 'Cancelled Requests';
        } else if(index === 3){
          document.getElementById('header-route-name').textContent = 'Rejected Requests';
        } else if(index === 4){
          document.getElementById('header-route-name').textContent = 'Completed Requests';
        } else if(document.getElementById('header-route-name')){
          document.getElementById('header-route-name').textContent = 'New Requests';
        }

        this.queryParams.page = 1;
        this.queryParams.global_search = '';
        this.$refs.bootstrap_table.query.global_search = '';

        await this.forceRerender()

        // this.getDeliveryOrderList();
      }
    },
    async forceRerender() {
      // console.log(' ============ forceRerender ============ ')
      
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    getDeliveryOrderList(isChangedPage = false){
      this.isDataLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      let status;
      if(this.tabIndex === 4){
        status = "COMPLETED";
      } else if(this.tabIndex === 1){
        status = "ACCEPTED";
      } else if(this.tabIndex === 2){
        status = "CANCELLED";
      } else if(this.tabIndex === 3){
        status = "REJECTED";
      } else {
        status = "PENDING";
      }
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        orderCol = orderCol === 'doNumber' ? 'c.do_number' : orderCol === 'user.name' ? 'lower(u.name)' : 'c.collection_time';
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, status: status, search: search, orderCol: orderCol, desc: desc};      
      // console.log(filter)
      // console.log('getDeliveryOrderList ====>>> ',this.queryParams.global_search, this.getQueryParams.search)

      this.$store.dispatch(GET_DELIVERY_ORDER_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.deliveryOrderList = res.result.list;
          this.deliveryOrderListTotal = res.result.total;
        }
        this.isDataLoading = false;

        if(isChangedPage) {
          this.$refs.bootstrap_table.query.global_search = this.getQueryParams.search
          this.$refs.bootstrap_table.$refs.global_search.value = this.getQueryParams.search
          this.isChangedPage = false
        }
      })
    },
    changeDate(date){
      return convertDateFormat(date, "DD/MM/YY")
    },
  },
  mounted(){
    // this.onTabsChanged(this.tabIndex);
  }
}
</script>
<style scoped>
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}

.stock-number-do{
  color: #8C0434;
}

.multiselect__element:hover .stock-number-do, .multiselect__option--highlight .stock-number-do{
  color: white!important;
}
</style>