import Vue from "vue";
import Router from "vue-router";
import productsRouter from "./modules/products";
import deliveryOrderRouter from "./modules/delivery-order";
import tradingForumRouter from "./modules/trading-forum";
import userManagementRouter from "./modules/user-management";
import newsRouter from "./modules/news";
import bannerRouter from "./modules/banner";
import notificationsRouter from "./modules/notifications";
import messagesRouter from "./modules/messages";
import Dashboard from "../views/dashboard.vue";

import store from "../store";
import {
  ROLE_ADMIN,
  LAYOUT_ADMIN,
  LAYOUT_AUTH
} from "../constants";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/dashboard");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/sign-in");
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      component: Dashboard,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        name: "Dashboard"
      },
      beforeEnter: ifAuthenticated
    },
    productsRouter,
    deliveryOrderRouter,
    tradingForumRouter,
    userManagementRouter,
    newsRouter,
    bannerRouter,
    notificationsRouter,
    messagesRouter,
    {
      path: "/not-allowed",
      component: () => import("../views/tmpls/denied-page.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ]
      }
    },
    {
      path: "/profile",
      component: () => import("../views/auth/profile.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        subtitles: [],
        title: "Profile"
      },
      name: ""
    },
    {
      path: "/sign-in",
      component: () => import("../views/auth/sign-in.vue"),
      meta: {
        layout: LAYOUT_AUTH
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/forgot-password",
      component: () => import("../views/auth/forgot-password.vue"),
      meta: {
        rule: 'isPublic',
        layout: LAYOUT_AUTH
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '*',
      redirect: '/sign-in',
      meta: {
        rule: 'isPublic',
        layout: LAYOUT_AUTH
      },
      component: () => import("../views/tmpls/denied-page.vue"),
    },
  ]
});