import request from "@/utils/request";
import {
  API_GET_UNREAD_COUNT_NOTIFICATIONS,
  API_GET_NOTIFICATIONS_LIST,
  API_UPDATE_NOTIFICATION,
  API_CREATE_MESSAGE,
  API_DELETE_MESSAGE,
  API_VIEW_MESSAGE
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getUnreadCountNotifications() {
  return await request({
    url: API_GET_UNREAD_COUNT_NOTIFICATIONS,
    method: "post",
    data: {locale: locale},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getNotificationsList(filter) {
  return await request({
    url: API_GET_NOTIFICATIONS_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateNotification(id) {
  return await request({
    url: API_UPDATE_NOTIFICATION,
    method: "post",
    data: {locale: locale, message: id},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function createMessage(message) {
  return await request({
    url: API_CREATE_MESSAGE,
    method: "post",
    data: {locale: locale, message: message},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function deleteMessage(id) {
  return await request({
    url: API_DELETE_MESSAGE,
    method: "post",
    data: {locale: locale, message: {id: id}},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getViewMessage(id) {
  return await request({
    url: API_VIEW_MESSAGE,
    method: "post",
    data: {locale: locale, message: {id: id}},
    timeout: DEFAULT_TIMEOUT
  });
}