<template>
  <div class="px-5 pt-5 mx-5 mt-5" style="padding-bottom: 15rem">
    <div class="row mb-5">
      <div class="col-12">
        <p>Created at: {{changeDate(item.createdDate)}}</p>
      </div>
    </div>
    <div class="receipients-list d-inline-flex mb-5">
      <div v-if="receipientListEqual()">
        <p>All active members selected</p>
      </div>
      <div v-else class="row d-inline-flex px-3">
        <div v-for="(user, index) in item.receipientList" :key="user.id">
          <div v-if="index < 5" class="user-wrapper mr-3" data-bs-toggle="tooltip" data-bs-html="true" :title="user.user.name">
            <img width="40" :src="user.user.picture || '/assets/user_default_profile.png'" class="mr-4" style="height: 40px; object-fit: cover; border-radius: 50%"/>
            <span>{{user.user.name}}</span>
          </div>
        </div>
        <div v-if="item.receipientList.length > 5" class="mb-4 ml-4 d-flex">
          <span class="modal-trigger my-auto" @click="triggerModalMembers">View all</span>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row my-5">
      <div class="col-10">
        <h5 class="mb-4">Subject</h5>
        <input
          v-model="item.subject"
          class="form-control"
          :disabled="true"
        />
      </div>
      <div class="col-12 mt-5">
        <h5 class="mb-4">Message</h5>
        <textarea
          v-model="item.content"
          class="form-control py-3"
          rows="7"
          :disabled="true"
        ></textarea>
      </div>
      <div v-if="item.uploadList.length > 0" class="col-12 mt-5">
        <h5 class="mb-4">Attachments</h5>
        <a 
          :href="baseURL + item.uploadList[0].url.replace(/https?:\/\/[^\/]*\/?/i, '')" 
          :download="/[^/]*$/.exec(item.uploadList[0].url)[0]"
          id="download-link"
          style="color: #8C0434"
        >Download file</a>
      </div>
    </div>
    <div class="row justify-content-center mt-5 pt-5">
      <button class="btn btn-danger" :disabled="isLaoding" @click="triggerModal">Delete Message</button>
    </div>
    <div class="generate-order-footer">
      <div class="row justify-content-center">
        <router-link to="/messages" class="btn btn-light justify-content-center mr-3">Back to Message list</router-link>
        <router-link to="/messages/create-new-message" class="btn btn-secondary justify-content-center">Create New Message</router-link>
      </div>
    </div>
    <BModal ref="confirm-modal" size="md" hide-footer>
      <div class="px-5 pb-5">
        <div class="row justify-content-center mb-5">
          <h5>Are you sure you want to delete <b><i>{{this.item.subject}}</i></b> ?</h5>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" @click="deleteMessage">Delete</button>
        </div>
      </div>
    </BModal>
    <BModal ref="view-all-members" size="xl" hide-footer>
      <div>
        <h4 class="text-center mb-5">Messaged Members <span class="members-length-message">({{item.receipientList.length}})</span></h4>
        <div class="row justify-content-end mb-5 px-4">
          <div class="col-4 search-wrapper px-4 d-flex justify-content-end input-group" id="search-group">
            <input id="search" type="text" class="form-control" v-model="search" placeholder="Search Member"/>
          </div>
        </div>
        <div class="row pb-4 px-2">
          <div v-for="item in filteredList" :key="item.id" class="col-3">
            <div class="user-wrapper d-flex mr-3 mb-4 position-relative" data-bs-toggle="tooltip" data-bs-html="true" :title="item.user.name">
            <div class="d-inline-flex">
              <img width="30" :src="item.picture || '/assets/user_default_profile.png'" class="mr-4" style="height: 30px; object-fit: cover; border-radius: 50%"/>
              <div class="user-name-message m-auto user-wrapper-lg">{{item.user.name}}</div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { DELETE_MESSAGE_REQUEST, GET_VIEW_MESSAGE_REQUEST } from "../../store/modules/notifications/actions";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { BModal } from "bootstrap-vue";
import { convertDateFormat } from "../../utils/helpers";
import { GET_STOCK_LIST_REQUEST } from "../../store/modules/product/actions";

export default {
  name: "ViewMessage",
  components: {
    BModal
  },
  computed: {
    filteredList() {
      return this.item.receipientList.filter(item => {
        return item.user.name && item.user.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  data(){
    return{
      isLaoding: false,
      baseURL: window.location.origin + '/',
      item: {
        receipientList: [],
        uploadList: []
      },
      receipientListOptions: [],
      search: ''
    }
  },
  methods: {
    receipientListEqual(){
      if(this.item.receipientList.length === this.receipientListOptions.length){
        if(this.item.receipientList.every(item => this.receipientListOptions.find(member => member.id === item.user.id))){
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    triggerModal(){
      this.$refs["confirm-modal"].toggle("#toggle-btn");
    },
    triggerModalMembers(){
      this.$refs["view-all-members"].toggle("#toggle-btn");
    },
    changeDate(date){
      return convertDateFormat(date, "DD-MM-YY HH:MM");
    },
    deleteMessage(){
      this.isLaoding = true;
      this.$store.dispatch(DELETE_MESSAGE_REQUEST, this.item.id).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Message was successfully deleted",
            timeOut: true
          });
          this.triggerModal();
        }
        this.isLaoding = false;
      })
    },
    getUserList(){
      const type = "MEMBER_TYPE";
      const filter = {type: type};
      this.$store.dispatch(GET_STOCK_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.receipientListOptions = res.result.list.filter(user => user.status === "ACTIVE");
        }
      });
    },
    getMessage(){
      this.$store.dispatch(GET_VIEW_MESSAGE_REQUEST, this.$route.params.id).then(res => {
        if(res.status === 'ok' && res.result){
          this.item = res.result;
        }
      })
    }
  },
  mounted(){
    this.getUserList();
    this.getMessage();
  }
}
</script>
<style scoped>
.user-wrapper{
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ADAFB1A7;
}
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
p, span, h5{
  cursor: default;
}
.user-name-message{
  white-space: nowrap;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-trigger{
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}
.user-wrapper-lg{
  width: 160px;
}
#search-group.input-group::after{
  z-index: 999;
  right: 20px;
}
.members-length-message{
  color: var(--primary);
}
</style>