export const GET_NEWS_LIST_REQUEST = "GET_NEWS_LIST_REQUEST";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_ERROR = "GET_NEWS_LIST_ERROR";
export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_ERROR = "DELETE_NEWS_ERROR";
export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_ERROR = "UPDATE_NEWS_ERROR";
export const CREATE_NEW_NEWS_REQUEST = "CREATE_NEW_NEWS_REQUEST";
export const CREATE_NEW_NEWS_SUCCESS = "CREATE_NEW_NEWS_SUCCESS";
export const CREATE_NEW_NEWS_ERROR = "CREATE_NEW_NEWS_ERROR";