export const GET_DELIVERY_ORDER_LIST_REQUEST = "GET_DELIVERY_ORDER_LIST_REQUEST";
export const GET_DELIVERY_ORDER_LIST_SUCCESS = "GET_DELIVERY_ORDER_LIST_SUCCESS";
export const GET_DELIVERY_ORDER_LIST_ERROR = "GET_DELIVERY_ORDER_LIST_ERROR";
export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_ERROR = "GET_COLLECTION_ERROR";
export const GENERATE_DO_REQUEST = "GENERATE_DO_REQUEST";
export const GENERATE_DO_SUCCESS = "GENERATE_DO_SUCCESS";
export const GENERATE_DO_ERROR = "GENERATE_DO_ERROR";
export const CHANGE_STATUS_DO_REQUEST = "CHANGE_STATUS_DO_REQUEST";
export const CHANGE_STATUS_DO_SUCCESS = "CHANGE_STATUS_DO_SUCCESS";
export const CHANGE_STATUS_DO_ERROR = "CHANGE_STATUS_DO_ERROR";
export const CREATE_DO_REQUEST = "CREATE_DO_REQUEST";
export const CREATE_DO_SUCCESS = "CREATE_DO_SUCCESS";
export const CREATE_DO_ERROR = "CREATE_DO_ERROR";
export const GET_COLLECTION_LIST_REQUEST = "GET_COLLECTION_LIST_REQUEST";
export const GET_COLLECTION_LIST_SUCCESS = "GET_COLLECTION_LIST_SUCCESS";
export const GET_COLLECTION_LIST_ERROR = "GET_COLLECTION_LIST_ERROR";
export const GET_DELIVERY_ORDER_LIST_FILTER = "GET_DELIVERY_ORDER_LIST_FILTER";