import {
  getUserManagementList,
  getUserProfile,
  updateUserProfile,
  updateUserPassword
} from "@/api/user-management";
import {
  GET_USER_MANAGEMENT_LIST_REQUEST,
  GET_USER_MANAGEMENT_LIST_SUCCESS,
  GET_USER_MANAGEMENT_LIST_ERROR,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [GET_USER_MANAGEMENT_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_USER_MANAGEMENT_LIST_REQUEST);
      getUserManagementList(postData)
        .then(resp => {
          commit(GET_USER_MANAGEMENT_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_USER_MANAGEMENT_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_USER_PROFILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_USER_PROFILE_REQUEST);
      getUserProfile(postData)
        .then(resp => {
          commit(GET_USER_PROFILE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_USER_PROFILE_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_USER_PROFILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_USER_PROFILE_REQUEST);
      updateUserProfile(postData)
        .then(resp => {
          commit(UPDATE_USER_PROFILE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_USER_PROFILE_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_USER_PASSWORD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_USER_PASSWORD_REQUEST);
      updateUserPassword(postData)
        .then(resp => {
          commit(UPDATE_USER_PASSWORD_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_USER_PASSWORD_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_USER_MANAGEMENT_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_USER_MANAGEMENT_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_USER_MANAGEMENT_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_USER_PROFILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_USER_PROFILE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_USER_PROFILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_PROFILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_USER_PROFILE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_PROFILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_PASSWORD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_USER_PASSWORD_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_USER_PASSWORD_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};