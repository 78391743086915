import {
  getDeliveryOrderList,
  getCollection,
  generateDO,
  changeStatusDO,
  createDO,
  getCollectionList
} from "@/api/delivery-order";
import {
  GET_DELIVERY_ORDER_LIST_REQUEST,
  GET_DELIVERY_ORDER_LIST_SUCCESS,
  GET_DELIVERY_ORDER_LIST_ERROR,
  GET_COLLECTION_REQUEST,
  GET_COLLECTION_SUCCESS,
  GET_COLLECTION_ERROR,
  GENERATE_DO_REQUEST,
  GENERATE_DO_SUCCESS,
  GENERATE_DO_ERROR,
  CHANGE_STATUS_DO_REQUEST,
  CHANGE_STATUS_DO_SUCCESS,
  CHANGE_STATUS_DO_ERROR,
  CREATE_DO_REQUEST,
  CREATE_DO_SUCCESS,
  CREATE_DO_ERROR,
  GET_COLLECTION_LIST_REQUEST,
  GET_COLLECTION_LIST_SUCCESS,
  GET_COLLECTION_LIST_ERROR,
  GET_DELIVERY_ORDER_LIST_FILTER
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false,
  filter: {}
};

const getters = {
  getQueryParams: state => state.filter,
};

const actions = {
  [GET_DELIVERY_ORDER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DELIVERY_ORDER_LIST_REQUEST);
      getDeliveryOrderList(postData)
        .then(resp => {
          if(postData.status === 'COMPLETED') commit(GET_DELIVERY_ORDER_LIST_FILTER, postData);
          commit(GET_DELIVERY_ORDER_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_DELIVERY_ORDER_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_COLLECTION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_COLLECTION_REQUEST);
      getCollection(postData)
        .then(resp => {
          commit(GET_COLLECTION_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_COLLECTION_ERROR, err);
          reject(err);
        });
    });
  },
  [GENERATE_DO_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GENERATE_DO_REQUEST);
      generateDO(postData)
        .then(resp => {
          commit(GENERATE_DO_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GENERATE_DO_ERROR, err);
          reject(err);
        });
    });
  },
  [CHANGE_STATUS_DO_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CHANGE_STATUS_DO_REQUEST);
      changeStatusDO(postData)
        .then(resp => {
          commit(CHANGE_STATUS_DO_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CHANGE_STATUS_DO_ERROR, err);
          reject(err);
        });
    });
  },
  [CREATE_DO_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_DO_REQUEST);
      createDO(postData)
        .then(resp => {
          commit(CREATE_DO_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CREATE_DO_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_COLLECTION_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_COLLECTION_LIST_REQUEST);
      getCollectionList(postData)
        .then(resp => {
          commit(GET_COLLECTION_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_COLLECTION_LIST_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_DELIVERY_ORDER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DELIVERY_ORDER_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_DELIVERY_ORDER_LIST_FILTER: (state,query) => {
    Object.assign(state, {
      status: "",
      filter: query,
      hasLoadedOnce: true
    });
  },
  GET_DELIVERY_ORDER_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_COLLECTION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_COLLECTION_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_COLLECTION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GENERATE_DO_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GENERATE_DO_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GENERATE_DO_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  CHANGE_STATUS_DO_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CHANGE_STATUS_DO_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  CHANGE_STATUS_DO_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  CREATE_DO_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CREATE_DO_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  CREATE_DO_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_COLLECTION_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_COLLECTION_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_COLLECTION_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};