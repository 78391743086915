import store from "@/store";
import PassThrough from "../../views/pass-through.vue";
import deliveryOrderNewRequest from "../../views/delivery-order/new-request.vue";
import deliveryOrderGenerateOrder from "../../views/delivery-order/generate-order.vue";

import {
  ROLE_ADMIN,
  LAYOUT_ADMIN
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.accessLevel) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/sign-in");
};

const deliveryOrderRouter = {
  path: "/delivery-order",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
    ],
  },
  children: [
    {
      path: "new-request",
      name: "New Request",
      component: deliveryOrderNewRequest,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Delivery Order",
        subtitles: [""]
      },
      props: true
    },
    {
      path: "generate-order/:id",
      name: "Generate Order",
      component: deliveryOrderGenerateOrder,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Delivery Order",
        subtitles: [""]
      },
      props: true
    },
  ],
  beforeEnter: ifAuthenticated
};

export default deliveryOrderRouter;
