<template>
  <div class="px-5 product-info-wrapper">
    <form class="form" @submit.prevent="handleSubmit">
      <div class="row my-5">
        <div class="col-3 d-inline-flex">
          <div class="position-relative">
            <img 
              :src="getActiveCoverImage()" 
              width="100" 
              class="product-pic"
              alt="Product Picture"
            />
            <div class="overlay" @click="triggerModal">View ({{form.uploadList && form.uploadList.length}})</div>
          </div>
          <div class="my-auto ml-5">
            <p class="mb-1">Change Product <br/> Photo</p>
            <p class="mb-0" style="cursor: pointer; text-decoration: underline" @click="triggerModal">View  &amp; Change</p>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12 d-inline-flex">
          <div class="col-4 pl-0">
            <label class="mb-3">Product Code</label>
            <input
              v-model="form.code"
              class="form-control"
            />
          </div>
          <div class="col-4 pr-0">
            <label class="mb-3">Product Name</label>
            <input
              v-model="form.name"
              class="form-control"
            />
          </div>
          <div class="col-4 pr-0">
            <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
              <label class="mb-3">Product Status</label>
              <multiselect
                ref="status"
                v-model="form.status"
                :searchable="true"
                :options="statusOptions"
                label="name"
                track-by="value"
                :loading="!statusOptions"
                :allow-empty="false"
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12">
          <label class="mb-3">Product Description</label>
          <input
            v-model="form.description"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-4">
          <label class="mb-3">Product Vintage</label>
          <input
            v-model="form.vintage"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Product UOM</label>
          <input
            v-model="form.uom"
            class="form-control"
          />
        </div>
        <div class="col-4">
          <label class="mb-3">Product Market Price (RM)</label>
          <input
            v-model="form.price"
            class="form-control"
          />
        </div>
      </div>
      <div class="product-footer">
        <div class="row justify-content-center">
          <router-link to="/products/product-list" class="btn btn-light d-flex mr-4">Back to Product List</router-link>
          <button class="btn btn-primary d-inline-flex mr-4" :disabled="isLoading">
            Update Product Info
            <div v-if="isLoading" class="loader loader-sm"></div>
          </button>
          <button class="btn btn-secondary d-inline-flex" type="button" @click="triggerModalMember">
            Product Member Info
          </button>
        </div>
      </div>
    </form>
    <BModal ref="view-modal" size="lg" hide-footer>
      <div v-if="imageTab === 0">
        <b-carousel
          ref="pic-carousel"
          id="carousel-1"
          :interval="interval"
          img-width="524"
          img-height="100"
          class="px-3"
          style="text-shadow: 1px 1px 2px #333;"
        >
          <!-- Text slides with image -->
          <b-carousel-slide class="px-5"
            :img-src="uploadingImages[0] ? uploadingImages[0].url : '/assets/default_image.jpeg'"
          ></b-carousel-slide>
        </b-carousel>
        <ol v-if="uploadingImages.length > 0" class="carousel-indicators position-relative my-5 px-5">
          <li role="button" v-for="(pic, index) in uploadingImages" :key="index" style="cursor: inherit">
            <img :src="pic.url" style="object-fit: cover;" width="100" class="h-100"/>
            <div class="remove-icon" @click="removePic(index)">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16.971 16.971" class="m-auto">
                <g id="Group_1226" data-name="Group 1226" transform="translate(-173.241 -200.818) rotate(45)">
                  <line id="Line_74" data-name="Line 74" y1="18" transform="translate(276.5 10.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                  <line id="Line_75" data-name="Line 75" y1="18" transform="translate(285.5 19.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                </g>
              </svg>
            </div>
          </li>
        </ol>
        <div class="row justify-content-center py-5">
          <button class="btn btn-light mr-3" @click="triggerUpload">Upload Image</button>
          <button class="btn btn-light mr-3" :disabled="checkToDisableUpload()" @click="handleSubmitPic">Update Product Images</button>
          <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
          <button class="btn btn-secondary" @click="imageTab = 1">Change Cover Photo</button>
        </div>
      </div>
      <div v-if="imageTab === 1" class="text-center">
        <h4>Choose am image cover photo</h4>
        <ol class="carousel-indicators position-relative my-5 px-5">
          <li v-for="(pic, index) in form.uploadList" :key="index">
            <img 
              :src="pic.url" 
              style="object-fit: cover; height: 80px" 
              :class="pic.cover === true && 'current-active-cover-picture' || activePic === pic && 'active-cover-picture'" 
              width="100" 
              @click="activePic = pic"
            />
            <b><p v-if="pic.cover" class="mt-3" style="color: #1BB28B">Active</p></b>
          </li>
        </ol>
        <div class="row justify-content-center">
          <button class="btn btn-light mr-3" @click="imageTab = 0">Upload Image</button>
          <button class="btn btn-secondary" :disabled="!checkToDisableChangeCover()" @click="updateCoverPic">Update</button>
        </div>
      </div>
    </BModal>
    <BModal ref="view-member" size="lg" hide-footer>
      <div class="pb-5">
        <div class="row">
          <div class="col-4">
            <label>Member Code</label>
          </div>
          <div class="col-4">
            <label>Member Name</label>
          </div>
          <div class="col-4">
            <label>Quantity</label>
          </div>
        </div>
        <div v-for="member in membersInfo" :key="member.id" class="row mb-4">
          <div class="col-4">
            <input
              v-model="member.memberNo"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <input
              v-model="member.name"
              :disabled="true"
              class="form-control"
            />
          </div>
          <div class="col-4">
            <input
              v-model="member.totalStock"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { BModal } from "bootstrap-vue";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { 
  UPDATE_PRODUCT_INFO_REQUEST, 
  UPLOAD_IMAGE_REQUEST,
  GET_PRODUCT_REQUEST,
  GET_STOCK_LIST_REQUEST
} from "../../store/modules/product/actions";
import Multiselect from "vue-multiselect";

export default {
  name: "ProductInfo",
  components: {
    BModal: BModal,
    Multiselect
  },
  data(){
    return{
      form: {},
      isLoading: false,
      uploadingImages: [],
      interval: 0,
      activePic: {},
      imageTab: 0,
      item: {},
      statusOptions: [{name: 'Active', value: 'ACTIVE'}, {name: 'Inactive', value: 'INACTIVE'}],
      membersInfo: {}
    }
  },
  methods: {
    removePic(index){ 
      if(this.uploadingImages[index].cover === true){
        this.uploadingImages[0].cover = true;
      }
      this.uploadingImages.splice(index, 1);
    },
    triggerUpload(){
      document.getElementById('file-input').click();
    },
    triggerModalMember(){
      this.$refs["view-member"].toggle("#toggle-btn");
    },
    checkToDisableChangeCover(){
      if(
        Object.keys(this.activePic).length === 0 && this.activePic.constructor === Object || 
        this.activePic === this.item.uploadList.find(el => el.cover === true)
      ) return false;
      return true;
    },
    checkToDisableUpload(){
      if(this.item.uploadList){
        if (this.item.uploadList.length !== this.uploadingImages.length) return false;
        for (var i = 0; i < this.item.uploadList.length; i++) {
          if (this.item.uploadList[i] !== this.uploadingImages[i]) return false;
        }
      }
      return true;

    },
    updateCoverPic(){
      let coverImage = this.uploadingImages.find(el => el.id === this.activePic.id);
      this.uploadingImages.map(el => {
        if(el === coverImage){
          el.cover = true;
        } else {
          el.cover = false;
        }
      })
      this.handleSubmitPic();
    },
    getActiveCoverImage(){
      let hasCover = this.form.uploadList ? this.form.uploadList.find(el => el.cover === true) : false;
      if(hasCover){
        return hasCover.url;
      } else {
        return '/assets/default_image.jpeg';
      }
    },
    triggerModal(){
      this.$refs["view-modal"].toggle("#toggle-btn");
    },
    uploadImage(event) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = ()=> {
        let imageBase64 = reader.result;
        this.$store.dispatch(UPLOAD_IMAGE_REQUEST, imageBase64).then(res => {
          if(res.result){
            const image = {};
            image.url = res.result.url;
            if(this.uploadingImages.length === 0) image.cover = true;
            else image.cover = false;
            this.uploadingImages.push(image);
          }
        })
      };      
    },
    handleSubmitPic(){
      const product = {...this.item};
      if(!this.checkToDisableUpload()) product.uploadList = this.uploadingImages;
      this.$store.dispatch(UPDATE_PRODUCT_INFO_REQUEST, {product}).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Product images has been updated!",
            timeOut: true
          });
          this.uploadingImages = [];
          res.result.uploadList.map(el => {
            this.uploadingImages.push(el);
          });
          this.form.uploadList = this.uploadingImages;
          // this.form = {...res.result};
          // this.form.type = this.typeOptions.find(el => el.value === res.result.type);
          this.activePic = {};
          this.getActiveCoverImage();
        }
      })
    },
    handleSubmit(){
      this.isLoading = true;
      const product = {...this.form};
      product.price = Number(product.price);
      product.type = product.type ? product.type.value : "";
      product.status = product.status.value;
      this.$store.dispatch(UPDATE_PRODUCT_INFO_REQUEST, {product}).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Product has been updated!",
            timeOut: true
          });
          this.uploadingImages = [];
          res.result.uploadList.map(el => {
            this.uploadingImages.push(el);
          });
          this.form = {...res.result};
          this.form.status = this.statusOptions.find(el => el.value === res.result.status);
          this.activePic = {};
          this.getActiveCoverImage();
        }
        this.isLoading = false;
      })
    },
    getProduct(){
      this.$store.dispatch(GET_PRODUCT_REQUEST, {id: this.$route.params.id}).then(res => {
        if(res.status === 'ok' && res.result){
          this.form = {...res.result};
          this.form.uploadList.map(el => {
            this.uploadingImages.push(el);
          });
          this.form.status = this.statusOptions.find(el => el.value === res.result.status);
          this.item = {...res.result};
          this.getActiveCoverImage();
          this.getMemberInfo();
        }
      })
    },
    getMemberInfo(){
      // const page = this.queryParams.page;
      // const limit = this.queryParams.per_page;
      const type = "STOCK";
      const filter = {type: type, desc: false, product: {id: this.item.id}};
      this.$store.dispatch(GET_STOCK_LIST_REQUEST, filter).then(res => {
        if(res.status === 'ok' && res.result){
          this.membersInfo = res.result.list.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
      })
    }
  },
  mounted() {
    this.getProduct();
  }
}
</script>

<style scoped>
.product-info-wrapper{
  padding-bottom: 15rem;
}
.remove-icon{
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  z-index: 9999;
  display: flex;
  cursor: pointer;
}
.current-active-cover-picture{
  border: 3px solid #1BB28B;
}
.active-cover-picture{
  border: 2px solid #1BB28B;
}
#file-input{
  display: none;
}
.product-pic{
  object-fit: cover;
  height: 100px;
  border-radius: 10px;
}

.product-text-green-color{
  color: #1BB28B;
}

.product-text-red-color{
  color: #D52B1E;
}

label{
  color:#545557;
}

.product-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
}
.overlay{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.44);
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
.loader.loader-sm{
  width: 20px;
  height: 20px;
  margin-left: 2rem;
  border: 1px solid;
  border-top: 1px solid var(--primary-dark);
}
</style>