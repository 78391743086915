<template>
  <div>
    <form @submit.prevent="handleSubmit" class="px-5">
      <div class="row my-5 pb-4">
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Membership Code (auto generated)</label>
            <input
              v-model="user.memberNo"
              :disabled="true"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">User Status</label>
            <multiselect
              ref="status"
              v-model="form.status"
              :searchable="true"
              :options="statusOptions"
              label="name"
              track-by="value"
              :loading="!statusOptions"
              :allow-empty="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
              </template>
            </multiselect>
            <span v-if="formErrors.status" class="invalid-field">{{ formErrors.status }}</span>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <label class="mb-3">Membership Type</label>
            <multiselect
              ref="type"
              v-model="form.memberType"
              :options="typeOptions"
              label="value"
              track-by="value"
              :allow-empty="false"
              :show-labels="false"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="row my-5 pb-4">
        <div class="col-4">
          <label class="mb-3">Member Full Name</label>
          <input
            v-model="form.name"
            class="form-control"
          />
          <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
        </div>
        <div class="col-4">
          <label class="mb-3">Contact Number</label>
          <input
            v-model="form.mobilePhone"
            class="form-control"
          />
          <span v-if="formErrors.mobilePhone" class="invalid-field">{{ formErrors.mobilePhone }}</span>
        </div>
        <div class="col-4">
          <label class="mb-3">Email Address</label>
          <input
            v-model="form.emailAddress"
            class="form-control"
          />
          <span v-if="formErrors.emailAddress" class="invalid-field">{{ formErrors.emailAddress }}</span>
        </div>
      </div>
      <div class="row my-5 pb-4">
        <div class="col-4">
          <label class="mb-3">Postal Address (maximum 4 lines)</label>
          <textarea 
            v-model="form.address"
            rows="4"
            class="form-control"
            style="max-height: 90px;"
          ></textarea>
          <span v-if="formErrors.address" class="invalid-field">{{ formErrors.address }}</span>
        </div>
      </div>
    </form>
    <div class="row my-5 justify-content-center">
      <button class="btn btn-secondary d-inline-flex" :disabled="isLoading" @click="handleSubmit">
        Update User
        <div v-if="isLoading" class="loader loader-sm"></div>
      </button>
    </div>
    <hr/>
    <div>
      <form @submit.prevent="updatePassword" class="px-5">
        <div class="row my-5 pb-4">
          <div class="col-4">
            <label class="mb-3">Password</label>
            <input 
              v-model="password"
              type="password"
              placeholder="********"
              class="form-control"
            />
            <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
          </div>
        </div>
        <div class="row my-5 justify-content-center">
          <button class="btn btn-primary" :disabled="isLoadingPassword" @click="updatePassword">Update Password</button>
        </div>
      </form>
    </div>
    <div class="generate-order-footer">
      <div class="row justify-content-center">
        <router-link to="/user-management" class="btn btn-light justify-content-center mr-5">Back to Member list</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { 
  GET_USER_PROFILE_REQUEST, 
  UPDATE_USER_PROFILE_REQUEST, 
  UPDATE_USER_PASSWORD_REQUEST 
} from "../../store/modules/user-management/actions";
import Multiselect from 'vue-multiselect';
import { GET_CONSTANT_LIST_REQUEST } from "../../store/modules/constant/actions";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";

export default {
  name: "ViewUserManagement",
  components: {
    Multiselect
  },
  data(){
    return{
      user: {},
      statusOptions: [
        {name: 'Active', value: 'ACTIVE'}, 
        {name: 'Inactive', value: 'INACTIVE'}
      ],
      typeOptions: [],
      form: {
        status: {name: '', value: ''},
      },
      address: "",
      password: "",
      isLoading: false,
      isLoadingPassword: false,
      formErrors: {}
    }
  },
  methods: {
    checkPasswordForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.password) {
        this.formErrors.password = "Password is required";
        error++;
      }

      return !error;
    },
    updatePassword(){
      if(this.checkPasswordForm()){
        this.isLoadingPassword = true;
        const user = {};
        user.password = this.password;
        user.uuid = this.user.uuid;
        this.$store.dispatch(UPDATE_USER_PASSWORD_REQUEST, user).then(res => {
          if(res.status === 'ok' && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Password has been successfully updated",
              timeOut: true
            });
          }
          this.isLoadingPassword = false;
        })
      }
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.status) {
        this.formErrors.status = "Status is required";
        error++;
      } 
      if (!this.form.name) {
        this.formErrors.name = "Name is required";
        error++;
      } 
      if (!this.form.mobilePhone) {
        this.formErrors.mobilePhone = "Mobile Phone is required";
        error++;
      } 
      if (!this.form.emailAddress) {
        this.formErrors.emailAddress = "Email Address is required";
        error++;
      } 
      if (!this.form.address) {
        this.formErrors.address = "Address is required";
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const user = {...this.form};
        const address = user.address.split("\n");
        user.address1 = address[0];
        user.address2 = address[1];
        user.address3 = address[2];
        user.address4 = address[3];
        if(user.memberType.id !== 0){
          user.memberType = user.memberType.value;
        } else {
          delete user["memberType"];
        }
        user.status = user.status.value;
        this.$store.dispatch(UPDATE_USER_PROFILE_REQUEST, user).then(res => {
          if(res.status === 'ok' && res.result){
            this.user = res.result;
            this.form = {...res.result};
            this.form.status = this.statusOptions.find(el => el.value === res.result.status);
            this.form.memberType = this.typeOptions.find(el => el.value === res.result.memberType) || this.typeOptions[0];
            this.form.address = res.result.address1 + "\n" + res.result.address2 + "\n" + res.result.address3 + "\n" + res.result.address4;
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "User has been successfully updated",
              timeOut: true
            });
          }
          this.isLoading = false;
        })
      }
    },
    getMemberTypes(){
      this.$store.dispatch(GET_CONSTANT_LIST_REQUEST, "MEMBER_TYPE").then(res => {
        if(res.status === "ok" && res.result){
          this.typeOptions.push({id: 0, value: "N/A"});
          res.result.map(el => {
            this.typeOptions.push(el);
          })
          this.getUserProfile();
        }
      });
    },
    getUserProfile(){
      const user = {uuid: this.$route.params.id};
      this.$store.dispatch(GET_USER_PROFILE_REQUEST, user).then(res => {
        if(res.status === 'ok' && res.result){
          document.getElementById('header-route-name').textContent = res.result.memberNo;
          this.user = res.result;
          if(!res.result.memberType) res.result.memberType = "N/A";
          this.form = {...res.result};
          this.form.memberType = this.typeOptions.find(el => el.value === res.result.memberType) || this.typeOptions[0];
          this.form.status = this.statusOptions.find(el => el.value === res.result.status);
          this.form.address = res.result.address1 + "\n" + res.result.address2 + "\n" + res.result.address3 + "\n" + res.result.address4;
        }
      })
    }
  },
  mounted(){
    this.getMemberTypes();
  }
}
</script>
<style scoped>
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
.loader.loader-sm{
  width: 20px;
  height: 20px;
  margin-left: 2rem;
  border: 1px solid;
  border-top: 1px solid var(--primary);
}
</style>