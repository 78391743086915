<template>
  <div class="banner-wrapper" :class="'banner-wrapper-' + banner.status.toLowerCase()">
    <div class="row justify-content-between p-3">
      <div class="col-4 d-inline-flex">
        <p class="ml-3 my-auto banner-p">Created Date {{changeDate(banner.createdDate)}}</p>
      </div>
      <div class="col-6 d-inline-flex justify-content-end">
        <h5 class="mr-3 my-auto banner-status">Banner Status</h5>
        <div class="col-4 mr-4">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <multiselect
              ref="status"
              v-model="status"
              :options="statusOptions"
              label="name"
              track-by="value"
              class="banner-status-input"
              :allow-empty="false"
              :show-labels="false"
              @input="updateStatus"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="my-auto mr-4" style="font-size: 25px; cursor: pointer" @click="triggerUpdateModal">&#9998;</div>
        <div class="custom-icon delete-icon my-auto" @click="triggerModal"></div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-12 py-3 text-center">
        <img :src="banner.url" class="banner-image"/>
      </div>
    </div>
    <BModal ref="confirm-modal" size="md" hide-footer>
      <div class="px-5 pb-5">
        <div class="row justify-content-center mb-5">
          <h5>Are you sure you want to delete this banner?</h5>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" @click="deleteBanner">Delete</button>
        </div>
      </div>
    </BModal>
    <BModal ref="update-modal" size="xl" hide-footer>
      <div>
        <form class="px-5" @submit.prevent="handleSubmit">
          <h5 class="text-center">Update Banner</h5>
          <div class="row my-5">
            <div class="col-4">
              <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
                <label class="mb-3">Banner Status</label>
                <multiselect
                  ref="status"
                  v-model="form.status"
                  :options="statusOptions"
                  label="name"
                  track-by="value"
                  placeholder="Select status"
                  class="news-status-input"
                  :allow-empty="false"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
                  </template>
                </multiselect>
                <span v-if="formErrors.status" class="invalid-field">{{ formErrors.status }}</span>
              </div>
            </div>
          </div>
          <div class="row my-5">
            <div class="col-12">
              <label class="mb-3">Banner Image</label>
              <div v-if="form.url.length > 0" class="position-relative">
                <div class="text-center">
                  <img :src="form.url" class="banner-image-modal"/>
                  <div class="remove-icon" @click="removeUrl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16.971 16.971" class="m-auto">
                      <g id="Group_1226" data-name="Group 1226" transform="translate(-173.241 -200.818) rotate(45)">
                        <line id="Line_74" data-name="Line 74" y1="18" transform="translate(276.5 10.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                        <line id="Line_75" data-name="Line 75" y1="18" transform="translate(285.5 19.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div v-else-if="isLoadingPic" class="row justify-content-center">
                <div class="loader"></div>
              </div>
              <div v-else class="dropzone justify-content-center d-flex" @click="triggerDropzone">
                <div class="m-auto">
                  <div class="custom-icon upload-icon mx-auto mb-3"></div>
                  <p>Click to browse an image</p>
                </div>
              </div>
              <input 
                ref="bannerDropzone" 
                name="file" 
                accept="image/*" 
                type="file" 
                class="d-none"
                @input="getUrl"
              />
              <span v-if="formErrors.url" class="invalid-field">{{ formErrors.url }}</span>
            </div>
          </div>
        </form>
        <div class="row mt-5 justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerUpdateModal">Cancel</button>
          <button class="btn btn-secondary" :disabled="isLoading" @click="handleSubmit">Update</button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { convertDateFormat } from "../../utils/helpers";
import Multiselect from 'vue-multiselect';
import { DELETE_BANNER_REQUEST, UPDATE_BANNER_REQUEST } from "../../store/modules/banner/actions"
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { BModal } from "bootstrap-vue";
import { UPLOAD_IMAGE_REQUEST } from "../../store/modules/product/actions";

export default {
  name: "BannerComponent",
  props: ['item'],
  components: {
    Multiselect,
    BModal
  },
  data(){
    return{
      statusOptions: [
        {name: 'Active', value: 'ACTIVE'},
        {name: 'Inactive', value: 'INACTIVE'},
      ],
      status: {name: '', value: ''},
      banner: {
        status: ''
      },
      form: {
        url: ''
      },
      isLoadingPic: false,
      formErrors: {},
      isLoading: false
    }
  },
  methods: {
    changeDate(date){
      return convertDateFormat(date, "DD-MM-YY");
    },
    getUrl(event){
      this.isLoadingPic = true;
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = ()=> {
        let imageBase64 = reader.result;
        this.$store.dispatch(UPLOAD_IMAGE_REQUEST, imageBase64).then(res => {
          if(res.status === 'ok' && res.result){
            this.form.url = res.result.url;
          }
          this.isLoadingPic = false;
        })
      }
      //   reader.readAsDataURL(file);
      // }
    },
    triggerUpdateModal(){
      this.$refs["update-modal"].toggle("#toggle-btn");
    },
    triggerModal(){
      this.$refs["confirm-modal"].toggle("#toggle-btn");
    },
    triggerDropzone(){
      this.$refs.bannerDropzone.click();
    },
    removeUrl(){
      this.form.url = "";
    },
    updateStatus(){
      const banner = {...this.banner};
      banner.status = this.status.value;
      this.$store.dispatch(UPDATE_BANNER_REQUEST, banner).then(res => {
        if(res.status === 'ok' && res.result){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Banner has been successfully updated",
            timeOut: true
          });
          this.banner = res.result;
        } else {
          this.status = this.statusOptions.find(el => el.value === this.item.status);
        }
      })
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.status) {
        this.formErrors.status = "Status is required";
        error++;
      } 
      if (this.form.url.length === 0) {
        this.formErrors.url = "Image is required";
        error++;
      } 

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const banner = {...this.form};
        banner.status = banner.status.value;
        this.$store.dispatch(UPDATE_BANNER_REQUEST, banner).then(res => {
          if(res.status === 'ok' && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Banner has been successfully created",
              timeOut: true
            });
            this.banner = res.result;
            this.status = this.statusOptions.find(el => el.value === res.result.status);
            this.triggerUpdateModal();
            this.$emit("update-list");
          } else {
            this.form = {...this.item};
            this.form.status = this.status;
          }
          this.isLoading = false;
        })
      }
    },
    deleteBanner(){
      this.$store.dispatch(DELETE_BANNER_REQUEST, {id: this.banner.id}).then(res => {
        if(res.status === 'ok'){
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Banner has been successfully deleted",
            timeOut: true
          });
          this.triggerModal();
          this.$emit("update-list");
        }
      })
    }
  },
  mounted(){
    this.banner = {...this.item};
    this.form = {...this.item};
    this.status = this.statusOptions.find(el => el.value === this.item.status);
    this.form.status = this.status;
  }
}
</script>
<style scoped>
.banner-wrapper{
  width: 100%;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  padding: 15px 30px;
}

.banner-image{
  width: 300px;
  height: 169px;
  object-fit: cover;
  border-radius: 10px;
}

.banner-wrapper-active{
  background-color: #F4E6EB;
}
.banner-p{
  color: #545557;
}

.banner-status{
  color: #545557da;
}

.banner-status-input{
  box-shadow: none;
  background-color: #F4F7FA;
  border-radius: 10px;
}

.delete-icon{
  cursor: pointer;
}
.remove-icon{
  position: absolute;
  top: -10px;
  left: 62%;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  z-index: 9999;
  display: flex;
  cursor: pointer;
}
.upload-icon{
  height: 90px;
  width: 100%;
}
.banner-image-modal{
  height: 169px;
  width: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.dropzone{
  height: 200px;
  border: dotted;
  border-radius: 10px;
  background: none;
}
p, h5{
  cursor: default!important;
}
</style>