export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";
export const GET_STOCK_LIST_REQUEST = "GET_STOCK_LIST_REQUEST";
export const GET_STOCK_LIST_SUCCESS = "GET_STOCK_LIST_SUCCESS";
export const GET_STOCK_LIST_ERROR = "GET_STOCK_LIST_ERROR";
export const UPDATE_PRODUCT_INFO_REQUEST = "UPDATE_PRODUCT_INFO_REQUEST";
export const UPDATE_PRODUCT_INFO_SUCCESS = "UPDATE_PRODUCT_INFO_SUCCESS";
export const UPDATE_PRODUCT_INFO_ERROR = "UPDATE_PRODUCT_INFO_ERROR";
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR";
export const GET_PRODUCT_LIST_BY_USER_REQUEST = "GET_PRODUCT_LIST_BY_USER_REQUEST";
export const GET_PRODUCT_LIST_BY_USER_SUCCESS = "GET_PRODUCT_LIST_BY_USER_SUCCESS";
export const GET_PRODUCT_LIST_BY_USER_ERROR = "GET_PRODUCT_LIST_BY_USER_ERROR";
export const UPDATE_STOCK_REQUEST = "UPDATE_STOCK_REQUEST";
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS";
export const UPDATE_STOCK_ERROR = "UPDATE_STOCK_ERROR";
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const ADD_STOCK_REQUEST = "ADD_STOCK_REQUEST";
export const ADD_STOCK_SUCCESS = "ADD_STOCK_SUCCESS";
export const ADD_STOCK_ERROR = "ADD_STOCK_ERROR";
export const EXPORT_STOCK_BALANCE_REPORT_REQUEST = "EXPORT_STOCK_BALANCE_REPORT_REQUEST";
export const EXPORT_STOCK_BALANCE_REPORT_SUCCESS = "EXPORT_STOCK_BALANCE_REPORT_SUCCESS";
export const EXPORT_STOCK_BALANCE_REPORT_ERROR = "EXPORT_STOCK_BALANCE_REPORT_ERROR";