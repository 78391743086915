import request from "@/utils/request";
import {
  API_LOGIN_SERVICE,
  API_FORGOT_PW_SERVICE,
  API_LOGOUT_SERVICE,
  API_USER_PROFILE,
  API_UPDATE_USER_PROFILE,
  API_UPDATE_USER_PASSWORD
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;
export async function loginByUsername(authData) {
  const encodedAuthData = btoa(authData.username + ":" + authData.password);
  return await request({
    url: API_LOGIN_SERVICE,
    method: "post",
    headers: {
      "Authorization": "Basic " + encodedAuthData,
    },
    data: {locale: locale, admin: true}
  });
}

export async function getUserProfile() {
  return await request({
    url: API_USER_PROFILE,
    method: "post",
    timeout: DEFAULT_TIMEOUT,
    data: {locale: locale, admin: true}
  });
}

export async function resetByEmail(username) {
  return await request({
    url: API_FORGOT_PW_SERVICE,
    method: "post",
    data: {locale: locale, username: username},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function logout() {
  return await request({
    url: API_LOGOUT_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}


export async function updateUserDetails(user) {
  return await request({
    url: API_UPDATE_USER_PROFILE,
    method: "post",
    data: {locale: locale, user: user},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateUserAdminPassword(data) {
  return await request({
    url: API_UPDATE_USER_PASSWORD,
    method: "post",
    data: {locale: locale, oldPassword: data.oldPassword, newPassword: data.newPassword},
    timeout: DEFAULT_TIMEOUT
  });
}
