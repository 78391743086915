import {
  getTradingForumList,
  createThread,
  getThread,
  updateThreadStatus
} from "@/api/trading-forum";
import {
  GET_TRADING_FORUM_LIST_REQUEST,
  GET_TRADING_FORUM_LIST_SUCCESS,
  GET_TRADING_FORUM_LIST_ERROR,
  CREATE_THREAD_REQUEST,
  CREATE_THREAD_SUCCESS,
  CREATE_THREAD_ERROR,
  GET_THREAD_REQUEST,
  GET_THREAD_SUCCESS,
  GET_THREAD_ERROR,
  UPDATE_THREAD_STATUS_REQUEST,
  UPDATE_THREAD_STATUS_SUCCESS,
  UPDATE_THREAD_STATUS_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [GET_TRADING_FORUM_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_TRADING_FORUM_LIST_REQUEST);
      getTradingForumList(postData)
        .then(resp => {
          commit(GET_TRADING_FORUM_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_TRADING_FORUM_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [CREATE_THREAD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_THREAD_REQUEST);
      createThread(postData)
        .then(resp => {
          commit(CREATE_THREAD_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CREATE_THREAD_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_THREAD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_THREAD_REQUEST);
      getThread(postData)
        .then(resp => {
          commit(GET_THREAD_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_THREAD_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_THREAD_STATUS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_THREAD_STATUS_REQUEST);
      updateThreadStatus(postData)
        .then(resp => {
          commit(UPDATE_THREAD_STATUS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_THREAD_STATUS_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_TRADING_FORUM_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_TRADING_FORUM_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_TRADING_FORUM_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  CREATE_THREAD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CREATE_THREAD_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  CREATE_THREAD_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_THREAD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_THREAD_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_THREAD_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_THREAD_STATUS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_THREAD_STATUS_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_THREAD_STATUS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};