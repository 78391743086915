import {
  getDashboardData,
} from "@/api/dashboard";
import {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false,
  lastSync: ""
};

const actions = {
  [GET_DASHBOARD_DATA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DASHBOARD_DATA_REQUEST);
      getDashboardData(postData)
        .then(resp => {
          commit(GET_DASHBOARD_DATA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_DASHBOARD_DATA_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_DASHBOARD_DATA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DASHBOARD_DATA_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_DASHBOARD_DATA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};