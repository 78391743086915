<template>
  <div class="px-5 pt-5 mx-5 mt-5" style="padding-bottom: 15rem">
    <form>
      <div class="row mb-5">
        <div class="col-5">
          <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
            <h5 class="mb-4">Select a Member</h5>
            <multiselect
              ref="receipientList"
              :searchable="true"
              :options="receipientListOptions"
              label="name"
              track-by="id"
              placeholder="Select member"
              :loading="!receipientListOptions"
              :disabled="form.receipientList.length === receipientListOptions.length - 1"
              :allow-empty="false"
              :show-labels="false"
              @input="addReceipient"
              :class="form.receipientList.length === receipientListOptions.length - 1 && 'disabled'"
            ></multiselect>
            <span v-if="formErrors.receipientList" class="invalid-field">{{ formErrors.receipientList }}</span>
          </div>
        </div>
      </div>
      <div v-if="form.receipientList.length === receipientListOptions.length - 1" class="row">
        <div class="col-12 d-inline-flex mb-5">
          <p class="my-auto">All members are selected</p>
          <div class="custom-icon delete-icon ml-4 my-auto" style="cursor: pointer" @click="form.receipientList = []"></div>
        </div>
      </div>
      <div v-else-if="form.receipientList.length > 0" class="row d-inline-flex mb-5 px-3">
        <div v-for="(user, index) in form.receipientList" :key="user.id">
          <div v-if="index < 5" class="user-wrapper d-flex mr-3 mb-4 position-relative" data-bs-toggle="tooltip" data-bs-html="true" :title="user.name">
            <div class="d-inline-flex">
              <img width="30" :src="user.picture || '/assets/user_default_profile.png'" class="mr-4" style="height: 30px; object-fit: cover; border-radius: 50%"/>
              <div class="user-name-message m-auto">{{user.name}}</div>
            </div>
            <div class="remove-icon" @click="removeUser(user.id)">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16.971 16.971" class="m-auto">
                <g id="Group_1226" data-name="Group 1226" transform="translate(-173.241 -200.818) rotate(45)">
                  <line id="Line_74" data-name="Line 74" y1="18" transform="translate(276.5 10.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                  <line id="Line_75" data-name="Line 75" y1="18" transform="translate(285.5 19.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div v-if="form.receipientList.length > 5" class="mb-4 ml-4 d-flex">
          <span class="modal-trigger my-auto" @click="triggerModal">View all</span>
        </div>
      </div>
      <hr/>
      <div class="row my-5">
        <div class="col-10">
          <h5 class="mb-4">Subject</h5>
          <input
            v-model="form.subject"
            class="form-control"
          />
          <span v-if="formErrors.subject" class="invalid-field">{{ formErrors.subject }}</span>
        </div>
        <div class="col-12 mt-5">
          <h5 class="mb-4">Message</h5>
          <textarea
            v-model="form.content"
            class="form-control py-3"
            rows="7"
          ></textarea>
          <span v-if="formErrors.content" class="invalid-field">{{ formErrors.content }}</span>
        </div>
        <div class="col-12 mt-5">
          <h5 class="mb-4">Attachments</h5>
          <div class="d-inline-flex">
            <input
              class="form-control py-3"
              id="input-file"
              type="file"
              @input="addFile"
            />
            <div class="custom-icon delete-icon ml-4 my-auto" style="cursor: pointer" @click="removeFile"></div>
          </div>
        </div>
      </div>
    </form>
    <div class="row justify-content-center mt-5 pt-5">
      <button class="btn btn-light mr-3" :disabled="isLoading" @click="clearMessage">Clear</button>
      <button class="btn btn-primary" :disabled="isLoading" @click="createMessage">Send Message</button>
    </div>
    <div class="generate-order-footer">
      <div class="row justify-content-center">
        <router-link to="/messages" class="btn btn-light justify-content-center mr-3">Back to Message list</router-link>
      </div>
    </div>
    <BModal ref="view-all-members" size="xl" hide-footer>
      <div>
        <h4 class="text-center mb-5">Members  <span class="members-length-message">({{form.receipientList.length}})</span></h4>
        <div class="row justify-content-end mb-5 px-4">
          <div class="col-4 search-wrapper px-4 d-flex justify-content-end input-group" id="search-group">
            <input id="search" type="text" class="form-control" v-model="search" placeholder="Search Member"/>
          </div>
        </div>
        <div class="row pb-4 px-2">
          <div v-for="item in filteredList" :key="item.id" class="col-3">
            <div class="user-wrapper d-flex mr-3 mb-4 position-relative" data-bs-toggle="tooltip" data-bs-html="true" :title="item.name">
            <div class="d-inline-flex">
              <img width="30" :src="item.picture || '/assets/user_default_profile.png'" class="mr-4" style="height: 30px; object-fit: cover; border-radius: 50%"/>
              <div class="user-name-message m-auto user-wrapper-lg">{{item.name}}</div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { CREATE_MESSAGE_REQUEST } from "../../store/modules/notifications/actions";
import { GET_STOCK_LIST_REQUEST, UPLOAD_IMAGE_REQUEST } from "../../store/modules/product/actions";
import Multiselect from 'vue-multiselect';
import { BModal } from "bootstrap-vue";

export default {
  name: "NewMessage",
  components: {
    Multiselect,
    BModal
  },
  computed: {
    filteredList() {
      return this.form.receipientList.filter(member => {
        return member.name && member.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  data(){
    return{
      form: {
        receipientList: []
      },
      formErrors: {},
      isLoading: false,
      receipientListOptions: [],
      file: '',
      search: ''
    }
  },
  methods: {
    triggerModal(){
      this.$refs["view-all-members"].toggle("#toggle-btn");
    },
    addReceipient(user){
      if(user.id === 'all'){
        this.form.receipientList = [];
        this.receipientListOptions.map(user => {
          if(user.id !== 'all') this.form.receipientList.push(user);
        });
      } else if(!this.form.receipientList.find(el => el === user)){
        this.form.receipientList.push(user);
      }
    },
    removeUser(id){
      const index = this.form.receipientList.indexOf(this.form.receipientList.find(el => el.id === id));
      this.form.receipientList.splice(index, 1);
    },
    addFile(event){
      if(event.target.files[0]){
        this.isLoading = true;
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = ()=> {
          let imageBase64 = reader.result;
          this.$store.dispatch(UPLOAD_IMAGE_REQUEST, imageBase64).then(res => {
            if(res.status === 'ok' && res.result){
              this.form.uploadList = [{url: res.result.url}];
            }
            this.isLoading = false;
          })
        }
      } else {
        this.form.uploadList = [];
      }
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.subject) {
        this.formErrors.subject = "Subject is required";
        error++;
      } 
      if (!this.form.content) {
        this.formErrors.content = "Message content is required";
        error++;
      } 
      if (this.form.receipientList.length === 0) {
        this.formErrors.receipientList = "There should be at least one member";
        error++;
      } 

      return !error;
    },
    clearMessage(){
      this.form = {receipientList: []};
      this.formErrors = {};
    },
    removeFile(){
      document.getElementById('input-file').value = '';
      this.form.uploadList = [];
    },
    createMessage(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const message = {...this.form};
        message.receipientList = message.receipientList.map(el => {
          return { user: {uuid: el.uuid}};
        });
        this.$store.dispatch(CREATE_MESSAGE_REQUEST, message).then(res => {
          if(res.status === 'ok' && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Message has been successfully sent",
              timeOut: true
            });
            this.form = {receipientList: []};
            document.getElementById('input-file').value = '';
          }
          this.isLoading = false;
        })
      }
    },
    getUserList(){
      const type = "MEMBER_TYPE";
      const filter = {type: type};
      this.$store.dispatch(GET_STOCK_LIST_REQUEST, filter).then(res => {
        if(res.status === "ok" && res.result){
          this.receipientListOptions.push({id: 'all', name: "ALL"});
          res.result.list.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).filter(user => user.status === "ACTIVE").map(user => {
            this.receipientListOptions.push(user);
          });
        }
      });
    }
  },
  mounted(){
    this.getUserList();
  }
}
</script>
<style scoped>
.generate-order-footer{
  position: absolute;
  bottom: 51px;
  background-color: white;
  padding: 20px 30px;
  left: 24rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
#search{
  padding-left: 20px!important;
}
.user-wrapper{
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ADAFB1A7;
  cursor: default;
}

.remove-icon{
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  z-index: 10;
  display: flex;
  cursor: pointer;
}
h5{
  cursor: default;
}
.user-name-message{
  white-space: nowrap;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-trigger{
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}
.user-wrapper-lg{
  width: 160px;
}
#search-group.input-group::after{
  z-index: 999;
  right: 20px;
}
.members-length-message{
  color: var(--primary);
}
</style>