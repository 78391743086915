import request from "@/utils/request";
import {
  API_CREATE_NEW_BANNER,
  API_GET_BANNER_LIST,
  API_DELETE_BANNER,
  API_UPDATE_BANNER
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function createNewBanner(banner) {
  return await request({
    url: API_CREATE_NEW_BANNER,
    method: "post",
    data: {locale: locale, banner: banner},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getBannerList(filter) {
  return await request({
    url: API_GET_BANNER_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function deleteBanner(id) {
  return await request({
    url: API_DELETE_BANNER,
    method: "post",
    data: {locale: locale, banner: id},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateBanner(banner) {
  return await request({
    url: API_UPDATE_BANNER,
    method: "post",
    data: {locale: locale, banner: banner},
    timeout: DEFAULT_TIMEOUT
  });
}