<script>
const default_layout = "auth";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  }
};
</script>

<template>
  <div id="app" class="position-relative">
    <component :is="layout">
      <router-view></router-view>
      <status-notification></status-notification>
    </component>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
