import request from "@/utils/request";
import {
  API_GET_MEMBER_LIST,
  API_GET_USER_PROFILE,
  API_UPDATE_USER_PROFILE,
  API_UPDATE_USER_PASSWORD
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getUserManagementList(filter) {
  return await request({
    url: API_GET_MEMBER_LIST,
    method: "post",
    data: {locale: locale, filter: filter},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getUserProfile(user) {
  return await request({
    url: API_GET_USER_PROFILE,
    method: "post",
    data: {locale: locale, user: user, admin: true},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateUserProfile(user) {
  return await request({
    url: API_UPDATE_USER_PROFILE,
    method: "post",
    data: {locale: locale, user: user},
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateUserPassword(user) {
  return await request({
    url: API_UPDATE_USER_PASSWORD,
    method: "post",
    data: {locale: locale, user: {uuid: user.uuid}, newPassword: user.password},
    timeout: DEFAULT_TIMEOUT
  });
}