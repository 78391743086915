import request from "@/utils/request";
import {
  API_DASHBOARD_DATA,
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

const locale = process.env.VUE_APP_LOCALE;

export async function getDashboardData(filter) {
  return await request({
    url: API_DASHBOARD_DATA,
    method: "post",
    data: {locale: locale, filter: filter || {}},
    timeout: DEFAULT_TIMEOUT
  });
}