<template>
  <div>
    <VueBootstrap4Table 
      :rows="messageList" 
      :columns="columns" 
      :config="config"
      :total-rows="messageListTotal"
      @on-change-query="onChangeQuery"
      @refresh-data="getMessageList"
    >
      <template slot="empty-results">
        <div
          v-if="!isDataLoading"
          class="bold-text"
        >No records found</div>
        <div
          v-if="isDataLoading"
          class="row justify-content-center mt-3"
        ><div class="loader"></div></div>
      </template>
      <template slot="paginataion" slot-scope="props">
        {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
      </template>
      <template slot="member" slot-scope="props">
        <div v-if="props.row.receipientList.length === 1">
          <div class="position-relative">
            <img :src="props.row.receipientList[0].user.picture || '/assets/user_default_profile.png'" width="40" class="mr-4 message-profile-pic"/>
          </div>
          <span style="margin-left: 6em">{{props.row.receipientList[0].user.name}}</span>
        </div>
        <div v-else class="d-inline-flex position-relative align-items-center">
          <div>
            <div :style="props.row.receipientList.length >= 3 ? 'margin-left: 2em': 'margin-left: 1em'" class="multiple-receipients-image"></div>
            <div v-if="props.row.receipientList.length >= 3" :style="props.row.receipientList.length >= 3 ? 'margin-left: 1em': 'margin-left: 2em'" class="multiple-receipients-image"></div>
            <img 
              :src="props.row.receipientList[0].user.picture || '/assets/user_default_profile.png'" 
              width="40" 
              class="ml-0 multiple-receipients-image" 
              style="height: 40px; object-fit: cover; border-radius: 50%"
              :style="props.row.receipientList.length >= 3 ? 'margin-left: 0em': ''"
            />
          </div>
          <div style="margin-left: 6em" class="multiple-receipients my-auto">
            <span v-for="(user, index) in props.row.receipientList" :key="user.id"> {{user.user.name}}{{index === props.row.receipientList.length - 1 ? ' ':','}}</span>
          </div>
          <span>({{props.row.receipientList.length}})</span>
        </div>
      </template>
      <template slot="created_date" slot-scope="props">
        <span>{{changeDate(props.row.createdDate)}}</span>
      </template>
      <template slot="subject" slot-scope="props">
        <span>{{props.row.subject}}</span>
      </template>
      <template slot="action" slot-scope="props">
        <div class="col-6 mx-auto">
          <router-link :to="'/messages/view-message/' + props.row.id" class="btn btn-primary justify-content-center">View</router-link>
        </div>
      </template>
    </VueBootstrap4Table>
    <div class="trading-forum-footer">
      <div class="row justify-content-center">
        <router-link to="/messages/create-new-message" class="btn btn-secondary justify-content-center">Create New Message</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_NOTIFICATIONS_LIST_REQUEST } from "../../store/modules/notifications/actions";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table/VueBootstrap4Table";
import { convertDateFormat } from "../../utils/helpers";

export default {
  name: "Messages",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
  },
  watch: {
    'queryParams.page': {
      handler(){
        this.getMessageList();
      }
    },
    'queryParams.per_page': {
      handler(){
        this.getMessageList();
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          this.getMessageList();
        }
      }
    },
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    }
  },
  data(){
    return{
      messageList: [],
      messageListTotal: 0,
      isDataLoading: false,
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Member",
        name: "member",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "40%"
      },
      {
        label: "Subject",
        name: "subject",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        sort: true,
      },
      {
        label: "Created Date",
        name: "created_date",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        sort: true,
      },
      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search message"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
    }
  },
  methods: {
    changeDate(date){
      return convertDateFormat(date, "DD-MM-YY HH:MM");
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getList();
    },
    getMessageList(){
      this.isDataLoading = true;
      const type = "MESSAGE";
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      let orderCol, desc;
      if(this.queryParams.sort[0]){
        orderCol = this.queryParams.sort[0].name;
        orderCol = orderCol === 'member' ? 'lower(u.name)' : orderCol;
        desc = this.queryParams.sort[0].order === "desc";
      }
      const filter = {page: page, limit: limit, search: search, type: type, orderCol: orderCol, desc: desc};
      this.$store.dispatch(GET_NOTIFICATIONS_LIST_REQUEST, filter).then(res => {
        if(res.status === 'ok' && res.result){
          this.messageList = res.result.list;
          this.messageListTotal = res.result.total;
        }
        this.isDataLoading = false;
      })
    },
  },
  mounted(){
    this.getMessageList();
  }
}
</script>
<style scoped>
  .multiple-receipients{
    white-space: nowrap; 
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .multiple-receipients-image{
    width: 40px;
    height: 40px;
    background-color: #C9C9C9;
    border-radius: 50%;
    position: absolute;
    border: 1px solid white;
    top: -4px;
  }
  .multiple-receipients-image:nth-child(1){
    margin-left: 2em;
  }
  .multiple-receipients-image:nth-child(2){
    margin-left: 1em;
  }
  .message-profile-pic{
    height: 40px; 
    object-fit: cover; 
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid white;
  }

  .trading-forum-footer{
    position: absolute;
    bottom: 51px;
    padding: 15px 30px;
    left: 74rem;
  }
  span{
    cursor: default;
  }
  
</style>