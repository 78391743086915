import {
  createNewBanner,
  getBannerList,
  deleteBanner,
  updateBanner
} from "@/api/banner";
import {
  CREATE_NEW_BANNER_REQUEST,
  CREATE_NEW_BANNER_SUCCESS,
  CREATE_NEW_BANNER_ERROR,
  GET_BANNER_LIST_REQUEST,
  GET_BANNER_LIST_SUCCESS,
  GET_BANNER_LIST_ERROR,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [CREATE_NEW_BANNER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_NEW_BANNER_REQUEST);
      createNewBanner(postData)
        .then(resp => {
          commit(CREATE_NEW_BANNER_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CREATE_NEW_BANNER_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_BANNER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_BANNER_LIST_REQUEST);
      getBannerList(postData)
        .then(resp => {
          commit(GET_BANNER_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_BANNER_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [DELETE_BANNER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_BANNER_REQUEST);
      deleteBanner(postData)
        .then(resp => {
          commit(DELETE_BANNER_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DELETE_BANNER_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_BANNER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_BANNER_REQUEST);
      updateBanner(postData)
        .then(resp => {
          commit(UPDATE_BANNER_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_BANNER_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  CREATE_NEW_BANNER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CREATE_NEW_BANNER_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  CREATE_NEW_BANNER_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_BANNER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_BANNER_LIST_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  GET_BANNER_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DELETE_BANNER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DELETE_BANNER_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DELETE_BANNER_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_BANNER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_BANNER_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_BANNER_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};