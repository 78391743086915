export const GET_UNREAD_NOTIFICATIONS_REQUEST = "GET_UNREAD_NOTIFICATIONS_REQUEST";
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = "GET_UNREAD_NOTIFICATIONS_SUCCESS";
export const GET_UNREAD_NOTIFICATIONS_ERROR = "GET_UNREAD_NOTIFICATIONS_ERROR";
export const GET_NOTIFICATIONS_LIST_REQUEST = "GET_NOTIFICATIONS_LIST_REQUEST";
export const GET_NOTIFICATIONS_LIST_SUCCESS = "GET_NOTIFICATIONS_LIST_SUCCESS";
export const GET_NOTIFICATIONS_LIST_ERROR = "GET_NOTIFICATIONS_LIST_ERROR";
export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";
export const CREATE_MESSAGE_REQUEST = "CREATE_MESSAGE_REQUEST";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_ERROR = "CREATE_MESSAGE_ERROR";
export const DELETE_MESSAGE_REQUEST = "DELETE_MESSAGE_REQUEST";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";
export const GET_VIEW_MESSAGE_REQUEST = "GET_VIEW_MESSAGE_REQUEST";
export const GET_VIEW_MESSAGE_SUCCESS = "GET_VIEW_MESSAGE_SUCCESS";
export const GET_VIEW_MESSAGE_ERROR = "GET_VIEW_MESSAGE_ERROR";