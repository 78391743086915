import store from "@/store";
import PassThrough from "../../views/pass-through.vue";
import ProductList from "../../views/products/product-list.vue";
import ProductInfo from "../../views/products/product-info.vue";
import ViewUser from "../../views/products/view-user.vue";
import UpdateStockDetails from "../../views/products/update-stock-details.vue";

import {
  ROLE_ADMIN,
  LAYOUT_ADMIN
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.accessLevel) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/sign-in");
};

const productsRouter = {
  path: "/products",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
    ],
  },
  children: [
    {
      path: "product-list",
      name: "Product List",
      component: ProductList,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Products",
        subtitles: [""]
      },
      props: true
    },
    {
      path: "product-list/product-info/:id",
      name: "Product Info",
      component: ProductInfo,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Products",
        subtitles: ["Product List"]
      },
    },
    {
      path: "stock-balance-list/view-user/:id",
      name: "View User",
      component: ViewUser,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Products",
        subtitles: ["Stock Balance"]
      },
    },
    {
      path: "stock-balance-list/view-user/update-stock-details/:id/:product_id",
      name: "Update Stock Details",
      component: UpdateStockDetails,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Products",
        subtitles: ["Stock Balance", "Update Stock"]
      },
    }
  ],
  beforeEnter: ifAuthenticated
};

export default productsRouter;
