// Auth
export const API_LOGIN_SERVICE = "user/login";
export const API_FORGOT_PW_SERVICE = "user/resetpassword";
export const API_LOGOUT_SERVICE = "";
export const API_USER_PROFILE = "user/get";

// Dashboard
export const API_DASHBOARD_DATA = "user/dashboard";

// Product
export const API_GET_PRODUCT_LIST = "product/list";
export const API_GET_STOCK_LIST = "product/stocklist";
export const API_UPDATE_PRODUCT_ITEM = "product/update";
export const API_UPLOAD_IMAGE = "upload/create";
export const API_GET_PRODUCT_LIST_BY_USER = "product/stocklist";
export const API_UPDATE_STOCK = "product/updatestock";
export const API_GET_PRODUCT = "product/get";
export const API_ADD_STOCK = "product/addstock";
export const API_EXPORT_STOCK_BALANCE_REPORT = "user/exportstockbalancereport";

// Delivery Order
export const API_GET_DELIVERY_ORDER_LIST = "collection/list";
export const API_GET_COLLECTION = "collection/get";
export const API_GENERATE_DO = "collection/generatedo";
export const API_CHANGE_STATUS_DO = "collection/changestatus";
export const API_CREATE_DO = "collection/create";
export const API_GET_COLLECTION_LIST = "collection/listbydonumber";

// Trading Forum
export const API_GET_TRADING_FORUM_LIST = "thread/list";
export const API_CREATE_THREAD = "thread/create";
export const API_GET_THREAD = "thread/get";
export const API_UPDATE_THREAD_STATUS = "thread/changestatus";

// User
export const API_GET_MEMBER_LIST = "user/list";
export const API_GET_USER_PROFILE = "user/get";
export const API_UPDATE_USER_PROFILE = "user/update";
export const API_UPDATE_USER_PASSWORD = "user/changepassword";

// Constant
export const API_GET_CONSTANT_LIST = "constant/list";
export const API_GET_LAST_SYNC = "constant/getlastsync";
export const API_GET_SYNC = "constant/sync";

// News
export const API_GET_NEWS_LIST = "news/list";
export const API_DELETE_NEWS = "news/delete";
export const API_UPDATE_NEWS = "news/update";
export const API_CREATE_NEW_NEWS = "news/create";

// Banner
export const API_CREATE_NEW_BANNER = "banner/create";
export const API_GET_BANNER_LIST = "banner/list";
export const API_DELETE_BANNER = "banner/delete";
export const API_UPDATE_BANNER = "banner/update";

// Notifications
export const API_GET_UNREAD_COUNT_NOTIFICATIONS = "message/getunreadcount";
export const API_GET_NOTIFICATIONS_LIST = "message/list";
export const API_UPDATE_NOTIFICATION = "message/read";
export const API_CREATE_MESSAGE = "message/create";
export const API_DELETE_MESSAGE = "message/delete";
export const API_VIEW_MESSAGE = "message/get";
