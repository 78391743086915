import store from "@/store";
import PassThrough from "../../views/pass-through.vue";
import messagesComponent from "../../views/messages/list.vue";
import viewMessageComponent from "../../views/messages/view-message.vue";
import newMessageComponent from "../../views/messages/new-message.vue";

import {
  ROLE_ADMIN,
  LAYOUT_ADMIN
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.accessLevel) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/sign-in");
};

const messagesRouter = {
  path: "/messages",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
    ],
  },
  children: [
    {
      path: "",
      name: "",
      component: messagesComponent,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Message",
        subtitles: []
      },
    },
    {
      path: "/messages/view-message/:id",
      name: "View Message",
      component: viewMessageComponent,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Message",
        subtitles: [""]
      },
      props: true
    },
    {
      path: "/messages/create-new-message",
      name: "Create New Message",
      component: newMessageComponent,
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
        ],
        title: "Message",
        subtitles: [""]
      },
    },
  ],
  beforeEnter: ifAuthenticated
};

export default messagesRouter;
