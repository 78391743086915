<template>
  <div class="p-5">
    <h3 class="mb-5">Overview</h3>
    <div class="row mb-5 justify-content-between">
      <div class="col-6">
        <div class="box-wrapper-long box-wrapper-green">
          <div class="col-6 d-flex">
            <h4 class="my-auto ml-5">Overall Stock Count</h4>
          </div>
          <div class="col-6 d-flex">
            <div class="number-icon-wrapper m-auto">
              <div>
                <h2 class="mb-0">{{data.stockCount}}</h2>
                <span>As of Today</span>
              </div>
              <div class="my-auto custom-icon stock-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="box-wrapper-long">
          <div class="col-6 d-flex">
            <h4 class="my-auto ml-5">Active Members</h4>
          </div>
          <div class="col-6 d-flex">
            <div class="number-icon-wrapper m-auto">
              <div>
                <h2 class="mb-0">{{data.activeMember}}</h2>
                <span>As of Today</span>
              </div>
              <div class="my-auto custom-icon member-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-4">
        <div class="box-wrapper-small">
          <div class="my-auto">
            <h4 class="mb-4">Active Trading</h4>
            <h1 class="mb-0">{{data.activeTrading}}</h1>
          </div>
          <div class="custom-icon my-auto active-trading-icon"></div>
        </div>
      </div>
      <div class="col-4">
        <div class="box-wrapper-small">
          <div class="my-auto">
            <h4 class="mb-4">Collection Request</h4>
            <h1 class="mb-0">{{data.collectionRequest}}</h1>
          </div>
          <div class="custom-icon my-auto collection-request-icon"></div>
        </div>
      </div>
      <div class="col-4">
        <div class="box-wrapper-small">
          <div class="my-auto">
            <h4 class="mb-4">DO Generated</h4>
            <h1 class="mb-0">{{data.doGenerated}}</h1>
          </div>
          <div class="custom-icon my-auto do-generated-icon"></div>
        </div>
      </div>
    </div>
    <ChartComponent :chartData="chartData" :weekOptions="weekOptions" :week="week" @get-data="getData"/>
  </div>
</template>
<script>
import { GET_DASHBOARD_DATA_REQUEST } from "../store/modules/dashboard/actions";
import ChartComponent from "../components/dashboard/ChartComponent";

export default {
  name: "Dashboard",
  components: {
    ChartComponent
  },
  data(){
    return{
      chartData: [],
      data: {},
      weekOptions: [],
      week: ''
    }
  },
  methods: {
    getData(week){
      this.$store.dispatch(GET_DASHBOARD_DATA_REQUEST, {week}).then(res => {
        if(res.status === 'ok' && res.result){
          this.chartData = JSON.parse(res.result.graphJsonString);
          this.data = res.result;
          this.weekOptions = res.result.weekList;
          this.week = res.result.week;
        }
      })
    },
  },
  mounted(){
    this.getData();
  }
}
</script>
<style scoped>
.box-wrapper-long{
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 0 1.5rem rgb(120 130 140 / 13%)!important;
}
.box-wrapper-small{
  border-radius: 15px;
  padding: 20px 4em;
  background-color: white;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #F0EAF8;
}

.box-wrapper-small h4{
  color: #545557ad;
  font-weight: 500;
}
.box-wrapper-green{
  background-color: #ECFCF5;
}

.box-wrapper-green .number-icon-wrapper{
  background-color: #35CD97!important;
}
.box-wrapper-green .number-icon-wrapper h2{
  color: white!important;
}

.box-wrapper-green .number-icon-wrapper span{
  color: #EAEAEA;
}


.box-wrapper-long .number-icon-wrapper{
  background-color: #F4E6EB;
}

.number-icon-wrapper{
  border-radius: 15px;
  width: 70%;
  padding: 20px;
  display: inline-flex;
  justify-content: space-around;
}

h4, h2, h1, span{
  cursor: default;
}
</style>