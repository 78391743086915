<template>
  <div class="px-5">
    <div class="row justify-content-end">
      <div class="col-6 d-inline-flex justify-content-end">
        <div class="col-6">
          <b-input-group class="form-group m-2" id="search-group">
            <b-form-input
              id="search"
              ref="global_search"
              placeholder="Search News"
              type="text"
              class="form-control px-0"
              @input="changeQueryParams"
              @keyup.enter="getNewsList"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-3 d-flex">
          <div class="input-group-append m-auto" @click="getNewsList">
            <span class="input-group-text btn btn-light" style="color: white">
              Search
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="newsList.length > 0" class="mt-5 news-row-wrapper">
      <NewsComponent
        v-for="news in newsList" :key="news.id"
        :item="news"
        @update-list="getNewsList"
      />
    </div>
   <div v-else-if="isDataLoading" class="row justify-content-center mt-5 pt-5">
      <div class="loader"></div>
    </div>
    <div v-else>
      <h5 class="text-center mt-5 pt-5">No records found</h5>
    </div>
    <tr class="footer-pagination-row">
      <td class="footer-pagination-row-inner px-5">
        <div class="row vbt-pagination-row no-gutters px-5 justify-content-end">
          <div class="col-4 justify-content-center d-flex">
            <button class="btn btn-secondary ml-4 my-auto" @click="triggerModal">
              Create News
            </button>
          </div>
          <div class="col-4 d-inline-flex justify-content-end">
            <div class="col-4 mr-3">
              <select
                id="per_page"
                class="form-control per-page-input"
                v-model="queryParams.per_page"
              >
                <option
                  v-for="(option, key, index) in queryParams.per_page_options"
                  :key="index"
                  :value="option"
                ><b>{{option}}</b> per page</option>
              </select>
            </div>
            <div class="my-auto mr-0 ml-2">
              <div>
                <Pagination
                  :page.sync="queryParams.page"
                  :per_page.sync="queryParams.per_page"
                  :per_page_options="queryParams.per_page_options"
                  :total="newsListTotal"
                >
                  <template slot="vbt-paginataion-previous-button">
                    <slot name="paginataion-previous-button">
                      &laquo;
                    </slot>
                  </template>
                  <template slot="vbt-paginataion-next-button">
                    <slot name="paginataion-next-button">
                        &raquo;
                    </slot>
                  </template>
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <BModal ref="view-modal" size="xl" hide-footer>
      <div>
        <form class="px-5" @submit.prevent="handleSubmit">
          <h5 class="text-center">Create New News</h5>
          <div class="row my-5">
            <div class="col-4">
              <label class="mb-3">News Title</label>
              <input
                v-model="form.name"
                maxlength="40"
                placeholder="News Title here"
                class="form-control"
              />
              <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
            </div>
            <div class="col-4">
              <label class="mb-3">Start Date</label>
              <datepicker 
                v-model="form.fromDateStr"
                :disabled-dates="disabledDates"
                format="dd/MM/yyyy"
                placeholder="12/04/2021"
                class="form-control"
              ></datepicker>
              <span v-if="formErrors.fromDateStr" class="invalid-field">{{ formErrors.fromDateStr }}</span>
            </div>
            <div class="col-4">
              <div class="form-group flex-grow-1 d-flex mb-0 h-100" style="flex-direction: column">
                <label class="mb-3">News Status</label>
                <multiselect
                  ref="status"
                  v-model="form.status"
                  :options="statusOptions"
                  label="name"
                  track-by="value"
                  placeholder="Select status"
                  class="news-status-input"
                  :allow-empty="false"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span :class="'status-span-' + option.name.toLowerCase()">{{option.name}}</span>
                  </template>
                </multiselect>
                <span v-if="formErrors.status" class="invalid-field">{{ formErrors.status }}</span>
              </div>
            </div>
          </div>
          <div class="row my-5">
            <div class="col-4">
              <label class="mb-3">End Date</label>
              <datepicker 
                v-model="form.toDateStr"
                :disabled-dates="disabledDates"
                format="dd/MM/yyyy"
                placeholder="12/04/2021"
                class="form-control"
              ></datepicker>
              <span v-if="formErrors.toDateStr" class="invalid-field">{{ formErrors.toDateStr }}</span>
            </div>
            <div class="col-8">
              <label class="mb-3">News Remarks</label>
              <input
                v-model="form.remarks"
                maxlength="80"
                placeholder="News Remarks here"
                class="form-control"
              />
              <span v-if="formErrors.remarks" class="invalid-field">{{ formErrors.remarks }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="mb-3">News Details</label>
              <textarea
                v-model="form.description"
                placeholder="News Details here"
                class="form-control"
                maxlength="120"
                rows="4"
              ></textarea>
              <span v-if="formErrors.description" class="invalid-field">{{ formErrors.description }}</span>
            </div>
          </div>
        </form>
        <div class="row mt-5 justify-content-center">
          <button class="btn btn-light mr-3" @click="triggerModal">Cancel</button>
          <button class="btn btn-secondary" :disabled="isLoading" @click="handleSubmit">Create</button>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import NewsComponent from "../../components/news/NewsComponent";
import { GET_NEWS_LIST_REQUEST, CREATE_NEW_NEWS_REQUEST } from "../../store/modules/news/actions";
import Pagination from "../../components/ui/VueBootstrap4Table/Pagination";
import { BModal } from "bootstrap-vue";
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { convertDateFormat } from "../../utils/helpers";

export default {
  name: "News",
  components: {
    NewsComponent,
    Pagination,
    BModal,
    Datepicker,
    Multiselect
  },
  watch: {
    'queryParams.page': {
      handler(){
        this.getNewsList();
      }
    },
    'queryParams.per_page': {
      handler(){
        this.getNewsList();
      }
    }
  },
  data(){
    return{
      newsList: [],
      newsListTotal: 0,
      isLoading: false,
      isDataLoading: false,
      disabledDates: {
        to: new Date()
      },
      queryParams: {
        global_search: "",
        per_page: 10,
        page: 1,
        per_page_options: [10, 25, 50, 100]
      },
      form: {},
      formErrors: {},
      statusOptions: [
        {name: 'Active', value: 'ACTIVE'},
        {name: 'Inactive', value: 'INACTIVE'},
      ],
    }
  },
  methods: {
    changeQueryParams(value){
      this.queryParams.global_search = value;
    },
    triggerModal(){
      this.$refs["view-modal"].toggle("#toggle-btn");
    },
    checkDetailsForm(){
      this.formErrors = {};
      let error = 0;

      if (!this.form.status) {
        this.formErrors.status = "Status is required";
        error++;
      } 
      if (!this.form.name) {
        this.formErrors.name = "Title is required";
        error++;
      } 
      if (!this.form.remarks) {
        this.formErrors.remarks = "Remarks is required";
        error++;
      } 
      if (!this.form.description) {
        this.formErrors.description = "Description is required";
        error++;
      } 
      if (!this.form.fromDateStr) {
        this.formErrors.fromDateStr = "Start Date is required";
        error++;
      } 
      if (!this.form.toDateStr) {
        this.formErrors.toDateStr = "End Date is required";
        error++;
      } 
      if(Date.parse(this.form.fromDateStr) > Date.parse(this.form.toDateStr)){
        this.formErrors.toDateStr = "End Date should be more than Start Date";
        error++;
      }

      return !error;
    },
    handleSubmit(){
      if(this.checkDetailsForm()){
        this.isLoading = true;
        const news = {...this.form};
        news.status = news.status.value;
        news.fromDateStr = convertDateFormat(news.fromDateStr, "DD/MM/YYYY");
        news.toDateStr = convertDateFormat(news.toDateStr, "DD/MM/YYYY");
        this.$store.dispatch(CREATE_NEW_NEWS_REQUEST, news).then(res => {
          if(res.status === 'ok' && res.result){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "News has been successfully created",
              timeOut: true
            });
            this.form = {};
            this.triggerModal();
            this.getNewsList();
          }
          this.isLoading = false;
        })
      }
    },
    getNewsList(){
      this.isDataLoading = true;
      const page = this.queryParams.page;
      const limit = this.queryParams.per_page;
      const search = this.queryParams.global_search;
      const filter = {page: page, limit: limit, search: search};
      this.$store.dispatch(GET_NEWS_LIST_REQUEST, filter).then(res => {
        if(res.status === 'ok' && res.result){
          this.newsList = res.result.list;
          this.newsListTotal = res.result.total;
        }
        this.isDataLoading = false;
      })
    }
  },
  mounted(){
    this.getNewsList();
    this.disabledDates.to = new Date(new Date().setDate(new Date().getDate() - 1));
  }
}
</script>

<style scoped>
#search{
  padding-left: 20px!important;
}

.footer-pagination-row-inner{
  padding: .75rem;
  border-top: .1rem solid rgba(120, 130, 140, .13);
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}

.news-row-wrapper{
  margin-bottom: 20rem;
}

.form-control.per-page-input{
  width: auto;
}
</style>